import { pageDefault } from '../common.js'
import {
  EMPLOYEE_CREATE_ACTION,
  EMPLOYEE_SHOW_ADD_DIALOG_ACTION,
  EMPLOYEE_HIDE_ADD_DIALOG_ACTION,
  EMPLOYEE_GET_OK_ACTION,
  EMPLOYEE_UPDATE_ACTION,
  EMPLOYEE_SHOW_EDIT_DIALOG_ACTION,
  EMPLOYEE_HIDE_EDIT_DIALOG_ACTION,
  EMPLOYEE_DELETE_ACTION,
  EMPLOYEE_SHOW_DELETE_DIALOG_ACTION,
  EMPLOYEE_HIDE_DELETE_DIALOG_ACTION,

  EMPLOYEE_GET_PAGE_ITEMS_ACTION,
  EMPLOYEE_SET_ORDER_ACTION,
  EMPLOYEE_SET_LIMIT_ACTION,
  EMPLOYEE_SET_OFFSET_ACTION,
  EMPLOYEE_SET_SEARCH_ACTION,
  EMPLOYEE_RESET_SEARCH_ACTION
} from './actions.js'

const initialState = pageDefault('name', 'employee')

/**
 * Reducer for handling employees.
 */
export function employee (state = initialState, action) {
  switch (action.type) {
    case EMPLOYEE_GET_OK_ACTION:
      return Object.assign({}, state, {
        items: action.items.slice()
      })
    case EMPLOYEE_CREATE_ACTION:
      return Object.assign({}, state, {
        items: state.items.concat(action.employee),
        pageItems: [action.employee].concat(state.pageItems)
      })
    case EMPLOYEE_SHOW_ADD_DIALOG_ACTION:
      return Object.assign({}, state, {
        addItem: true
      })
    case EMPLOYEE_HIDE_ADD_DIALOG_ACTION:
      return Object.assign({}, state, {
        addItem: false
      })
    case EMPLOYEE_UPDATE_ACTION:
      return Object.assign({}, state, {
        items: state.items.map(c => {
          if (c.id === action.employee.id) {
            return action.employee
          }
          return c
        }),
        pageItems: state.pageItems.map(c => {
          if (c.id === action.employee.id) {
            return action.employee
          }
          return c
        })
      })
    case EMPLOYEE_SHOW_EDIT_DIALOG_ACTION:
      return Object.assign({}, state, {
        editItem: action.employee
      })
    case EMPLOYEE_HIDE_EDIT_DIALOG_ACTION:
      return Object.assign({}, state, {
        editItem: null
      })
    case EMPLOYEE_DELETE_ACTION:
      return Object.assign({}, state, {
        items: state.items.filter(c => {
          return c.id !== action.employee.id
        }),
        pageItems: state.pageItems.filter(c => {
          return c.id !== action.employee.id
        })
      })
    case EMPLOYEE_SHOW_DELETE_DIALOG_ACTION:
      return Object.assign({}, state, {
        deleteItem: action.employee
      })
    case EMPLOYEE_HIDE_DELETE_DIALOG_ACTION:
      return Object.assign({}, state, {
        deleteItem: null
      })

    case EMPLOYEE_GET_PAGE_ITEMS_ACTION:
      return Object.assign({}, state, { pageItems: action.pageItems })
    case EMPLOYEE_SET_ORDER_ACTION: {
      const { order, orderBy } = action
      return Object.assign({}, state, { order, orderBy, offset: 0 })
    }
    case EMPLOYEE_SET_LIMIT_ACTION:
      return Object.assign({}, state, { limit: action.limit, offset: 0 })
    case EMPLOYEE_SET_OFFSET_ACTION:
      return Object.assign({}, state, { offset: action.offset })
    case EMPLOYEE_SET_SEARCH_ACTION:
      return Object.assign({}, state, { search: action.search, offset: 0 })
    case EMPLOYEE_RESET_SEARCH_ACTION:
      return { ...state, search: '', offset: 0 }
    default:
      return state
  }
}
