import { createSelector } from '@reduxjs/toolkit'

export const makeSelectorById = () => createSelector(
  (state) => state.ticket,
  (_, id) => id,
  (slice, id) => {
    return slice.items?.find(({ id: _id }) => _id === Number(id))
  }
)

export const makeSelectorTicketByProjectId = () => createSelector(
  (state) => state.ticket,
  (_, id) => id,
  (slice, id) => {
    return slice.items?.filter(({ project_id: _id }) => _id === Number(id))
  }
)

export const makeSelectorByIsEndedAt = () => createSelector(
  (state) => state.ticket,
  (_, id) => id,
  (slice, id) => {
    const ticket = slice.items.find(({ id: _id }) => _id === Number(id))
    return ticket?.ended_at
  }
)
