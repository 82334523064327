import React from 'react'
import { Tag, Link, styled } from '@pergas-common/pergas-components'
import { Remove } from '@pergas-common/pergas-icons'

const Holder = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
  max-height: 136px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    width: 15px;
    background: ${({ theme }) => theme.palette.brand.secondary};
  }

  > div {
    flex: 1 0 50%;
    display: flex;
    justify-content: flex-start;
    align-items: end;
    
    > button {
      vertical-align: bottom;
      margin-left: 4px;
    }
  }
  > div > span {
    max-width: 42px;
    margin-top: 8px;
  }
`

const Elipsis = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Tags = ({ tags, disabled, handleChange }) => {
  const renderedTags = tags.map(({ id, color, name }) => (
    <div key={id}>
      <Tag color={color}><Elipsis>{name}</Elipsis></Tag>
      <Link
        onClickHandler={() => {
          handleChange(id)
        }}
      >
        {!disabled && <Remove color='#b13a22' width={20} height={20} />}
      </Link>
    </div>
  ))

  return renderedTags
}

export const TagList = ({ name, disabled, tags, handleChange }) => {
  if (tags == null) {
    return <Holder />
  }
  return (
    <Holder>
      <Tags
        disabled={disabled}
        tags={tags} handleChange={(id) => {
          const newTags = tags.filter((t) => t.id !== id)
          handleChange(name, newTags)
        }}
      />
    </Holder>
  )
}
