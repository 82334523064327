import React from 'react'
import { connect } from 'react-redux'
import {
  List,
  ListItem,
  ListItemText
} from '@mui/material'
import {
  LocaleIcon,
  SettingsIcon
} from '../icons.js'
import redux from '../../redux/index.js'
import PageToolbar from './PageToolbar.js'

const SettingsPage = ({ locale, onSetLocale }) => {
  return (
    <>
      <PageToolbar left={<><SettingsIcon style={{ width: 20, height: 20 }} /><span>{locale.settings}</span></>} />
      <List>
        <ListItem button onClick={() => onSetLocale('en')}>
          <LocaleIcon style={{ width: 20, height: 20 }} />
          <ListItemText primary='English' />
        </ListItem>
        <ListItem button onClick={() => onSetLocale('sv')}>
          <LocaleIcon style={{ width: 20, height: 20 }} />
          <ListItemText primary='Svenska' />
        </ListItem>
        <ListItem button onClick={() => onSetLocale('sv_lawfirm')}>
          <LocaleIcon style={{ width: 20, height: 20 }} />
          <ListItemText primary='Svenska (Advokat)' />
        </ListItem>
      </List>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    locale: state.locale.strings
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSetLocale: (locale) => {
      dispatch(redux.actions.locale.setLocale(locale))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage)
