import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createClient from '@pergas-pds/pds-api-client'
import { createReducers } from './create-reducers'
import * as MSAL from '@azure/msal-browser'
import resources from '../resources'

// admin
import { account } from './account/actions.js'
import { permission } from './permission/actions.js'
import { tenant } from './tenant/actions.js'

// user
import { setAppPath } from './app-path/actions.js'
import { category } from './category/actions.js'
import { contact } from './contact/actions.js'
import { contactPerson } from './contact-person/actions.js'
import { contentType } from './content-type/actions.js'
import { department } from './department/actions.js'
import { employee } from './employee/actions.js'
import { resetError } from './error/actions.js'
import { setLocale } from './locale/actions.js'
import { initLogin } from './login/actions.js'
import { objectType } from './object-type/actions.js'
import { project } from './project/actions.js'
import { role } from './role/actions.js'
import { tag } from './tag/actions.js'
import { ticket } from './ticket/actions.js'
import { time } from './time/actions.js'
import file from './files/actions.js'

const baseUrl = process.env.REACT_APP_API_URL
if (typeof baseUrl !== 'string') {
  throw new Error('baseUrl must be set to a valid url')
}
export const api = createClient({ baseUrl })

const msalOptions = {
  auth: {
    clientId: 'f1f8356e-c3c7-4ac1-ba48-27df05880265'
  },
  cache: {
    cacheLocation: 'localStorage'
  }
}
const msal = new MSAL.PublicClientApplication(msalOptions)

const store = configureStore({
  reducer: createReducers(resources),
  middleware: [...getDefaultMiddleware({
    serializableCheck: false // temporary disable check to supress console spam.
  })]
})

const login = initLogin(api, store, msal)

const redux = {
  store,
  actions: {
    account: account(api, store),
    category: category(api, store),
    contact: contact(api, store),
    contactPerson: contactPerson(api, store),
    contentType: contentType(api, store),
    department: department(api, store),
    employee: employee(api, store),
    error: { resetError },
    file: file(store, msal),
    locale: { setLocale },
    login,
    objectType: objectType(api, store),
    project: project(api, store),
    permission: permission(api, store),
    role: role(api, store),
    tag: tag(api, store),
    tenant: tenant(api, store),
    ticket: ticket(api, store),
    time: time(api, store),
    window: { setAppPath }
  }
}

export default redux
