import React, { Component } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { styled, media } from '@pergas-common/pergas-components'
import SideBarMenu from './SideBarMenu'
import {
  AccountPage,
  CategoryPage,
  ContactPage,
  ContactDetails,
  ContactPersonPage,
  ContactPersonDetails,
  ContentTypePage,
  DepartmentPage,
  EmployeePage,
  ObjectTypePage,
  ProjectPage,
  ProjectDetails,
  SettingsPage,
  RolePage,
  TagPage,
  TicketPage,
  TimeInputPage,
  NotFoundPage,
  TicketDetails
} from './pages'
import TopBar from './TopBar.js'
import {
  EditAccount,
  EditCategory,
  EditContactPerson,
  EditDepartment,
  EditEmployee,
  EditObjectType,
  EditRole,
  EditTag
} from './dialogs'
import redux from '../redux/index.js'

const SideBar = styled.div`  
  color: #575f65;
  width: 100%;
  max-width: 50px;
  ${media.medium`
    max-width: 279px;
  `}
`

class WorkSpace extends Component {
  renderAddAccountDialog () {
    const {
      addAccount,
      onAddAccountOk,
      onAddAccountCancel
    } = this.props
    if (addAccount) {
      return <EditAccount onOk={onAddAccountOk} onCancel={onAddAccountCancel} />
    } else {
      return null
    }
  }

  renderAddCategoryDialog () {
    const {
      addCategory,
      onAddCategoryOk,
      onAddCategoryCancel
    } = this.props
    if (addCategory) {
      return <EditCategory onOk={onAddCategoryOk} onCancel={onAddCategoryCancel} />
    } else {
      return null
    }
  }

  renderAddContactPersonDialog () {
    const {
      addContactPerson,
      onAddContactPersonOk,
      onAddContactPersonCancel
    } = this.props
    if (addContactPerson) {
      return <EditContactPerson onOk={onAddContactPersonOk} onCancel={onAddContactPersonCancel} />
    } else {
      return null
    }
  }

  renderAddDepartmentDialog () {
    const {
      addDepartment,
      onAddDepartmentOk,
      onAddDepartmentCancel
    } = this.props
    if (addDepartment) {
      return <EditDepartment onOk={onAddDepartmentOk} onCancel={onAddDepartmentCancel} />
    } else {
      return null
    }
  }

  renderAddEmployeeDialog () {
    const {
      addEmployee,
      onAddEmployeeOk,
      onAddEmployeeCancel
    } = this.props
    if (addEmployee) {
      return <EditEmployee onOk={onAddEmployeeOk} onCancel={onAddEmployeeCancel} />
    } else {
      return null
    }
  }

  renderAddObjectTypeDialog () {
    const {
      addObjectType,
      onAddObjectTypeOk,
      onAddObjectTypeCancel
    } = this.props
    if (addObjectType) {
      return <EditObjectType onOk={onAddObjectTypeOk} onCancel={onAddObjectTypeCancel} />
    } else {
      return null
    }
  }

  renderAddRoleDialog () {
    const {
      addRole,
      onAddRoleOk,
      onAddRoleCancel
    } = this.props
    if (addRole) {
      return <EditRole onOk={onAddRoleOk} onCancel={onAddRoleCancel} />
    } else {
      return null
    }
  }

  renderAddTagDialog () {
    const {
      addTag,
      onAddTagOk,
      onAddTagCancel
    } = this.props
    if (addTag) {
      return <EditTag onOk={onAddTagOk} onCancel={onAddTagCancel} />
    } else {
      return null
    }
  }

  render () {
    const {
      permissions,
      sharePointSaveEnabled
    } = this.props
    return (
      <BrowserRouter basename='/'>
        <div style={workspaceStyle}>
          {this.renderAddAccountDialog()}
          {this.renderAddCategoryDialog()}
          {this.renderAddContactPersonDialog()}
          {this.renderAddDepartmentDialog()}
          {this.renderAddEmployeeDialog()}
          {this.renderAddObjectTypeDialog()}
          {this.renderAddRoleDialog()}
          {this.renderAddTagDialog()}

          <TopBar />
          <div style={menuAndPageContainerStyle}>

            <SideBar>
              <SideBarMenu />
            </SideBar>

            <div style={pageStyle}>
              <Routes>
                <Route path='*' element={<NotFoundPage />} />
                {permissions.contact.canRead && <Route path='/' element={<ContactPage />} />}
                {permissions.contact.canRead && (
                  <Route path='contacts'>
                    <Route path='' element={<ContactPage />} />
                    <Route path='edit' element={<ContactDetails.View />} />
                    <Route path='new' element={<ContactDetails.New />} />
                  </Route>
                )}
                {permissions.contactPerson.canRead && (
                  <Route path='contact-persons'>
                    <Route path='' element={<ContactPersonPage />} />
                    <Route path='new' element={<ContactPersonDetails />} />
                    <Route path='edit' element={<ContactPersonDetails />} />
                  </Route>)}
                {permissions.project.canRead && (
                  <Route path='projects'>
                    <Route path='' element={<ProjectPage />} />
                    <Route path='edit' element={<ProjectDetails.View />} />
                    <Route path='new' element={<ProjectDetails.New />} />
                  </Route>
                )}
                {permissions.ticket.canRead && (
                  <Route path='tickets'>
                    <Route path='' element={<TicketPage />} />
                    <Route path='new' element={<TicketDetails.New />} />
                    <Route path='edit' element={<TicketDetails.View />} />
                  </Route>)}
                {permissions.department.canRead && sharePointSaveEnabled && <Route path='departments' element={<DepartmentPage />} />}
                {permissions.category.canRead && sharePointSaveEnabled && <Route path='categories' element={<CategoryPage />} />}
                {permissions.employee.canRead && <Route path='employees' element={<EmployeePage />} />}
                {permissions.account.canRead && <Route path='accounts' element={<AccountPage />} />}
                {permissions.role.canRead && <Route path='roles' element={<RolePage />} />}
                {permissions.tag.canRead && <Route path='tags' element={<TagPage />} />}
                {permissions.objectType.canRead && <Route path='object-types' element={<ObjectTypePage />} />}
                {permissions.isAdmin && sharePointSaveEnabled && <Route path='content-types' element={<ContentTypePage />} />}
                {permissions.timeEntry.canRead && <Route path='time-input' element={<TimeInputPage />} />}
                <Route exact path='settings' element={<SettingsPage />} />
              </Routes>
            </div>

          </div>
        </div>
      </BrowserRouter>
    )
  }
}

const workspaceStyle = {
  display: 'flex',
  flexDirection: 'column',
  height: '100vh'
}

const menuAndPageContainerStyle = {
  display: 'flex',
  height: '100vh'
}

const pageStyle = {
  borderLeft: '0.25px solid #c7c7c7',
  color: '#575f65',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflowY: 'auto'
}

const mapStateToProps = (state) => {
  return {
    addAccount: state.account.addItem,
    addCategory: state.category.addItem,
    addContactPerson: state.contactPerson.addItem,
    addDepartment: state.department.addItem,
    addEmployee: state.employee.addItem,
    addObjectType: state.objectType.addItem,
    addRole: state.role.addItem,
    addTag: state.tag.addItem,
    permissions: state.login.permissions,
    sharePointSaveEnabled: state.login.userData.sharePointSaveEnabled
  }
}

const mapDispatchToProps = (dispatch) => {
  const {
    actions: {
      account,
      category,
      contactPerson,
      department,
      employee,
      objectType,
      role,
      tag
    }
  } = redux
  return {
    onAddAccountOk: (a) => {
      dispatch(account.hideAddAccountDialog())
      dispatch(account.addAccount(a))
    },
    onAddAccountCancel: () => {
      dispatch(account.hideAddAccountDialog())
    },

    onAddCategoryOk: (c) => {
      dispatch(category.hideAddCategoryDialog())
      dispatch(category.addCategory(c))
    },
    onAddCategoryCancel: () => {
      dispatch(category.hideAddCategoryDialog())
    },

    onAddContactPersonOk: (c) => {
      dispatch(contactPerson.hideAddContactPersonDialog())
      dispatch(contactPerson.addContactPerson(c))
    },
    onAddContactPersonCancel: () => {
      dispatch(contactPerson.hideAddContactPersonDialog())
    },

    onAddDepartmentOk: (d) => {
      dispatch(department.hideAddDepartmentDialog())
      dispatch(department.addDepartment(d))
    },
    onAddDepartmentCancel: () => {
      dispatch(department.hideAddDepartmentDialog())
    },

    onAddEmployeeOk: (e) => {
      dispatch(employee.hideAddEmployeeDialog())
      dispatch(employee.addEmployee(e))
    },
    onAddEmployeeCancel: () => {
      dispatch(employee.hideAddEmployeeDialog())
    },

    onAddObjectTypeOk: (type) => {
      dispatch(objectType.hideAddObjectTypeDialog())
      dispatch(objectType.addObjectType(type))
    },
    onAddObjectTypeCancel: () => {
      dispatch(objectType.hideAddObjectTypeDialog())
    },

    onAddRoleOk: (r) => {
      dispatch(role.hideAddRoleDialog())
      dispatch(role.addRole(r))
    },
    onAddRoleCancel: () => {
      dispatch(role.hideAddRoleDialog())
    },

    onAddTagOk: (t) => {
      dispatch(tag.hideAddTagDialog())
      dispatch(tag.addTag(t))
    },
    onAddTagCancel: () => {
      dispatch(tag.hideAddTagDialog())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkSpace)
