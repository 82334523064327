import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { api } from 'domains'
import {
  styled,
  media,
  Card,
  Layout,
  Link,
  Topbar
} from '@pergas-common/pergas-components'
import {
  Add,
  CheckBook
} from '@pergas-common/pergas-icons'
import Divider from 'components/Divider'
import { selectLocale } from 'domains/locale/selectors'
import { AddTagV2 } from 'components/dialogs/AddTag'
import { TicketGrid } from 'components/TicketGrid'
import { Select, Input, Textarea } from 'components/Input'
import { TagList } from 'components/Tags'
import useRequest from 'hooks/useRequest'
import useUrlQuery from 'hooks/useUrlQuery'
import useDebounce from 'hooks/useDebounce'
import { itemsWithEmail } from '../../Input'
import { makeSelectorPermissionTicket } from '../../../redux/permission/selectors'

const Holder = styled.div`
  height: calc(100% + 600px);
  overflow-y: auto;
  background: rgb(247, 249, 250);
`
const Overflow = styled.div`
  overflow-y: hidden;
  height: 218px;
`
const TagHolder = styled.div`
  width: 100%;
  > div {
    padding: 0px 6px 0 0;
  }
  ${media.medium`
    width: 92%;
  `}
  height: 268px;
`

const Background = styled.div`
  background: ${({ theme }) => theme.palette.brand.secondary};
`
const Name = styled.span`
  ${({ theme }) => theme.typography.body};
  color: ${({ theme }) => theme.palette.common.primary};
`

const findResponsiblePersonRole = (personRole) => {
  const found = personRole?.find((pr) => pr.role_id === 1)
  if (found) {
    return {
      name: found.person_name,
      id: found.person_id
    }
  }
  return {
    name: '',
    id: 0
  }
}

const REQUEST_PARAMS = {
  limit: 50,
  sort: 'name.asc'
}

export default ({ disable, values, setFieldValue, handleChange, handleSubmit, children }) => {
  const locale = useSelector(selectLocale)

  const navigate = useNavigate()
  const query = useUrlQuery()
  const id = query.get('id')
  const selectByPermissionTicket = useMemo(makeSelectorPermissionTicket, [])
  const permissions = useSelector(selectByPermissionTicket)

  const [isTagDialogOpen, setIsTagDialogOpen] = useState(false)
  const [getProjects, projects, projectStatus] = useRequest(api.getProjects, 'project')
  const [getRoles, roles] = useRequest(api.getRoles, 'roles')

  const [getEmployees, employees, employeeStatus] = useRequest(api.getEmployees, 'employee')
  const [debouncedEmployeesRequest] = useDebounce(getEmployees, 300)
  const [debouncedProjectsRequest] = useDebounce(getProjects, 300)

  const isFetchingProject = projectStatus === 'pending'
  const isFetchingEmployees = employeeStatus === 'pending'

  useEffect(() => {
    getProjects(REQUEST_PARAMS)
  }, [getProjects])

  useEffect(() => {
    getEmployees(REQUEST_PARAMS)
  }, [getEmployees])

  useEffect(() => {
    getRoles(REQUEST_PARAMS)
  }, [getRoles])

  const assignedTo = findResponsiblePersonRole(values.person_role)
  const assignedProject = projects && projects.find(({ id: _id }) => _id === values.project_id)
  const assignedProjectTo = assignedProject && findResponsiblePersonRole(assignedProject.person_role)

  const defaultProject = { name: values.project_name || '', id: values.project_id || 0 } // eslint-disable-line camelcase

  const onTagSelect = useCallback((selected) => {
    const tag = { ...selected, tag_id: selected.id }

    if (values.tags == null) {
      setFieldValue('tags', [tag])
      return
    }
    const tagAlreadyExist = values.tags.some(({ tag_id: tagId }) => tagId === selected.id)
    if (!tagAlreadyExist) {
      setFieldValue('tags', [tag, ...values.tags])
    }
  }, [setFieldValue, values.tags])

  const onResponsible = useCallback((_, __, selected) => {
    const roleExists = roles?.find((r) => r.id === 1)
    const withoutPersonRole = values.person_role?.filter((pr) => pr.role_id !== 1) ?? []

    if (roleExists) {
      const mergedRoles = [...withoutPersonRole, {
        person_id: selected.id,
        person_name: selected.name,
        role_id: roleExists.id,
        role_name: roleExists.name,
        role_internal_name: roleExists.internal_name
      }]
      setFieldValue('person_role', mergedRoles)
    }
  }, [roles, setFieldValue, values.person_role])

  return (
    <>
      <Background>
        <Topbar left={<Name>{values.name}</Name>} />
      </Background>
      <Holder>
        <Layout.Main>
          <Layout.Aside>
            <form onSubmit={handleSubmit} id='submitTicket'>
              {isTagDialogOpen && <AddTagV2
                tagType='ticket'
                isOpen={isTagDialogOpen}
                onCloseRequest={() => {
                  setIsTagDialogOpen(false)
                }}
                onSelect={onTagSelect}
                                  />}
              <Input name='name' label={locale.task} disabled={disable} value={values.name} handleChange={handleChange} />
              <Textarea name='description' label='Beskrivning' disabled={disable} value={values.description} handleChange={handleChange} />
              <Divider />
              <Select
                name='project_id'
                label={locale.project}
                disabled={disable}
                defaultValue={defaultProject}
                request={(input) => {
                  debouncedProjectsRequest({ query: [{ key: 'name', op: '~', value: input }], ...REQUEST_PARAMS })
                }}
                requestStatus={isFetchingProject}
                items={projects}
                handleChange={(_, id, item) => {
                  setFieldValue('project_name', item.name)
                  setFieldValue('project_id', id)
                }}
              >
                <CheckBook
                  onClick={() => {
                    if (values.project_id) {
                      navigate(`/projects/edit?id=${values.project_id}`)
                    }
                  }} color='#28afe0' width={18} height={18}
                />
              </Select>
              <Input name='responsible_project_person_role' label={locale.project_responsible} disabled value={assignedProjectTo ? assignedProjectTo.name : ''} handleChange={() => {}} />
              <Select
                name='responsible_person_role'
                label='Uppgift tilldelat'
                disabled={disable}
                defaultValue={assignedTo}
                request={(input) => {
                  debouncedEmployeesRequest({ query: [{ key: 'name', op: '~', value: input }], ...REQUEST_PARAMS })
                }}
                requestStatus={isFetchingEmployees}
                items={itemsWithEmail(employees)}
                handleChange={onResponsible}
              />
              <Input name='started_at' type='date' label={locale.started_at} disabled={disable} value={values.started_at} handleChange={handleChange} />
              <Input name='expires_at' type='date' label={locale.expires_at} disabled={disable} value={values.expires_at} handleChange={handleChange} />
              <Input name='estimate_value' type='number' label={locale.estimate_value} disabled={disable} value={values.estimate_value} handleChange={handleChange} />
              <TagHolder>
                <Card.Content>
                  <Card.Header title={locale.tags}>
                    {!disable && (
                      <Link onClickHandler={() => { !disable && setIsTagDialogOpen(true) }}>
                        <Add color='#28afe0' width={20} height={20} />
                      </Link>
                    )}
                  </Card.Header>
                  <Overflow>
                    <TagList name='tags' disabled={disable} tags={values.tags} handleChange={setFieldValue} />
                  </Overflow>
                </Card.Content>
              </TagHolder>
            </form>
          </Layout.Aside>
          <Layout.Section>
            {children}
            <Card.Content>
              <Card.Header title={locale.tasks_in_same_project} columns='4fr 2fr 2fr 1fr' extra={[locale.assigned, locale.should_be_finished_by]}>
                {permissions.canCreate && <Link onClickHandler={() => { navigate('/tickets/new', { state: { ticket: values, project: { ...assignedProject } } }) }}><Add width={20} height={20} color='#28afe0' /></Link>}
              </Card.Header>
              <Overflow>
                <TicketGrid currentTicket={Number(id)} projectId={values.project_id} canEdit={!disable} />
              </Overflow>
            </Card.Content>
          </Layout.Section>
        </Layout.Main>
      </Holder>
    </>
  )
}
