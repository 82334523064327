import { pageDefault } from '../common.js'
import {
  CATEGORY_CREATE_ACTION,
  CATEGORY_SHOW_ADD_DIALOG_ACTION,
  CATEGORY_HIDE_ADD_DIALOG_ACTION,
  CATEGORY_GET_OK_ACTION,
  CATEGORY_UPDATE_ACTION,
  CATEGORY_SHOW_EDIT_DIALOG_ACTION,
  CATEGORY_HIDE_EDIT_DIALOG_ACTION,
  CATEGORY_DELETE_ACTION,
  CATEGORY_SHOW_DELETE_DIALOG_ACTION,
  CATEGORY_HIDE_DELETE_DIALOG_ACTION,

  CATEGORY_GET_PAGE_ITEMS_ACTION,
  CATEGORY_SET_ORDER_ACTION,
  CATEGORY_SET_LIMIT_ACTION,
  CATEGORY_SET_OFFSET_ACTION,
  CATEGORY_SET_SEARCH_ACTION,
  CATEGORY_RESET_SEARCH_ACTION,
  CATEGORY_SET_FILTER_QUERIES_ACTION,

  CATEGORY_SET_SELECTED_ITEM_ID
} from './actions.js'

const initialState = pageDefault('name', 'category')

/**
 * Reducer for handling categories.
 */
export function category (state = initialState, action) {
  switch (action.type) {
    case CATEGORY_GET_OK_ACTION:
      return { ...state, items: action.items.slice() }
    case CATEGORY_CREATE_ACTION:
      return {
        ...state,
        items: state.items.concat(action.category),
        pageItems: [action.category].concat(state.pageItems)
      }
    case CATEGORY_SHOW_ADD_DIALOG_ACTION:
      return { ...state, addItem: true }
    case CATEGORY_HIDE_ADD_DIALOG_ACTION:
      return { ...state, addItem: false }
    case CATEGORY_UPDATE_ACTION:
      return {
        ...state,
        items: state.items.map(c => {
          return c.id === action.category.id ? action.category : c
        }),
        pageItems: state.pageItems.map(c => {
          if (c.id === action.category.id) {
            return action.category
          }
          return c
        })
      }
    case CATEGORY_SHOW_EDIT_DIALOG_ACTION:
      return { ...state, editItem: action.category }
    case CATEGORY_HIDE_EDIT_DIALOG_ACTION:
      return { ...state, editItem: null }
    case CATEGORY_DELETE_ACTION:
      return {
        ...state,
        items: state.items.filter(c => c.id !== action.category.id),
        pageItems: state.pageItems.filter(c => c.id !== action.category.id)
      }
    case CATEGORY_SHOW_DELETE_DIALOG_ACTION:
      return { ...state, deleteItem: action.category }
    case CATEGORY_HIDE_DELETE_DIALOG_ACTION:
      return { ...state, deleteItem: null }

    case CATEGORY_GET_PAGE_ITEMS_ACTION:
      return { ...state, pageItems: action.pageItems }
    case CATEGORY_SET_ORDER_ACTION: {
      const { order, orderBy } = action
      return { ...state, order, orderBy, offset: 0 }
    }
    case CATEGORY_SET_LIMIT_ACTION:
      return { ...state, limit: action.limit, offset: 0 }
    case CATEGORY_SET_OFFSET_ACTION:
      return { ...state, offset: action.offset }
    case CATEGORY_SET_SEARCH_ACTION:
      return { ...state, search: action.search, offset: 0 }
    case CATEGORY_RESET_SEARCH_ACTION:
      return { ...state, search: '', offset: 0 }
    case CATEGORY_SET_FILTER_QUERIES_ACTION:
      return { ...state, filterQueries: action.filterQueries, offset: 0 }

    case CATEGORY_SET_SELECTED_ITEM_ID:
      return { ...state, selectedItemId: action.selectedItemId }

    default:
      return state
  }
}
