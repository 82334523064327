import { useMemo, useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import redux, { api } from 'domains'
import { busy, notBusy } from 'domains/busy/reducer'
import { makeSelectMetadataByRequestId, selectStatusByRequestId } from 'domains/busy/selectors'
import { handleGetErrors, handleAddErrors, handleDeleteErrors, handleUpdateErrors } from 'domains/error/actions'
import { makeSelectorByAuthTokens } from 'domains/login/selectors'
import { useRequestId } from 'hooks/useRequestId'
import { makeSelectorById, makeSelectorTicketByProjectId } from './selectors'

const { actions } = redux

export function useGetTicketByIdQuery (id) {
  const reqId = useRequestId(id)
  const dispatch = useDispatch()
  const [refetching, setRefetching] = useState(false)
  const selectTicketById = useMemo(makeSelectorById, [])
  const selectAuthTokens = useMemo(makeSelectorByAuthTokens, [])

  const status = useSelector((state) => selectStatusByRequestId(state, reqId))
  const data = useSelector((state) => selectTicketById(state, id))
  const authTokens = useSelector(selectAuthTokens)

  const effects = useCallback((requestId, params) => {
    dispatch(busy({ requestId }))
    api.getTickets(params, authTokens).then(result => {
      dispatch(actions.ticket.receivedTickets(result.data.value))
      dispatch(notBusy({ requestId }))
    }).catch(handleGetErrors('ticket', dispatch, requestId))
  }, [dispatch, authTokens])

  useEffect(() => {
    if (status === undefined || refetching) {
      effects(reqId, { query: [{ key: 'id', op: '=', value: id }] })
      setRefetching(false)
    }
  }, [effects, id, reqId, status, refetching, setRefetching])

  const refetch = useCallback(() => {
    setRefetching(true)
  }, [setRefetching])

  const isLoading = status === 'pending' || status === undefined
  const isError = status === 'rejected'
  const isSuccessful = status === 'fulfilled'
  const isUnitialized = status === undefined
  return { isUnitialized, isError, isSuccessful, data, refetch, isLoading }
}

export function useGetTicketByProjectIdQuery (id) {
  const reqId = useRequestId()
  const dispatch = useDispatch()
  const [refetching, setRefetching] = useState(false)
  const selectTickets = useMemo(makeSelectorTicketByProjectId, [])
  const selectAuthTokens = useMemo(makeSelectorByAuthTokens, [])

  const status = useSelector((state) => selectStatusByRequestId(state, reqId))
  const data = useSelector((state) => selectTickets(state, id))
  const authTokens = useSelector(selectAuthTokens)

  const effects = useCallback((requestId, params) => {
    dispatch(busy({ requestId }))
    api.getTickets(params, authTokens).then(result => {
      dispatch(actions.ticket.receivedTickets(result.data.value))
      dispatch(notBusy({ requestId }))
    }).catch(handleGetErrors('ticket', dispatch, requestId))
  }, [dispatch, authTokens])

  useEffect(() => {
    if (!id) {
      return
    }
    if (status === undefined || refetching) {
      effects(reqId, { query: [{ key: 'project_id', op: '=', value: id }], sort: 'expires_at.asc' })
      setRefetching(false)
    }
  }, [effects, id, reqId, status, refetching, setRefetching])

  const refetch = useCallback(() => {
    setRefetching(true)
  }, [setRefetching])

  const isLoading = status === 'pending' || status === undefined
  const isError = status === 'rejected'
  const isSuccessful = status === 'fulfilled'
  const isUnitialized = status === undefined
  return { isUnitialized, isError, isSuccessful, data, refetch, isLoading }
}

export function useAddTicketMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()
  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])
  const selectAuthTokens = useMemo(makeSelectorByAuthTokens, [])

  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))
  const authTokens = useSelector(selectAuthTokens)

  const mutation = useCallback((ticket) => {
    dispatch(busy({ requestId }))
    api.addTicket(ticket, authTokens).then(result => {
      dispatch(notBusy({ requestId, id: result.data.value?.id }))
    }).catch(handleAddErrors('ticket', dispatch))
  }, [dispatch, requestId, authTokens])

  const isUnitialized = status === 'pending' || status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'
  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}

export function useUpdateTicketMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()

  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])
  const selectAuthTokens = useMemo(makeSelectorByAuthTokens, [])
  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))
  const authTokens = useSelector(selectAuthTokens)

  const mutation = useCallback((ticket) => {
    dispatch(busy({ requestId }))
    api.updateTicket(ticket, authTokens).then(result => {
      dispatch(notBusy({ requestId, id: result.data.value?.id }))
    }).catch(handleUpdateErrors('ticket', dispatch, requestId))
  }, [dispatch, requestId])

  const isUnitialized = status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'

  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}

export function useDeleteTicketMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()

  const selectAuthTokens = useMemo(makeSelectorByAuthTokens, [])
  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])
  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))
  const authTokens = useSelector(selectAuthTokens)

  const mutation = useCallback((ticket) => {
    dispatch(busy({ requestId }))
    api.deleteTicket(ticket, authTokens).then(() => {
      dispatch(notBusy({ requestId }))
      dispatch(actions.ticket.getTickets())
    }).catch(handleDeleteErrors('ticket', dispatch, requestId))
  }, [dispatch, requestId])

  const isUnitialized = status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'

  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}
