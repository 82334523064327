import { busy, notBusy } from '../busy/actions.js'
import {
  handleAddErrors,
  handleGetErrors,
  handleUpdateErrors,
  handleDeleteErrors
} from '../error/actions.js'
import { getAuthTokens } from '../common.js'
import storage from '../storage.js'
import { resetFileSearch } from '../files/actions.js'

export const CONTACT_CREATE_ACTION = 'CONTACT_CREATE_ACTION'
export const CONTACT_SHOW_ADD_DIALOG_ACTION = 'CONTACT_SHOW_ADD_DIALOG_ACTION'
export const CONTACT_HIDE_ADD_DIALOG_ACTION = 'CONTACT_HIDE_ADD_DIALOG_ACTION'
export const CONTACT_GET_OK_ACTION = 'CONTACT_GET_OK_ACTION'
export const CONTACT_UPDATE_ACTION = 'CONTACT_UPDATE_ACTION'
export const CONTACT_SHOW_EDIT_DIALOG_ACTION = 'CONTACT_SHOW_EDIT_DIALOG_ACTION'
export const CONTACT_HIDE_EDIT_DIALOG_ACTION = 'CONTACT_HIDE_EDIT_DIALOG_ACTION'
export const CONTACT_DELETE_ACTION = 'CONTACT_DELETE_ACTION'
export const CONTACT_SHOW_DELETE_DIALOG_ACTION = 'CONTACT_SHOW_DELETE_DIALOG_ACTION'
export const CONTACT_HIDE_DELETE_DIALOG_ACTION = 'CONTACT_HIDE_DELETE_DIALOG_ACTION'

export const CONTACT_GET_PAGE_ITEMS_ACTION = 'CONTACT_GET_PAGE_ITEMS_ACTION'
export const CONTACT_SET_ORDER_ACTION = 'CONTACT_SET_ORDER_ACTION'
export const CONTACT_SET_LIMIT_ACTION = 'CONTACT_SET_LIMIT_ACTION'
export const CONTACT_SET_OFFSET_ACTION = 'CONTACT_SET_OFFSET_ACTION'
export const CONTACT_SET_SEARCH_ACTION = 'CONTACT_SET_SEARCH_ACTION'
export const CONTACT_RESET_SEARCH_ACTION = 'CONTACT_RESET_SEARCH_ACTION'
export const CONTACT_SET_FILTER_QUERIES_ACTION = 'CONTACT_SET_FILTER_QUERIES_ACTION'
export const CONTACT_SET_SELECTED_ITEM_ID = 'CONTACT_SET_SELECTED_ITEM_ID'

export function contactCreateAction (contact) {
  return {
    type: CONTACT_CREATE_ACTION,
    contact
  }
}

export function contactUpdateAction (contact) {
  return {
    type: CONTACT_UPDATE_ACTION,
    contact
  }
}

export function contactDeleteAction (contact) {
  return {
    type: CONTACT_DELETE_ACTION,
    contact
  }
}

export function contact (api, store) {
  function setSelectedItemId (selectedItemId) {
    return function (dispatch) {
      dispatch({
        type: CONTACT_SET_SELECTED_ITEM_ID,
        selectedItemId
      })
      dispatch(resetFileSearch('contact'))
    }
  }

  /**
   * Delete dialog
   */
  function showDeleteContactDialog (contact) {
    return {
      type: CONTACT_SHOW_DELETE_DIALOG_ACTION,
      contact
    }
  }

  function hideDeleteContactDialog () {
    return {
      type: CONTACT_HIDE_DELETE_DIALOG_ACTION
    }
  }

  /**
   * CREATE
   */
  function addContact (contact) {
    return function (dispatch) {
      dispatch(busy())
      api.addContact(contact, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleAddErrors('contact', dispatch))
    }
  }

  /**
   * READ
   */
  function getContacts (params = {}) {
    return function (dispatch) {
      dispatch(busy())
      api.getContacts(params, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
        dispatch(receivedContacts(result.data.value))
      }).catch(handleGetErrors('contact', dispatch))
    }
  }

  function receivedContacts (items) {
    return {
      type: CONTACT_GET_OK_ACTION,
      items
    }
  }

  /**
   * UPDATE
   */
  function updateContact (contact) {
    return function (dispatch) {
      dispatch(busy())
      api.updateContact(contact, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleUpdateErrors('contact', dispatch))
    }
  }

  /**
   * DELETE
   */
  function deleteContact (contact) {
    return function (dispatch) {
      dispatch(busy())
      api.deleteContact(contact, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
        dispatch(getContacts())
      }).catch(handleDeleteErrors('contact', dispatch))
    }
  }

  function toggleFavorite (contact) {
    return function (dispatch) {
      const favorite = !contact.is_favorite
      dispatch(busy())
      api.setContactFavorite(contact.id, favorite, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleUpdateErrors('contact', dispatch))
    }
  }

  /**
   * Get items for a page
   */
  function getPageItems () {
    const state = store.getState()
    const { search, filterQueries, limit, offset, orderBy, order } = state.contact

    let query = []
    if (typeof search === 'string' && search.length > 0) {
      const split = search.split(',').map(s => s.trim()).filter(Boolean)
      if (split.length) {
        query.push({ key: 'name', value: split[0], op: '~' })
      }
    }

    if (Array.isArray(filterQueries) && filterQueries.length > 0) {
      query = [...query, ...filterQueries]
    }

    const sort = orderBy ? `${orderBy}.${order}` : null
    return function (dispatch) {
      getContacts({
        query,
        limit,
        offset,
        sort
      })(dispatch)
    }
  }

  /**
   * Sort order
   */
  function setOrder (orderBy, order) {
    return {
      type: CONTACT_SET_ORDER_ACTION,
      orderBy,
      order
    }
  }

  /**
   * Set limit for pagination
   */
  function setLimit (limit) {
    return {
      type: CONTACT_SET_LIMIT_ACTION,
      limit
    }
  }

  /**
   * Set offset for pagination
   */
  function setOffset (offset) {
    return {
      type: CONTACT_SET_OFFSET_ACTION,
      offset
    }
  }

  /**
   * Set search
   */
  function setSearch (search) {
    return {
      type: CONTACT_SET_SEARCH_ACTION,
      search
    }
  }

  /**
   * Set filter queries
   */
  function setFilterQueries (filterQueries) {
    storage.putPageSetting('contact', { filterQueries })
    return {
      type: CONTACT_SET_FILTER_QUERIES_ACTION,
      filterQueries
    }
  }
  /*
   * Reset search
   */
  function resetSearch () {
    return {
      type: CONTACT_RESET_SEARCH_ACTION
    }
  }

  return {
    setSelectedItemId,

    showDeleteContactDialog,
    hideDeleteContactDialog,
    addContact,
    getContacts,
    updateContact,
    deleteContact,
    toggleFavorite,
    receivedContacts,

    getPageItems,
    setOrder,
    setLimit,
    setOffset,
    setSearch,
    resetSearch,
    setFilterQueries
  }
}
