import React, { useCallback, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { TablePagination } from '@mui/material'
import {
  Link,
  Table,
  TD as Td,
  styled
} from '@pergas-common/pergas-components'
import {
  ContentTypeIcon,
  ResetContentTypesIcons
} from '../icons.js'
import PageToolbar from './PageToolbar.js'
import redux from '../../redux/index.js'
import SearchField from '../SearchField.js'

const TableHolder = styled.div`
  display: flex;
  position: absolute;
  top: 100px;
  bottom: 0;
  overflow-y: scroll;
`

const NameHolder = styled.div`
  display: inline-block;
  margin: 0 8px;
`

const ROWS_PER_PAGE = [
  25,
  50,
  100,
  500,
  1000
]

const ContentTypePage = ({
  locale,
  rows,
  limit,
  offset,
  orderBy,
  order,
  search,
  resetSearch,
  getPageItems,
  setOrder,
  setLimit,
  setOffset,
  setSearch,
  onResetContentTypes
}) => {
  useEffect(getPageItems, [limit, offset, orderBy, order, search])

  const manualSort = useCallback(({ id, isSorted, isSortedDesc }) => {
    if (isSorted && !isSortedDesc) {
      setOrder(id, 'desc')
    } else if (isSorted && isSortedDesc) {
      setOrder('', '')
    } else {
      setOrder(id, 'asc')
    }
  }, [setOrder])

  const columns = useMemo(() => {
    return [
      {
        Header: locale.content_type_name,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (
            <Td
              {...cell.getCellProps()} left={
                <>
                  <ContentTypeIcon style={{ width: 16, height: 16 }} />
                  <NameHolder>
                    <span>{original.content_type_name}</span>
                  </NameHolder>
                </>
            }
            />
          )
        },
        canSort: true,
        id: 'content_type_name',
        size: 'md',
        manualSort,
        sortType: () => {}
      },
      {
        Header: locale.field_internal_name,
        accessor: 'field_internal_name',
        manualSort,
        sortType: () => {}
      },
      {
        Header: locale.field_type_as_string,
        accessor: 'field_type_as_string',
        manualSort,
        sortType: () => {}
      },
      {
        Header: locale.field_id,
        accessor: 'field_id',
        manualSort,
        sortType: () => {}
      },
      {
        Header: locale.field_title,
        accessor: 'field_title',
        manualSort,
        sortType: () => {}
      }
    ]
  }, [locale, manualSort])

  let initialSortBy = []
  if (orderBy) {
    initialSortBy = [{
      id: orderBy,
      desc: order === 'desc'
    }]
  }

  return (
    <>
      <PageToolbar left={<><ContentTypeIcon style={{ width: 20, height: 20 }} /><span>{locale.content_types}</span><Link onClickHandler={onResetContentTypes}><ResetContentTypesIcons style={{ width: 20, height: 20, color: '#28afe0' }} /></Link></>}>
        <SearchField resetSearch={resetSearch} value={search} onChange={setSearch} />
      </PageToolbar>
      <TableHolder>
        <Table columns={columns} data={rows} initialPageSize={limit} initialSortBy={initialSortBy}>
          {({ setPageSize }) => (
            <TablePagination
              rowsPerPage={limit}
              rowsPerPageOptions={ROWS_PER_PAGE}
              count={-1}
              page={offset / limit}
              labelRowsPerPage={locale.rows_per_page}
              labelDisplayedRows={({ from, to }) => `${from}-${to}`}
              onRowsPerPageChange={(e) => {
                setLimit(e.target.value)
                setPageSize(e.target.value)
              }}
              onPageChange={(e, number) => setOffset(number * limit)}
            />
          )}
        </Table>
      </TableHolder>
    </>
  )
}

const mapStateToProps = (state) => {
  const { locale, contentType } = state
  const {
    pageItems,
    limit,
    offset,
    orderBy,
    order,
    search
  } = contentType
  return {
    locale: locale.strings,
    rows: pageItems,
    limit,
    offset,
    orderBy,
    order,
    search
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions: { contentType } } = redux
  return {
    onResetContentTypes: () => dispatch(contentType.resetContentTypes()),
    getPageItems: () => dispatch(contentType.getPageItems()),
    setOrder: (orderBy, order) => dispatch(contentType.setOrder(orderBy, order)),
    setLimit: (limit) => dispatch(contentType.setLimit(limit)),
    setOffset: (offset) => dispatch(contentType.setOffset(offset)),
    setSearch: (search) => dispatch(contentType.setSearch(search)),
    resetSearch: () => dispatch(contentType.resetSearch())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentTypePage)
