import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { styled, media, Button, Group } from '@pergas-common/pergas-components'
import { CheckboxEmpty, CheckboxFilled, Email, Favorite, FavoriteFilled, Folder, Remove, Sharepoint } from '@pergas-common/pergas-icons'
import { selectLocale } from 'domains/locale/selectors'
import { makeSelectorByIsFavorite, makeSelectorById, makeSelectorBySharepointStatus, makeSelectorBySharepointUrl } from 'domains/project/selectors'
import { useDeleteProjectMutation, useUpdateProjectMutation, useFavoriteProjectMutation } from 'domains/project/hooks'
import useUrlQuery from 'hooks/useUrlQuery'
import { DeleteItemV2 } from 'components/dialogs/DeleteItem'
import AsyncLoader from 'components/AsyncLoader'
import dayjs from 'dayjs'
import { useFormikValues } from '.'

const Holder = styled.div`
  display: flex;
  max-width: 850px;
  align-items: stretch;
  flex-direction: column;
  > div:nth-child(1) {
    width: 100%;
    max-width: 320px;
    flex: 0 0 32px;  
    ${media.medium`
      flex: 1;
      max-width: none;
    `}
  }
  ${media.medium`
    flex-direction: row;
  `}
`
function toggleEndedProjectPayload (project = {}) {
  const {
    name = '',
    description = '',
    ended_at = null,
    started_at = dayjs(),
    expires_at = dayjs().add(1, 'day'),
    teams_url = '',
    person_role = [],
    object_type_name = '',
    object_type_id = 0,
    collection_name = '',
    collection_id = 0,
    tags = []
  } = project
  return {
    name,
    description,
    ended_at,
    started_at: dayjs(started_at).format('YYYY-MM-DD'),
    expires_at: dayjs(expires_at).format('YYYY-MM-DD'),
    teams_url,
    person_role,
    object_type_id,
    object_type_name,
    collection_name,
    collection_id,
    tags
  }
}
export default ({ values, children }) => {
  const query = useUrlQuery()
  const id = query.get('id')

  const navigate = useNavigate()
  const [remove, setRemove] = useState(false)
  const locale = useSelector(selectLocale)
  const { mutation: updateMutation, isLoading: isMutating } = useUpdateProjectMutation()
  const { mutation: favoriteMutation, isLoading: isFavoriteLoading } = useFavoriteProjectMutation()
  const { mutation: deleteMutation, isLoading: isDeleting, isSuccessful } = useDeleteProjectMutation()
  const onToggleEnded = useFormikValues(updateMutation)

  const selectByFavorite = useMemo(makeSelectorByIsFavorite, [])
  const selectProjectById = useMemo(makeSelectorById, [])
  const selectorBySharepointStatus = useMemo(makeSelectorBySharepointStatus, [])
  const selectorBySharepointUrl = useMemo(makeSelectorBySharepointUrl, [])

  const sharepointStatus = useSelector((state) => selectorBySharepointStatus(state, id))
  const sharepointUrl = useSelector((state) => selectorBySharepointUrl(state, id))
  const isFavorite = useSelector((state) => selectByFavorite(state, id))
  const project = useSelector((state) => selectProjectById(state, id))

  useEffect(() => {
    if (isSuccessful) {
      navigate('/projects', { replace: true })
    }
  }, [navigate, isSuccessful])

  const disableSharepoint = !sharepointUrl || sharepointStatus !== 'finished'
  const sharepointSiteAsyncLoader = <AsyncLoader loading={disableSharepoint}><Sharepoint width={18} height={18} /></AsyncLoader>
  const sharepointDocumentAsyncLoader = <AsyncLoader loading={disableSharepoint}><Folder width={20} height={20} /></AsyncLoader>
  const toggleProjectDoneAsync = <AsyncLoader loading={isMutating}>{project?.ended_at ? <CheckboxFilled width={18} height={18} color='#34b3e1' /> : <CheckboxEmpty width={18} height={18} color='#34b3e1' />}</AsyncLoader>
  const favoriteAsyncLoader = <AsyncLoader loading={isFavoriteLoading}>{isFavorite ? <FavoriteFilled width={18} height={18} color='#34b3e1' /> : <Favorite width={18} height={18} color='#34b3e1' />}</AsyncLoader>
  const deleteAsyncLoader = <AsyncLoader loading={isDeleting}><Remove color='#b13a22' width={20} height={20} /></AsyncLoader>
  const deleteButton = <Button type='button' disabled={!id} onClick={() => { setRemove(true) }}><span>{locale.delete}</span>{deleteAsyncLoader}</Button>
  const shareButton = (
    <Button
      type='button' disabled={!id} onClick={() => {
        window.location = `mailto:?subject=${values.name}&body=${window.location.href}`
      }}
    ><span>{locale.share}</span><Email color='#34b3e1' width={20} height={20} />
    </Button>
  )

  const onSharepointClick = (url) => {
    if (!url) {
      return
    }
    window.open(url)
  }

  const toggleFavorite = <Button type='button' disabled={isFavoriteLoading || !id} onClick={(e) => { favoriteMutation({ id, is_favorite: isFavorite }) }}><span>{locale.favorite}</span>{favoriteAsyncLoader}</Button>
  const sharepointStartPageButton = <Button type='button' disabled={disableSharepoint} onClick={() => { onSharepointClick(sharepointUrl) }}><span>{locale.startpage}</span>{sharepointSiteAsyncLoader}</Button>
  const sharepointDocumentPageButton = <Button type='button' disabled={disableSharepoint} onClick={() => { onSharepointClick(`${sharepointUrl}/files/Forms/AllItems.aspx`) }}><span>{locale.documents}</span>{sharepointDocumentAsyncLoader}</Button>
  const toggleProjectDoneButton = (
    <Button
      type='button' disabled={!id || isMutating} onClick={() => {
        onToggleEnded({ id: project.id, ...toggleEndedProjectPayload(project), ended_at: project.ended_at ? null : dayjs().format('YYYY-MM-DD') })
      }}
    ><span>{locale.finished}</span>{toggleProjectDoneAsync}
    </Button>
  )

  return (
    <Holder>
      <div>
        {remove && <DeleteItemV2
          text={values.name}
          isOpen={remove}
          onCloseRequest={() => {
            setRemove(false)
          }}
          onSubmit={() => {
            deleteMutation({ id })
            setRemove(false)
          }}
                   />}
        <Group.Button>
          {sharepointStartPageButton}
          {shareButton}
          {deleteButton}
        </Group.Button>
        <Group.Button>
          {sharepointDocumentPageButton}
          {children}
        </Group.Button>
      </div>
      <div>
        <Group.Button>
          {toggleFavorite}
          {toggleProjectDoneButton}
        </Group.Button>
      </div>
    </Holder>
  )
}
