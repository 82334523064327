import React, { useState } from 'react'
import { connect } from 'react-redux'
import Accordion from './Accordion.jsx'
import { TreeView, TreeItem } from '@mui/x-tree-view'
import { styled, List } from '@pergas-common/pergas-components'
import { Add, ArrowDown, ArrowRight, Open, YellowFolder as Folder } from '@pergas-common/pergas-icons'
import SearchField from './SearchField.js'
import { canOpenDocument, openDocument } from '../util.js'
import { getFileIcon } from './file-icons.js'
import AddFolder from 'components/dialogs/AddFolder'
import redux from '../redux'

const DUMMY_FILE_NODE = Symbol('DUMMY_FILE_NODE')

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -8px;
`

const NameWrapper = styled.div`
  user-select: none;
  margin-left: 5px;
`

const TreeViewHolder = styled.div`
  margin-top: 5px;
`

const FolderLabel = ({ name }) => {
  return (
    <LabelWrapper>
      <div style={{ marginTop: 2 }}>
        <Folder width={20} height={20} />
      </div>
      <NameWrapper>{name}</NameWrapper>
    </LabelWrapper>
  )
}

const FileLabel = ({ name }) => {
  const isRealFile = name !== DUMMY_FILE_NODE
  if (isRealFile) {
    const Icon = getFileIcon(name)
    return (
      <LabelWrapper>
        <div style={{ marginTop: 2, marginLeft: 3 }}>
          <Icon width={20} height={20} />
        </div>
        <NameWrapper>{name}</NameWrapper>
      </LabelWrapper>
    )
  }
}

const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const buttonStyle = (active) => {
  if (active) {
    return { color: '#28afe0', cursor: 'pointer' }
  } else {
    return { color: 'lightgrey' }
  }
}

const FileView = ({
  // Parent params
  siteItem,
  right,
  onShowToggle,
  isOpen,
  initialHeight,
  onSetHeight,
  searchState,
  // Redux data params
  locale,
  trees,
  // Redux dispatch params
  getRootFolder,
  getSubFolder,
  setSearch,
  resetFileSearch,
  createFolder
}) => {
  const [expandedNodes, setExpandedNodes] = useState(['root'])
  const [selectedFileNode, setSelectedFileNode] = useState(null)
  const [selectedFolderNode, setSelectedFolderNode] = useState(null)
  const tree = siteItem ? trees[`${siteItem.type}:${siteItem.id}`] : null
  const { query, isSearching, results: searchResults } = searchState
  const [isFolderDialogOpen, setIsFolderDialogOpen] = useState(false)

  const Toolbar = () => {
    const canOpen = canOpenDocument(selectedFileNode?.uri)
    const onOpenClick = () => {
      if (canOpen) {
        openDocument(selectedFileNode.uri)
      }
    }
    const canAddFolder = !!selectedFolderNode
    const onAddFolderClick = () => {
      if (selectedFolderNode) {
        setIsFolderDialogOpen(true)
      }
    }
    return (
      <div style={{ marginTop: 5 }}>
        <Open style={buttonStyle(canOpen)} width={20} height={20} onClick={onOpenClick} />
        <Add style={buttonStyle(canAddFolder)} width={20} height={20} onClick={onAddFolderClick} />
      </div>
    )
  }

  const SearchResult = ({ name, id, onClick }) => {
    const Icon = getFileIcon(name)
    return (
      <List.Item selected={id === selectedFileNode?.id} onClick={onClick}>
        <div style={{ marginLeft: 10, marginTop: -3 }}>
          <Icon width={20} height={20} />
        </div>
        <NameWrapper>{name}</NameWrapper>
      </List.Item>
    )
  }

  const onFolderNodeClick = (node) => {
    setSelectedFileNode(null)
    setSelectedFolderNode(node)
    if (node.id === 'root') {
      setExpandedNodes(['root'])
      getRootFolder(siteItem)
    } else {
      if (!expandedNodes.includes(node.nodeId)) {
        setExpandedNodes(expandedNodes.concat(node.nodeId))
        getSubFolder(siteItem, node)
      } else {
        setExpandedNodes(expandedNodes.filter(nodeId => nodeId !== node.nodeId))
      }
    }
  }

  const onFileNodeClick = (event, node) => {
    setSelectedFolderNode(null)
    switch (event.detail) {
      case 1: {
        setSelectedFileNode(node)
        break
      }
      case 2: {
        if (canOpenDocument(selectedFileNode?.uri)) {
          openDocument(selectedFileNode.uri)
        }
        break
      }
    }
  }

  const renderTree = (node) => {
    if (node) {
      const isFolder = Array.isArray(node.children)
      if (isFolder) {
        let children = node.children
        if (!children.length) {
          children = [{
            id: '0',
            nodeId: '0',
            name: DUMMY_FILE_NODE
          }]
        }
        return (
          <TreeItem
            key={node.id}
            nodeId={node.nodeId}
            label={<FolderLabel name={node.name} />}
            collapseIcon={<ArrowDown width={8} height={8} style={{ marginTop: -3 }} />}
            expandIcon={<ArrowRight width={8} height={8} style={{ marginTop: -4 }} />}
            onClick={() => onFolderNodeClick(node)}
          >
            {children.map(renderTree)}
          </TreeItem>
        )
      } else {
        return (
          <TreeItem
            key={node.id}
            nodeId={node.nodeId}
            label={<FileLabel name={node.name} />}
            onClick={(event) => onFileNodeClick(event, node)}
          />
        )
      }
    }
  }

  const showSearchResults = Array.isArray(searchResults)

  const title = `${locale.documents}${siteItem ? ` - ${siteItemName(siteItem)}` : ''}`
  return (
    <Accordion
      isOpen={isOpen}
      title={title}
      initialHeight={initialHeight}
      left={<Toolbar />}
      center={
        <SearchField
          onChange={(s) => setSearch(siteItem, s)}
          resetSearch={() => resetFileSearch(siteItem)}
          isSearching={isSearching}
          value={query}
        />
      }
      right={right}
      onShowToggle={onShowToggle}
      onSetHeight={(height) => onSetHeight && onSetHeight(height)}
    >
      {
        isFolderDialogOpen &&
          <AddFolder
            isOpen={isFolderDialogOpen}
            onCloseRequest={(folderName) => {
              if (selectedFolderNode && folderName) {
                createFolder(siteItem, selectedFolderNode, folderName)
              }
              setIsFolderDialogOpen(false)
            }}
          />
      }
      {showSearchResults && (
        <ResultWrapper>
          <List.Items>
            {searchResults.map(row => <SearchResult onClick={(e) => onFileNodeClick(e, row)} key={row.id} name={row.name} id={row.id} />)}
          </List.Items>
        </ResultWrapper>
      )}
      {!showSearchResults && (
        <TreeViewHolder>
          <TreeView expanded={expandedNodes}>
            {renderTree(tree)}
          </TreeView>
        </TreeViewHolder>
      )}
    </Accordion>
  )
}

function siteItemName (siteItem) {
  if (siteItem.type === 'category') {
    return `${siteItem.collection_name} - ${siteItem.name}`
  } else {
    return siteItem.name
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.locale.strings,
    trees: state.files.trees
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions: { file } } = redux
  return {
    getRootFolder: (item) => dispatch(file.getRootFolder(item)),
    getSubFolder: (item, folderItem) => dispatch(file.getSubFolder(item, folderItem)),
    setSearch: (item, search) => {
      if (item) dispatch(file.searchFiles(item, search))
    },
    resetFileSearch: (item) => {
      if (item) dispatch(file.resetFileSearch(item?.type))
    },
    createFolder: (siteItem, item, folderName) => {
      dispatch(file.createFolder(siteItem, item, folderName))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileView)
