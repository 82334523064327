import { pageDefault } from '../common.js'
import {
  OBJECT_TYPE_CREATE_ACTION,
  OBJECT_TYPE_SHOW_ADD_DIALOG_ACTION,
  OBJECT_TYPE_HIDE_ADD_DIALOG_ACTION,
  OBJECT_TYPE_GET_OK_ACTION,
  OBJECT_TYPE_UPDATE_ACTION,
  OBJECT_TYPE_SHOW_EDIT_DIALOG_ACTION,
  OBJECT_TYPE_HIDE_EDIT_DIALOG_ACTION,
  OBJECT_TYPE_DELETE_ACTION,
  OBJECT_TYPE_SHOW_DELETE_DIALOG_ACTION,
  OBJECT_TYPE_HIDE_DELETE_DIALOG_ACTION,

  OBJECT_TYPE_GET_PAGE_ITEMS_ACTION,
  OBJECT_TYPE_SET_ORDER_ACTION,
  OBJECT_TYPE_SET_LIMIT_ACTION,
  OBJECT_TYPE_SET_OFFSET_ACTION,
  OBJECT_TYPE_SET_SEARCH_ACTION,
  OBJECT_TYPE_RESET_SEARCH_ACTION
} from './actions.js'

const initialState = pageDefault('name', 'object-type')

/**
 * Reducer for handling object types.
 */
export function objectType (state = initialState, action) {
  switch (action.type) {
    case OBJECT_TYPE_GET_OK_ACTION:
      return Object.assign({}, state, {
        items: action.items.slice()
      })
    case OBJECT_TYPE_CREATE_ACTION:
      return Object.assign({}, state, {
        items: state.items.concat(action.objectType),
        pageItems: [action.objectType].concat(state.pageItems)
      })
    case OBJECT_TYPE_SHOW_ADD_DIALOG_ACTION:
      return Object.assign({}, state, {
        addItem: true
      })
    case OBJECT_TYPE_HIDE_ADD_DIALOG_ACTION:
      return Object.assign({}, state, {
        addItem: false
      })
    case OBJECT_TYPE_UPDATE_ACTION:
      return Object.assign({}, state, {
        items: state.items.map(ot => ot.id === action.objectType.id ? action.objectType : ot),
        pageItems: state.pageItems.map(ot => ot.id === action.objectType.id ? action.objectType : ot)
      })
    case OBJECT_TYPE_SHOW_EDIT_DIALOG_ACTION:
      return Object.assign({}, state, {
        editItem: action.objectType
      })
    case OBJECT_TYPE_HIDE_EDIT_DIALOG_ACTION:
      return Object.assign({}, state, {
        editItem: null
      })
    case OBJECT_TYPE_DELETE_ACTION: {
      const { objectType } = action
      return Object.assign({}, state, {
        items: state.items.filter(ot => ot.id !== objectType.id),
        pageItems: state.pageItems.filter(ot => ot.id !== objectType.id)
      })
    }
    case OBJECT_TYPE_SHOW_DELETE_DIALOG_ACTION:
      return Object.assign({}, state, {
        deleteItem: action.objectType
      })
    case OBJECT_TYPE_HIDE_DELETE_DIALOG_ACTION:
      return Object.assign({}, state, {
        deleteItem: null
      })

    case OBJECT_TYPE_GET_PAGE_ITEMS_ACTION:
      return Object.assign({}, state, { pageItems: action.pageItems })
    case OBJECT_TYPE_SET_ORDER_ACTION: {
      const { order, orderBy } = action
      return Object.assign({}, state, { order, orderBy, offset: 0 })
    }
    case OBJECT_TYPE_SET_LIMIT_ACTION:
      return Object.assign({}, state, { limit: action.limit, offset: 0 })
    case OBJECT_TYPE_SET_OFFSET_ACTION:
      return Object.assign({}, state, { offset: action.offset })
    case OBJECT_TYPE_SET_SEARCH_ACTION:
      return Object.assign({}, state, { search: action.search, offset: 0 })
    case OBJECT_TYPE_RESET_SEARCH_ACTION:
      return Object.assign({}, state, { search: '', offset: 0 })
    default:
      return state
  }
}
