import { pageDefault } from '../common.js'
import {
  TICKET_CREATE_ACTION,
  TICKET_SHOW_ADD_DIALOG_ACTION,
  TICKET_HIDE_ADD_DIALOG_ACTION,
  TICKET_CHANGE_ORDERING_ACTION,
  TICKET_GET_OK_ACTION,
  TICKET_UPDATE_ACTION,
  TICKET_SHOW_EDIT_DIALOG_ACTION,
  TICKET_HIDE_EDIT_DIALOG_ACTION,
  TICKET_DELETE_ACTION,
  TICKET_SHOW_DELETE_DIALOG_ACTION,
  TICKET_HIDE_DELETE_DIALOG_ACTION,
  TICKET_SET_ORDER_ACTION,
  TICKET_SET_LIMIT_ACTION,
  TICKET_SET_OFFSET_ACTION,
  TICKET_SET_SEARCH_ACTION,
  TICKET_RESET_SEARCH_ACTION,
  TICKET_SET_FILTER_QUERIES_ACTION
} from './actions.js'

const initialState = pageDefault('name', 'ticket', {
  filterQueries: [{ key: 'ended_at', value: null, op: '=' }]
})

/**
 * Reducer for handling tickets.
 */

export function ticket (state = initialState, action) {
  let normalizedItems = {}
  switch (action.type) {
    case TICKET_GET_OK_ACTION:
      normalizedItems = { ...state.normalizedItems }
      action.items.reduce((_, item) => {
        normalizedItems[item.id] = item
        return normalizedItems
      }, {})
      return {
        ...state,
        items: action.items.slice(),
        normalizedItems
      }
    case TICKET_CREATE_ACTION:
      normalizedItems = { ...state.normalizedItems }
      normalizedItems[action.ticket.id] = action.ticket

      return {
        ...state,
        items: [action.ticket].concat(state.items),
        normalizedItems
      }
    case TICKET_SHOW_ADD_DIALOG_ACTION:
      return { ...state, addItem: true }
    case TICKET_HIDE_ADD_DIALOG_ACTION:
      return { ...state, addItem: false }
    case TICKET_CHANGE_ORDERING_ACTION: {
      const { order, orderBy } = action
      return { ...state, order, orderBy }
    }
    case TICKET_UPDATE_ACTION:
      normalizedItems = { ...state.normalizedItems }
      normalizedItems[action.ticket.id] = action.ticket

      return {
        ...state,
        normalizedItems,
        items: state.items.map(c => {
          if (c.id === action.ticket.id) {
            return action.ticket
          }
          return c
        })
      }
    case TICKET_SHOW_EDIT_DIALOG_ACTION:
      return { ...state, editItem: action.ticket }
    case TICKET_HIDE_EDIT_DIALOG_ACTION:
      return { ...state, editItem: null }
    case TICKET_DELETE_ACTION:
      normalizedItems = { ...state.normalizedItems }
      delete normalizedItems[action.ticket.id]
      return {
        ...state,
        normalizedItems,
        items: state.items.filter(c => {
          return c.id !== action.ticket.id
        })
      }
    case TICKET_SHOW_DELETE_DIALOG_ACTION:
      return { ...state, deleteItem: action.ticket }
    case TICKET_HIDE_DELETE_DIALOG_ACTION:
      return { ...state, deleteItem: null }
    case TICKET_SET_ORDER_ACTION: {
      const { order, orderBy } = action
      return { ...state, order, orderBy, offset: 0 }
    }
    case TICKET_SET_LIMIT_ACTION:
      return { ...state, limit: action.limit, offset: 0 }
    case TICKET_SET_OFFSET_ACTION:
      return { ...state, offset: action.offset }
    case TICKET_SET_SEARCH_ACTION:
      return { ...state, search: action.search, offset: 0 }
    case TICKET_RESET_SEARCH_ACTION:
      return { ...state, search: '', offset: 0 }
    case TICKET_SET_FILTER_QUERIES_ACTION:
      return { ...state, filterQueries: action.filterQueries, offset: 0 }

    default:
      return state
  }
}
