import React, { useEffect, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  styled,
  Button,
  Link,
  Waves,
  Layout
} from '@pergas-common/pergas-components'
import {
  Add,
  CheckBook,
  Company
} from '@pergas-common/pergas-icons'
import { Save } from '@mui/icons-material'
import { selectLocale } from 'domains/locale/selectors'
import { makeSelectorPermissionProject } from 'domains/permission/selectors'
import { useAddProjectMutation, useUpdateProjectMutation, useGetProjectByIdQuery } from 'domains/project/hooks'
import TopbarNavigation from 'components/TopbarNavigation'
import { usePermissionRoute } from 'hooks/usePermission'
import useUrlQuery from 'hooks/useUrlQuery'
import AsyncLoader from 'components/AsyncLoader'
import ProjectForm from './form'
import Buttons from './buttons'
import dayjs from 'dayjs'

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const validate = (values) => {
  const errors = {}
  if (!values.name) {
    errors.name = 'Required'
  }
  if (!values.collection_id) {
    errors.collection_id = 'Required'
  }
  return errors
}

const useInitialValues = (project = {}) => {
  const { state } = useLocation()
  const {
    name = '',
    description = '',
    ended_at = null,
    started_at = dayjs(),
    expires_at = dayjs().add(1, 'day'),
    teams_url = '',
    person_role = [],
    object_type_name = '',
    object_type_id = 0,
    collection_name = state?.contact?.name || '',
    collection_id = state?.contact?.id || 0,
    tags = []
  } = project
  return {
    name,
    description,
    ended_at,
    started_at: dayjs(started_at).format('YYYY-MM-DD'),
    expires_at: dayjs(expires_at).format('YYYY-MM-DD'),
    teams_url,
    person_role,
    object_type_id,
    object_type_name,
    collection_name,
    collection_id,
    tags
  }
}

export function useFormikValues (cb) {
  const onSubmit = useCallback(async (values) => {
    let payload = {
      ...values
    }
    if (values.expires_at === '' || values.expires_at === 'Invalid Date') {
      payload = { ...payload, expires_at: null }
    }

    if (values.started_at === '' || values.started_at === 'Invalid Date') {
      payload = { ...payload, started_at: null }
    }

    cb(payload)
  }, [cb])
  return onSubmit
}

const New = () => {
  const navigate = useNavigate()

  const locale = useSelector(selectLocale)
  const selectByPermissionProject = useMemo(makeSelectorPermissionProject, [])
  const permissions = useSelector(selectByPermissionProject)

  const { metadata, mutation, isLoading, isSuccessful } = useAddProjectMutation()

  const formData = useInitialValues()
  const onSubmitFormik = useFormikValues(mutation)
  usePermissionRoute(permissions, ['canCreate'])

  useEffect(() => {
    if (!isLoading && isSuccessful) {
      navigate(`/projects/edit?id=${metadata?.id}`, { replace: true })
    }
  }, [metadata, isSuccessful])

  if (isLoading) {
    return (
      <Layout.Main>
        <Layout.Aside>
          <Center>
            <Waves width={40} height={40} />
          </Center>
        </Layout.Aside>
      </Layout.Main>
    )
  }

  return (
    <Formik initialValues={formData} onSubmit={onSubmitFormik} validate={validate}>
      {(props) => {
        const { inputError, isSubmitting, dirty } = props
        const submit = (!inputError && !isSubmitting && dirty && !isLoading)
        const submitAsyncLoader = <AsyncLoader loading={isSubmitting || isLoading}><Save style={{ color: '#34b3e1', width: 20, height: 20 }} /></AsyncLoader>
        return (
          <TopbarNavigation title={locale.project} icon={<Company width={20} height={20} />}>
            <ProjectForm disable={false} {...props}>
              <Buttons {...props}>
                <Button disabled={!submit} type='submit' form='submitProject'><span>{locale.save}</span>{submitAsyncLoader}</Button>
              </Buttons>
            </ProjectForm>
          </TopbarNavigation>
        )
      }}
    </Formik>
  )
}

const View = () => {
  const navigate = useNavigate()
  const query = useUrlQuery()
  const id = query.get('id')

  const selectByPermissionProject = useMemo(makeSelectorPermissionProject, [])
  const permissions = useSelector(selectByPermissionProject)

  const locale = useSelector(selectLocale)
  const { data, refetch, isLoading } = useGetProjectByIdQuery(id)

  const { mutation: updateMutation, isLoading: isMutating, isSuccessful: isSuccessfulUpdate } = useUpdateProjectMutation()

  const formData = useInitialValues(data)
  usePermissionRoute(permissions, ['canUpdate'])

  const onSubmitFormik = useFormikValues(updateMutation)

  useEffect(() => {
    if (isSuccessfulUpdate) {
      refetch()
    }
  }, [isSuccessfulUpdate, refetch])

  if (isLoading) {
    return (
      <Layout.Main>
        <Layout.Aside>
          <Center>
            <Waves width={40} height={40} />
          </Center>
        </Layout.Aside>
      </Layout.Main>
    )
  }

  const createLink = permissions.canCreate && <Link onClickHandler={() => { navigate('/projects/new') }}><Add width={20} height={20} color='#28afe0' /></Link>
  return (
    <Formik initialValues={formData} onSubmit={(values) => onSubmitFormik({ id, ...values })} validate={validate}>
      {(props) => {
        const { inputError, isSubmitting, dirty } = props
        const disableSubmit = inputError || isSubmitting || !dirty || isMutating
        const submitAsyncLoader = <AsyncLoader loading={isSubmitting || isMutating}><Save style={{ color: '#34b3e1', width: 20, height: 20 }} /></AsyncLoader>
        return (
          <TopbarNavigation title={locale.project} icon={<CheckBook width={20} height={20} />} link={createLink}>
            <ProjectForm disable={false} {...props} original={data}>
              <Buttons {...props}>
                <Button disabled={disableSubmit} type='submit' form='submitProject'><span>{locale.save}</span>{submitAsyncLoader}</Button>
              </Buttons>
            </ProjectForm>
          </TopbarNavigation>
        )
      }}
    </Formik>
  )
}

export default { View, New }
