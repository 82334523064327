import { busy, notBusy } from '../busy/actions.js'
import {
  handleAddErrors,
  handleGetErrors,
  handleUpdateErrors,
  handleDeleteErrors
} from '../error/actions.js'
import { getAuthTokens } from '../common.js'
import storage from '../storage.js'
import { resetFileSearch } from '../files/actions.js'

export const PROJECT_CREATE_ACTION = 'PROJECT_CREATE_ACTION'
export const PROJECT_SHOW_ADD_DIALOG_ACTION = 'PROJECT_SHOW_ADD_DIALOG_ACTION'
export const PROJECT_HIDE_ADD_DIALOG_ACTION = 'PROJECT_HIDE_ADD_DIALOG_ACTION'
export const PROJECT_GET_OK_ACTION = 'PROJECT_GET_OK_ACTION'
export const PROJECT_UPDATE_ACTION = 'PROJECT_UPDATE_ACTION'
export const PROJECT_SHOW_EDIT_DIALOG_ACTION = 'PROJECT_SHOW_EDIT_DIALOG_ACTION'
export const PROJECT_HIDE_EDIT_DIALOG_ACTION = 'PROJECT_HIDE_EDIT_DIALOG_ACTION'
export const PROJECT_DELETE_ACTION = 'PROJECT_DELETE_ACTION'
export const PROJECT_SHOW_DELETE_DIALOG_ACTION = 'PROJECT_SHOW_DELETE_DIALOG_ACTION'
export const PROJECT_HIDE_DELETE_DIALOG_ACTION = 'PROJECT_HIDE_DELETE_DIALOG_ACTION'

export const PROJECT_GET_PAGE_ITEMS_ACTION = 'PROJECT_GET_PAGE_ITEMS_ACTION'
export const PROJECT_SET_ORDER_ACTION = 'PROJECT_SET_ORDER_ACTION'
export const PROJECT_SET_LIMIT_ACTION = 'PROJECT_SET_LIMIT_ACTION'
export const PROJECT_SET_OFFSET_ACTION = 'PROJECT_SET_OFFSET_ACTION'
export const PROJECT_SET_SEARCH_ACTION = 'PROJECT_SET_SEARCH_ACTION'
export const PROJECT_RESET_SEARCH_ACTION = 'PROJECT_RESET_SEARCH_ACTION'
export const PROJECT_SET_FILTER_QUERIES_ACTION = 'PROJECT_SET_FILTER_QUERIES_ACTION'
export const PROJECT_SET_SELECTED_ITEM_ID = 'PROJECT_SET_SELECTED_ITEM_ID'

export function projectCreateAction (project) {
  return {
    type: PROJECT_CREATE_ACTION,
    project
  }
}

export function projectUpdateAction (project) {
  return {
    type: PROJECT_UPDATE_ACTION,
    project
  }
}

export function projectDeleteAction (project) {
  return {
    type: PROJECT_DELETE_ACTION,
    project
  }
}

export function project (api, store) {
  function setSelectedItemId (selectedItemId) {
    return function (dispatch) {
      dispatch({
        type: PROJECT_SET_SELECTED_ITEM_ID,
        selectedItemId
      })
      dispatch(resetFileSearch('project'))
    }
  }

  /**
   * Add dialog
   */
  function showAddProjectDialog () {
    return {
      type: PROJECT_SHOW_ADD_DIALOG_ACTION
    }
  }

  function hideAddProjectDialog () {
    return {
      type: PROJECT_HIDE_ADD_DIALOG_ACTION
    }
  }

  /**
   * Edit dialog
   */
  function showEditProjectDialog (project) {
    return {
      type: PROJECT_SHOW_EDIT_DIALOG_ACTION,
      project
    }
  }

  function hideEditProjectDialog () {
    return {
      type: PROJECT_HIDE_EDIT_DIALOG_ACTION
    }
  }

  /**
   * Delete dialog
   */
  function showDeleteProjectDialog (project) {
    return {
      type: PROJECT_SHOW_DELETE_DIALOG_ACTION,
      project
    }
  }

  function hideDeleteProjectDialog () {
    return {
      type: PROJECT_HIDE_DELETE_DIALOG_ACTION
    }
  }

  /**
   * CREATE
   */
  function addProject (project) {
    return function (dispatch) {
      dispatch(busy())
      api.addProject(project, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleAddErrors('project', dispatch))
    }
  }

  /**
   * READ
   */
  function getProjects () {
    return function (dispatch) {
      dispatch(busy())
      api.getProjects(getAuthTokens(store)).then(result => {
        dispatch(notBusy())
        dispatch(receivedProjects(result.data.value))
      }).catch(handleGetErrors('project', dispatch))
    }
  }

  function receivedProjects (items) {
    return {
      type: PROJECT_GET_OK_ACTION,
      items
    }
  }

  /**
   * UPDATE
   */
  function updateProject (project) {
    return function (dispatch) {
      dispatch(busy())
      api.updateProject(project, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleUpdateErrors('project', dispatch))
    }
  }

  /**
   * DELETE
   */
  function deleteProject (project) {
    return function (dispatch) {
      dispatch(busy())
      api.deleteProject(project, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
        dispatch(getPageItems())
      }).catch(handleDeleteErrors('project', dispatch))
    }
  }

  function toggleFavorite (project) {
    return function (dispatch) {
      const favorite = !project.is_favorite
      dispatch(busy())
      api.setProjectFavorite(project.id, favorite, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleUpdateErrors('project', dispatch))
    }
  }

  /**
   * Get items for a page
   */
  function getPageItems () {
    const state = store.getState()
    const { search, filterQueries, limit, offset, orderBy, order } = state.project

    let query = []
    if (typeof search === 'string' && search.length > 0) {
      const split = search.split(',').map(s => s.trim())
      if (split.length === 1) {
        if (split[0].length > 0) query.push({ key: 'name', value: split[0], op: '~' })
      } else if (split.length === 2) {
        if (split[0].length > 0) query.push({ key: 'name', value: split[0], op: '~' })
        if (split[1].length > 0) query.push({ key: 'collection_name', value: split[1], op: '~' })
      }
    }

    if (Array.isArray(filterQueries) && filterQueries.length > 0) {
      query = [...query, ...filterQueries]
    }

    const sort = orderBy ? `${orderBy}.${order}` : null

    return function (dispatch) {
      dispatch(busy())
      api.getProjects({
        query,
        limit,
        offset,
        sort
      }, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
        dispatch({
          type: PROJECT_GET_PAGE_ITEMS_ACTION,
          pageItems: result.data.value
        })
      }).catch(handleGetErrors('project', dispatch))
    }
  }

  /**
   * Sort order
   */
  function setOrder (orderBy, order) {
    return {
      type: PROJECT_SET_ORDER_ACTION,
      orderBy,
      order
    }
  }

  /**
   * Set limit for pagination
   */
  function setLimit (limit) {
    return {
      type: PROJECT_SET_LIMIT_ACTION,
      limit
    }
  }

  /**
   * Set offset for pagination
   */
  function setOffset (offset) {
    return {
      type: PROJECT_SET_OFFSET_ACTION,
      offset
    }
  }

  /**
   * Set search
   */
  function setSearch (search) {
    return {
      type: PROJECT_SET_SEARCH_ACTION,
      search
    }
  }

  /**
   * Set filter queries
   */
  function setFilterQueries (filterQueries) {
    storage.putPageSetting('project', { filterQueries })
    return {
      type: PROJECT_SET_FILTER_QUERIES_ACTION,
      filterQueries
    }
  }

  /*
   * Reset search
   */
  function resetSearch () {
    return {
      type: PROJECT_RESET_SEARCH_ACTION
    }
  }

  return {
    setSelectedItemId,

    // Dialogs
    showAddProjectDialog,
    hideAddProjectDialog,
    showEditProjectDialog,
    hideEditProjectDialog,
    showDeleteProjectDialog,
    hideDeleteProjectDialog,
    // CRUD
    addProject,
    getProjects,
    updateProject,
    deleteProject,
    toggleFavorite,
    receivedProjects,

    getPageItems,
    setOrder,
    setLimit,
    setOffset,
    setSearch,
    resetSearch,
    setFilterQueries
  }
}
