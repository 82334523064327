import React, { useEffect, useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import PageToolbar from './PageToolbar.js'
import redux from '../../redux/index.js'
import SearchField from '../SearchField.js'
import DeleteItem from '../dialogs/DeleteItem.js'
import { TablePagination } from '@mui/material'
import {
  Table,
  Link,
  styled,
  Tag,
  TD as Td
} from '@pergas-common/pergas-components'
import {
  Add,
  Email,
  Teams,
  Phone,
  Remove,
  OpenExternal
} from '@pergas-common/pergas-icons'
import { EmployeeIcon } from '../icons.js'
import { sortArray } from '../../util.js'
import EditEmployee from '../dialogs/EditEmployee.js'
import { DEFAULT_OBJECT_TYPE_COLOR } from '../style.js'
import { createDateAccessor } from './columns.js'
import CsvButton from '../CsvButton.js'

const TableHolder = styled.div`
  display: flex;
  position: absolute;
  top: 100px;
  bottom: 0;
  overflow-y: scroll;
`

const NameHolder = styled.div`
  display: inline-block;
  margin: 0 8px;
`

const TagHolder = styled.span`
  margin-right: 8px;
`

const Spacer = styled.div`
  display: inline-block;
  margin: 2px;
`

const ROWS_PER_PAGE = [
  25,
  50,
  100,
  500,
  1000
]

const EmployeePage = ({
  locale,
  rows,
  limit,
  offset,
  orderBy,
  order,
  search,
  resetSearch,
  getPageItems,
  setOrder,
  setLimit,
  setOffset,
  setSearch,
  canUpdate,
  canDelete,
  deleteItem,
  onDeleteItemClick,
  onDeleteOk,
  onDeleteCancel,
  editItem,
  onEditItemClick,
  onEditOk,
  onEditCancel,
  canCreate,
  onShowAddDialog
}) => {
  useEffect(getPageItems, [limit, offset, orderBy, order, search])

  const manualSort = useCallback(({ id, isSorted, isSortedDesc }) => {
    if (isSorted && !isSortedDesc) {
      setOrder(id, 'desc')
    } else if (isSorted && isSortedDesc) {
      setOrder('', '')
    } else {
      setOrder(id, 'asc')
    }
  }, [setOrder])

  const transformedRows = useMemo(() => rows.map((r) => {
    const row = {
      [locale.id]: r.id,
      [locale.name]: r.name,
      [locale.office]: r.object_type_name ?? '',
      [locale.phone]: r.address?.phone ?? '',
      [locale.email]: r.address?.email ?? '',
      [locale.title]: r.tags?.find((t) => t)?.name ?? ''
    }

    return row
  }), [rows, locale])

  const columns = useMemo(() => {
    return [
      {
        Header: locale.name,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (
            <Td
              {...cell.getCellProps()} left={
                <>
                  <EmployeeIcon style={{ width: 16, height: 16 }} />
                  <NameHolder>
                    {!!original.name && <span>{original.name}</span>}
                  </NameHolder>
                </>
            }
            />
          )
        },
        canSort: true,
        id: 'name',
        size: 'md',
        manualSort,
        sortType: () => {}
      },
      {
        id: 'object_type_name',
        Header: locale.employee_object_type,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return <Td {...cell.getCellProps()} center={original.object_type_name && <Tag color={original.object_type_color || DEFAULT_OBJECT_TYPE_COLOR} textColor={original.object_type_color ? '#FFFFFF' : '#3a4a54'} border='#969696'>{original.object_type_name}</Tag>} />
        },
        canSort: true,
        sortType: () => {},
        manualSort
      },
      {
        id: 'phone_numbers',
        Header: locale.phone_number,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          const { address: { phone } } = original
          let primaryPhone = phone
          if (typeof primaryPhone === 'string' && primaryPhone.includes(',')) {
            // TODO: Sanitize this on the backend since free text introduces a lot of edge cases.
            primaryPhone = primaryPhone.split(',')[0].split(' ')
          }
          return <Td {...cell.getCellProps()} left={phone && <Link href={`tel:${primaryPhone}`}>{primaryPhone}</Link>} />
        },
        isSortable: false
      },
      {
        id: 'tags_string',
        Header: locale.tags,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          const tags = original.tags && original.tags.length > 0 && sortArray(original.tags, 'name').slice(0, 2).map(({ color, id, name }) => {
            return <TagHolder key={id}><Tag textColor='#FFFFFF' color={color}>{name}</Tag></TagHolder>
          })
          return (<Td {...cell.getCellProps()} left={tags && tags} />)
        },
        canSort: true,
        sortType: () => {},
        manualSort
      },
      {
        id: 'updated_at',
        Header: locale.updated_at,
        manualSort,
        accessor: createDateAccessor('updated_at'),
        sortType: () => {}
      },
      {
        id: 'toolbar',
        Header: locale.tool_belt,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          const { address } = original
          const { phone, email } = address
          return (
            <Td
              {...cell.getCellProps()}
              left={
                <>
                  {original.teams_url && <Spacer><Link href={original.teams_url}><Teams width={20} height={20} /></Link></Spacer>}
                  {phone && <Spacer><Link href={`tel:${phone}`}><Phone width={20} height={20} /></Link></Spacer>}
                  {!!email && <Spacer><Link href={`mailto:${email}`}><Email width={18} height={18} /></Link></Spacer>}
                  {!!email && <Spacer><Link href={`https://teams.microsoft.com/l/chat/0/0?users=${email}`}><Teams /></Link></Spacer>}
                  {canUpdate && <Spacer><Link onClickHandler={() => { onEditItemClick(original) }}><OpenExternal color='#28afe0' width={20} height={20} /></Link></Spacer>}
                </>
              }
              right={canDelete && <Link onClickHandler={() => { onDeleteItemClick(original) }}><Remove width={20} height={20} color='#FF5656' /></Link>}
            />
          )
        },
        size: 'sm',
        isSortable: false
      }
    ]
  }, [locale, canUpdate, canDelete, manualSort, onDeleteItemClick, onEditItemClick])

  let initialSortBy = []
  if (orderBy) {
    initialSortBy = [{
      id: orderBy,
      desc: order === 'desc'
    }]
  }

  return (
    <>
      {editItem && <EditEmployee isEditing item={editItem} onOk={onEditOk} onCancel={onEditCancel} />}
      {deleteItem && <DeleteItem text={deleteItem.name || deleteItem.first_name} onOk={() => { onDeleteOk(deleteItem) }} onCancel={onDeleteCancel} />}
      <PageToolbar
        resetSearch={resetSearch} left={
          <>
            <EmployeeIcon style={{ width: 20, height: 20 }} /><span>{locale.employees}</span>
            {canCreate && <Link onClickHandler={onShowAddDialog}><Add width={20} height={20} color='#28afe0' /></Link>}
            <CsvButton download={locale.employees} keys={[locale.id, locale.name, locale.office, locale.phone, locale.email, locale.title]} data={transformedRows} />
          </>
}
      >
        <SearchField value={search} onChange={setSearch} />
      </PageToolbar>
      <TableHolder>
        <Table columns={columns} data={rows} initialPageSize={limit} initialSortBy={initialSortBy}>
          {({ setPageSize }) => (
            <TablePagination
              rowsPerPage={limit}
              rowsPerPageOptions={ROWS_PER_PAGE}
              count={-1}
              page={offset / limit}
              labelRowsPerPage={locale.rows_per_page}
              labelDisplayedRows={({ from, to }) => `${from}-${to}`}
              onRowsPerPageChange={(e) => {
                setLimit(e.target.value)
                setPageSize(e.target.value)
              }}
              onPageChange={(e, number) => setOffset(number * limit)}
            />
          )}
        </Table>
      </TableHolder>
    </>
  )
}

const mapStateToProps = (state) => {
  const { locale, employee } = state
  const permissions = state.login.permissions
  const {
    pageItems,
    limit,
    offset,
    orderBy,
    order,
    search
  } = employee
  return {
    locale: locale.strings,
    rows: pageItems,
    limit,
    offset,
    orderBy,
    order,
    search,
    canUpdate: permissions.employee.canUpdate,
    canDelete: permissions.employee.canDelete,
    canCreate: permissions.employee.canCreate,
    deleteItem: state.employee.deleteItem,
    editItem: state.employee.editItem
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions: { employee } } = redux
  return {
    onShowAddDialog: () => {
      dispatch(employee.showAddEmployeeDialog())
    },

    onEditOk: (e) => {
      dispatch(employee.hideEditEmployeeDialog())
      dispatch(employee.updateEmployee(e))
    },
    onEditItemClick: (e) => {
      dispatch(employee.showEditEmployeeDialog(e))
    },
    onEditCancel: () => {
      dispatch(employee.hideEditEmployeeDialog())
    },

    onDeleteOk: (e) => {
      dispatch(employee.hideDeleteEmployeeDialog())
      dispatch(employee.deleteEmployee(e))
    },
    onDeleteItemClick: (e) => {
      dispatch(employee.showDeleteEmployeeDialog(e))
    },
    onDeleteCancel: () => {
      dispatch(employee.hideDeleteEmployeeDialog())
    },

    getPageItems: () => dispatch(employee.getPageItems()),
    setOrder: (orderBy, order) => dispatch(employee.setOrder(orderBy, order)),
    setLimit: (limit) => dispatch(employee.setLimit(limit)),
    setOffset: (offset) => dispatch(employee.setOffset(offset)),
    setSearch: (search) => dispatch(employee.setSearch(search)),
    resetSearch: () => dispatch(employee.resetSearch())

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeePage)
