import Permissions from '@pergas-pds/pds-permissions'
import { ERROR_ACTION } from '../error/actions.js'
import { UnknownAvatarBase64 } from '../../components/icons.js'
import storage from '../storage.js'
import {
  LOGIN_SUCCESS_ACTION,
  LOGIN_IS_LOGGING_IN_ACTION,
  LOGIN_HEARTBEAT_ACTION,
  LOGIN_GOT_USER_AVATAR_ACTION,
  ERROR_TYPE_LOGIN,
  LOGOUT_ACTION
} from './actions.js'

function calculatePermissions (userData) {
  return Permissions(userData ? userData.permissions : '')
}

const userData = storage.getUserData()
const { idToken, token } = userData
if (idToken && token) {
  console.info('Cached login!')
} else {
  console.info('No cached tokens found')
}

console.info('email:', userData.email)
console.info('name:', userData.name)
console.info('permissions:', userData.permissions)
console.info('tenant:', userData.tenant_name)
console.info('sharepoint save enabled:', userData.sharePointSaveEnabled)
console.info('sc root:', userData.sc_root)
console.info('id token:', userData.idToken)
console.info('api token:', userData.token)
console.info('access token:', userData.accessToken)
console.info('graph access token:', userData.graphAccessToken)

const userAvatar = storage.getUserAvatar()
if (userAvatar) {
  console.info('found cached user avatar')
} else {
  console.info('no cached user avatar, using default for now')
}

const LOGIN_DEFAULT = {
  userData,
  permissions: calculatePermissions(userData),
  avatar: userAvatar || UnknownAvatarBase64,
  isLoggingIn: false,
  timeLeft: -1
}

/**
 * Reducer for handling login/logout.
 */
export function login (state = LOGIN_DEFAULT, action) { // eslint-disable-line
  switch (action.type) {
    case LOGIN_SUCCESS_ACTION: {
      const { userData } = action
      return Object.assign({}, state, {
        userData,
        permissions: calculatePermissions(userData),
        isLoggingIn: false
      })
    }
    case LOGIN_IS_LOGGING_IN_ACTION:
      return Object.assign({}, state, { isLoggingIn: true })
    case LOGIN_HEARTBEAT_ACTION: {
      const { timeLeft } = action
      return Object.assign({}, state, { timeLeft })
    }
    case LOGIN_GOT_USER_AVATAR_ACTION: {
      const { avatar } = action
      return Object.assign({}, state, { avatar })
    }
    case LOGOUT_ACTION:
      return Object.assign({}, state, {
        permissions: calculatePermissions(),
        avatar: UnknownAvatarBase64
      })
    case ERROR_ACTION:
      if (action.errorType === ERROR_TYPE_LOGIN) {
        return Object.assign({}, state, {
          permissions: calculatePermissions(),
          isLoggingIn: false
        })
      } else {
        return state
      }
    default:
      return state
  }
}
