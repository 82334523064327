import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  spacing,
  styled
} from '@pergas-common/pergas-components'
import { api } from 'domains'
import { selectLocale } from 'domains/locale/selectors'
import useRequest from '../../hooks/useRequest'
import useDebounce from '../../hooks/useDebounce.js'
import { Radio, Select } from '../Input'

const Holder = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  align-self: center;
  max-width: 320px;
  width: 100%;
`

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: center;
`

const InputHolder = styled.div`
  display: inline-flex;
  align-items: center;
  > small {
    margin-left: ${spacing(1)};
  }
`

const SelectRadioLabel = styled.label`
  display: inline-flex;
  align-items: flex-end;
  > div {
    height: 48px;
    margin-left: ${spacing(1)};
  }
`

const FilterDepartment = ({ radio = true, applyFilter, rules }) => {
  const [collection, setCollection] = useState(rules.find(({ key }) => key === 'collection_id') ?? { id: 0, name: '' })

  const locale = useSelector(selectLocale)

  const [request, result = [], status] = useRequest(api.getDepartments, 'department')
  const [debouncedRequest] = useDebounce(request, 300)

  const isSpecifiedCollectionApplied = rules.filter(({ key }) => key === 'collection_id').length > 0

  useEffect(() => {
    request({ query: [{ key: 'name', op: '=', value: '' }], sort: 'name.asc', limit: 50 })
  }, [request])

  return (
    <Holder>
      <FilterRow>
        <SelectRadioLabel>
          {radio && (
            <InputHolder>
              <Radio
                value={isSpecifiedCollectionApplied}
                readOnly label={locale.category} checked={isSpecifiedCollectionApplied} onChange={() => {
                  if (collection) {
                    applyFilter(collection.id, collection)
                  }
                }}
              />
            </InputHolder>
          )}
          <Select
            name='contact'
            label={locale.department}
            defaultValue={collection}
            cb={({ selectedItem, inputValue }) => {
              if (inputValue === '') {
                applyFilter(null)
                setCollection(null)
              }

              if (selectedItem && inputValue !== '') {
                applyFilter(selectedItem.id, selectedItem)
                setCollection(selectedItem)
              }
            }}
            request={(input) => {
              debouncedRequest({ query: [{ key: 'name', op: '~', value: input }], sort: 'name.asc', limit: 50 })
            }}
            requestStatus={status === 'pending'}
            items={[{ id: 0, name: '' }, ...result ?? []]}
            handleChange={(_, id, contact) => {}}
          />
        </SelectRadioLabel>
      </FilterRow>
    </Holder>
  )
}

export default FilterDepartment
