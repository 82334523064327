import { busy, notBusy } from '../busy/actions.js'
import {
  handleDeleteErrors,
  handleGetErrors
} from '../error/actions.js'
import { getAuthTokens } from '../common.js'

export const CONTENT_TYPE_GET_PAGE_ITEMS_ACTION = 'CONTENT_TYPE_GET_PAGE_ITEMS_ACTION'
export const CONTENT_TYPE_CLEAR_ITEMS_ACTION = 'CONTENT_TYPE_CLEAR_ITEMS_ACTION'
export const CONTENT_TYPE_SET_ORDER_ACTION = 'CONTENT_TYPE_SET_ORDER_ACTION'
export const CONTENT_TYPE_SET_LIMIT_ACTION = 'CONTENT_TYPE_SET_LIMIT_ACTION'
export const CONTENT_TYPE_SET_OFFSET_ACTION = 'CONTENT_TYPE_SET_OFFSET_ACTION'
export const CONTENT_TYPE_SET_SEARCH_ACTION = 'CONTENT_TYPE_SET_SEARCH_ACTION'
export const CONTENT_TYPE_RESET_SEARCH_ACTION = 'CONTENT_TYPE_RESET_SEARCH_ACTION'

export function contentType (api, store) {
  /**
   * READ
   */
  function getPageItems () {
    const state = store.getState()
    const { search, limit, offset, orderBy, order } = state.contentType
    const sort = `${orderBy}.${order}`

    const params = { limit, offset, sort }

    if (typeof search === 'string' && search.length > 0) {
      const split = search.split(',').map(s => s.trim()).filter(Boolean)
      if (split.length) {
        const query = []
        const value = split[0]
        query.push({ key: 'content_type_name', value, op: '~' })
        query.push({ key: 'field_internal_name', value, op: '~' })
        query.push({ key: 'field_type_as_string', value, op: '~' })
        query.push({ key: 'field_id', value, op: '~' })
        query.push({ key: 'field_title', value, op: '~' })
        params.query = query
        params.qor = true
      }
    }

    return function (dispatch) {
      dispatch(busy())
      api.getContentTypes(params, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
        dispatch({
          type: CONTENT_TYPE_GET_PAGE_ITEMS_ACTION,
          pageItems: result.data.value
        })
      }).catch(handleGetErrors('contentType', dispatch))
    }
  }

  /**
   * Reset content types.
   */
  function resetContentTypes () {
    return function (dispatch) {
      dispatch(busy())
      dispatch({ type: CONTENT_TYPE_CLEAR_ITEMS_ACTION })
      api.resetContentTypes(getAuthTokens(store)).then(result => {
        dispatch(notBusy())
        dispatch({
          type: CONTENT_TYPE_GET_PAGE_ITEMS_ACTION,
          pageItems: result.data.value
        })
      }).catch(handleDeleteErrors('contentType', dispatch))
    }
  }

  /**
   * Sort order
   */
  function setOrder (orderBy, order) {
    return {
      type: CONTENT_TYPE_SET_ORDER_ACTION,
      orderBy,
      order
    }
  }

  /**
   * Set limit for pagination
   */
  function setLimit (limit) {
    return {
      type: CONTENT_TYPE_SET_LIMIT_ACTION,
      limit
    }
  }

  /**
   * Set offset for pagination
   */
  function setOffset (offset) {
    return {
      type: CONTENT_TYPE_SET_OFFSET_ACTION,
      offset
    }
  }

  /**
   * Set search
   */
  function setSearch (search) {
    return {
      type: CONTENT_TYPE_SET_SEARCH_ACTION,
      search
    }
  }

  /**
   * Reset search
   */
  function resetSearch () {
    return {
      type: CONTENT_TYPE_RESET_SEARCH_ACTION
    }
  }

  return {
    getPageItems,
    resetContentTypes,
    setOrder,
    setLimit,
    setOffset,
    setSearch,
    resetSearch
  }
}
