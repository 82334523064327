import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link as RLink } from 'react-router-dom'
import { TablePagination } from '@mui/material'
import { Link, LinkStyle, Table, TD as Td, Tag, styled } from '@pergas-common/pergas-components'
import { CircularTick, Add, OpenExternal, Remove } from '@pergas-common/pergas-icons'
import { makeSelectorByEmployeeId } from 'domains/login/selectors'
import DeleteItem from '../dialogs/DeleteItem.js'
import PageToolbar from './PageToolbar.js'
import SearchField from '../SearchField.js'
import { Switch } from '../Input'
import redux from '../../redux/index.js'
import storage from '../../redux/storage.js'
import { sortArray, trimNewLineFromString } from '../../util.js'
import { isExpired } from './columns.js'
import dayjs from 'dayjs'
import { makeSelectorFilterQueries } from '../../redux/common.js'
import CsvButton from '../CsvButton.js'
import Filterbar from '../filter/Filterbar'
import FilterEmployee from '../filter/FilterEmployee'
import All from '../filter/FilterAll'
import FilterMine from '../filter/FilterMine'
import { selectLocale } from '../../redux/locale/selectors.js'
import FilterProject from '../filter/FilterProject.js'
import FilterNumber from '../filter/FilterNumber.js'
import { css } from 'styled-components'

const TableHolder = styled.div`
  display: flex;
  position: fixed;
  top: 100px;
  bottom: 0;
  overflow-y: scroll;
  ${({ $isExpanded }) => $isExpanded && css`
    top: 220px;  
  `};
`

const TagHolder = styled.span`
  margin-right: 8px;
`

const Spacer = styled.div`
  display: inline-block;
  margin: 2px;
`

const CircularTickHolder = styled(CircularTick)`
  vertical-align: bottom;
`

const NameHolder = styled.div`
  display: inline-block;
  margin: 0 8px;
`

const InternalLink = styled(RLink)`
  ${LinkStyle}
`

const Strikethrough = styled.span`
  text-decoration: ${({ isDone }) => isDone ? 'line-through' : 'none'};
`

const Expired = styled.span`
  color: ${({ date }) => isExpired(date)
? `
    #FF5656
  `
: 'inherit'};
`

const VALID_OPS_ESTIMATED = [
  { name: 'gt', op: '>' },
  { name: 'lt', op: '<' },
  { name: 'gteq', op: '>=' },
  { name: 'lteq', op: '<=' }
]

const FilterList = () => {
  const dispatch = useDispatch()
  const selectFilterQueries = useMemo(makeSelectorFilterQueries, [])
  const selectMyEmployeeId = useMemo(makeSelectorByEmployeeId, [])

  const filterQueries = useSelector((state) => selectFilterQueries(state, 'ticket'))
  const myEmployeeId = useSelector((state) => selectMyEmployeeId(state))
  const locale = useSelector(selectLocale)

  const filterByMine = filterQueries?.find(({ key, value }) => key === 'person_role.person_id' && value === myEmployeeId)
  const filterByAssignedTo = filterQueries?.find(({ key, value }) => key === 'person_role.person_id' && value !== myEmployeeId)
  const filterByAll = filterQueries.filter(({ key }) => key === 'person_role.person_id').length === 0
  const filterByProject = filterQueries?.find(({ key }) => key === 'project_id')
  const filterByContactResponsible = filterQueries?.find(({ key }) => key === 'collection_person_role.person_id')
  const filterByProjectResponsible = filterQueries?.find(({ key }) => key === 'project_person_role.person_id')
  const isMine = filterByMine && !filterByAll && !filterByAssignedTo
  const isAll = filterByAll && !isMine && !filterByAssignedTo
  const isOther = filterByAssignedTo && !isMine && !isAll

  const assignedToFilter = useCallback((id, metadata = {}) => {
    const included = filterQueries.filter(({ key }) => key !== 'person_role.person_id' && key !== 'person_role.role_id')
    if (!id) {
      dispatch(redux.actions.ticket.setFilterQueries(included))
    } else {
      dispatch(redux.actions.ticket.setFilterQueries([...included, { key: 'person_role.person_id', value: id, op: '=', ...metadata }, { key: 'person_role.role_id', value: 1, op: '=' }]))
    }
  }, [dispatch, filterQueries])

  const projectFilter = useCallback((id, metadata = {}) => {
    const included = filterQueries.filter(({ key }) => key !== 'project_id')
    if (!id) {
      dispatch(redux.actions.ticket.setFilterQueries(included))
    } else {
      dispatch(redux.actions.ticket.setFilterQueries([...included, { key: 'project_id', value: id, op: '=', ...metadata }]))
    }
  }, [dispatch, filterQueries])

  const projectResponsibleFilter = useCallback((id, metadata = {}) => {
    const included = filterQueries.filter(({ key }) => key !== 'project_person_role.person_id' && key !== 'project_person_role.role_id')
    if (!id) {
      dispatch(redux.actions.ticket.setFilterQueries(included))
    } else {
      dispatch(redux.actions.ticket.setFilterQueries([...included, { key: 'project_person_role.person_id', value: id, op: '=', ...metadata }, { key: 'project_person_role.role_id', value: 1, op: '=' }]))
    }
  }, [dispatch, filterQueries])

  const contactResponsibleFilter = useCallback((id, metadata = {}) => {
    const included = filterQueries.filter(({ key }) => key !== 'collection_person_role.person_id' && key !== 'collection_person_role.role_id')
    if (!id) {
      dispatch(redux.actions.ticket.setFilterQueries(included))
    } else {
      dispatch(redux.actions.ticket.setFilterQueries([...included, { key: 'collection_person_role.person_id', value: id, op: '=', ...metadata }, { key: 'collection_person_role.role_id', value: 1, op: '=' }]))
    }
  }, [dispatch, filterQueries])

  const estimatedFilter = useCallback(({ input, op }) => {
    const validOp = VALID_OPS_ESTIMATED.find((v) => v.op === op)
    const included = filterQueries.filter(({ key }) => key !== 'estimate_value')
    if (!validOp) {
      dispatch(redux.actions.ticket.setFilterQueries(included))
      return
    }
    if (!input) {
      dispatch(redux.actions.ticket.setFilterQueries(included))
    } else {
      dispatch(redux.actions.ticket.setFilterQueries([...included, { key: 'estimate_value', value: input, op: validOp.op }]))
    }
  }, [dispatch, filterQueries])

  return (
    <Filterbar.Content>
      <Filterbar.Grouped border>
        <legend>{locale.assigned}</legend>
        <Filterbar.Column offset={1}>
          <All
            applyFilter={assignedToFilter} checked={isAll}
          />
          <FilterMine
            applyFilter={assignedToFilter} checked={isMine}
          />
        </Filterbar.Column>
        <Filterbar.Row offset={-0}>
          <FilterEmployee
            checked={isOther} defaultValue={filterByAssignedTo} applyFilter={assignedToFilter}
          />
        </Filterbar.Row>
      </Filterbar.Grouped>
      <Filterbar.Grouped>
        <Filterbar.Row offset={-1}>
          <FilterProject
            defaultValue={filterByProject}
            applyFilter={projectFilter}
          />
          <FilterEmployee label={locale.project_responsible} radio={false} defaultValue={filterByProjectResponsible} applyFilter={projectResponsibleFilter} />
        </Filterbar.Row>
      </Filterbar.Grouped>
      <Filterbar.Grouped>
        <Filterbar.Row offset={-1}>
          <FilterEmployee label={locale.contact_responsible} radio={false} defaultValue={filterByContactResponsible} applyFilter={contactResponsibleFilter} />
        </Filterbar.Row>
      </Filterbar.Grouped>
      <Filterbar.Grouped>
        <Filterbar.Row offset={1}>
          <FilterNumber options={['>', '<', '>=', '<=']} applyFilter={estimatedFilter} />
        </Filterbar.Row>
      </Filterbar.Grouped>
    </Filterbar.Content>
  )
}

const ROWS_PER_PAGE = [
  25,
  50,
  100,
  500,
  1000
]

const mapStateToProps = (state) => {
  const { ticket } = state
  const {
    items,
    limit,
    offset,
    orderBy,
    order,
    search
  } = ticket
  return {
    limit,
    offset,
    orderBy,
    order,
    search,
    locale: state.locale.strings,
    permissions: state.login.permissions,
    tickets: items,
    editItem: state.ticket.editItem,
    deleteItem: state.ticket.deleteItem,
    strings: state.locale.strings
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions: { ticket } } = redux
  return {
    onShowAddDialog: () => {
      dispatch(ticket.showAddTicketDialog())
    },
    onDeleteOk: (p) => {
      dispatch(ticket.hideDeleteTicketDialog())
      dispatch(ticket.deleteTicket(p))
    },
    onDeleteItemClick: (p) => {
      dispatch(ticket.showDeleteTicketDialog(p))
    },
    onDeleteCancel: () => {
      dispatch(ticket.hideDeleteTicketDialog())
    },
    getTickets: () => {
      dispatch(ticket.getTickets())
    },
    setOrder: (orderBy, order) => dispatch(ticket.setOrder(orderBy, order)),
    setLimit: (limit) => dispatch(ticket.setLimit(limit)),
    setOffset: (offset) => dispatch(ticket.setOffset(offset)),
    setSearch: (search) => dispatch(ticket.setSearch(search)),
    resetSearch: () => dispatch(ticket.resetSearch())
  }
}

function Tickets (props) {
  const {
    deleteItem,
    getTickets, limit,
    locale,
    offset,
    resetSearch,
    onDeleteCancel,
    onDeleteItemClick,
    onDeleteOk,
    orderBy,
    order,
    permissions,
    setSearch,
    tickets,
    setOrder,
    setLimit,
    setOffset,
    search
  } = props
  const dispatch = useDispatch()

  const settings = storage.getPageSettings('ticket')
  const [expanded, setExpanded] = useState(settings.toolbarExpanded || false)

  function onSetExpanded (set) {
    storage.putPageSetting('ticket', { toolbarExpanded: !expanded })
    setExpanded(set)
  }

  const selectFilterQueries = useMemo(makeSelectorFilterQueries, [])
  const filterQueries = useSelector((state) => selectFilterQueries(state, 'ticket'))

  useEffect(() => {
    getTickets()
  }, [limit, offset, orderBy, order, search, filterQueries])
  const navigate = useNavigate()
  const manualSort = useCallback(({ id, isSorted, isSortedDesc }) => {
    if (isSorted && !isSortedDesc) {
      setOrder(id, 'desc')
    } else if (isSorted && isSortedDesc) {
      setOrder('', '')
    } else {
      setOrder(id, 'asc')
    }
  }, [setOrder])
  const onlyOpenTickets = filterQueries.find(({ key, value }) => key === 'ended_at' && value === null)

  const toggleClosedProjectsFilter = useCallback(() => {
    const rest = filterQueries.filter(({ key, value }) => key !== 'ended_at' && value !== null)
    const isOnlySHowingOpen = filterQueries.find(({ key, value }) => key === 'ended_at' && value === null)
    if (isOnlySHowingOpen) {
      dispatch(redux.actions.ticket.setFilterQueries([...rest]))
    } else {
      dispatch(redux.actions.ticket.setFilterQueries([...rest, { key: 'ended_at', value: null, op: '=' }]))
    }
  }, [dispatch, filterQueries])

  let initialSortBy = []
  if (orderBy) {
    initialSortBy = [{
      id: orderBy,
      desc: order === 'desc'
    }]
  }
  const transformedRows = useMemo(() => tickets.map((r) => {
    const getResponsibleName = (l) => l?.find((pr) => pr.role_internal_name === 'responsible')?.person_name ?? ''
    const row = {
      [locale.id]: r.id,
      [locale.description]: trimNewLineFromString(r.description),
      [locale.name]: r.name ?? '',
      [locale.project]: r.project_name ?? '',
      [`${locale.project}_${locale.id}`]: r.project_id,
      [locale.project_responsible]: getResponsibleName(r.project_person_role),
      // we shouldn't be matching on id but we do not expose the internal name here right now.
      [locale.assigned]: r.person_role?.find((pr) => pr.role_id === 1)?.person_name ?? ''
    }

    if (r.started_at) {
      row[locale.started_at] = dayjs(r.started_at).format('YYYY-MM-DD')
    } else {
      row[locale.started_at] = ''
    }

    if (r.expires_at) {
      row[locale.expires_at] = dayjs(r.expires_at).format('YYYY-MM-DD')
    } else {
      row[locale.expires_at] = ''
    }

    if (r.ended_at) {
      row[locale.finished] = dayjs(r.ended_at).format('YYYY-MM-DD')
    } else {
      row[locale.finished] = ''
    }

    return row
  }), [tickets, locale])

  const { ticket: { canUpdate, canDelete, canCreate } } = permissions
  const columns = useMemo(() => {
    return [
      {
        Header: locale.name,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (
            <Td
              {...cell.getCellProps()} left={
                <>
                  <CircularTickHolder width={16} height={16} />
                  <NameHolder>
                    {!!original.name && <InternalLink to={`edit?id=${original.id}`}><Strikethrough isDone={original?.ended_at}>{original.name}</Strikethrough></InternalLink>}
                  </NameHolder>
                </>
              }
            />
          )
        },
        canSort: true,
        id: 'name',
        size: 'md',
        manualSort,
        sortType: () => {}
      },
      {
        id: 'person_name',
        Header: locale.assigned,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          const person = original.person_role && original.person_role.length > 0 && original.person_role[0]
          return (<Td {...cell.getCellProps()} left={person && person.person_name} />)
        },
        isSortable: false
      },
      {
        Header: locale.project,
        accessor: 'project_name',
        manualSort,
        sortType: () => {}
      },
      {
        id: 'tags_string',
        Header: locale.tags,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          const tags = original.tags && original.tags.length > 0 && sortArray(original.tags, 'name').slice(0, 2).map(({ color, id, name }) => {
            return <TagHolder key={id}><Tag textColor='#FFFFFF' color={color}>{name}</Tag></TagHolder>
          })
          return (<Td {...cell.getCellProps()} left={tags && tags} />)
        },
        canSort: true,
        sortType: () => {},
        manualSort
      },
      {
        id: 'expires_at',
        Header: locale.expires_at,
        manualSort,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          const formattedDate = original.expires_at ? dayjs(original.expires_at).format('YYYY-MM-DD') : ''
          return (<Td {...cell.getCellProps()} left={<Expired date={formattedDate}>{formattedDate}</Expired>} />)
        },
        canSort: true,
        sortType: () => {}
      },
      {
        id: 'estimate_value',
        Header: locale.estimate_value,
        accessor: 'estimate_value',
        manualSort,
        sortType: () => {}
      },
      {
        id: 'toolbar',
        Header: locale.tool_belt,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (
            <Td
              {...cell.getCellProps()}
              left={canUpdate && <Spacer><Link onClickHandler={() => { navigate(`edit?id=${original.id}`) }}><OpenExternal color='#28afe0' width={20} height={20} /></Link></Spacer>}
              right={canDelete && <Link onClickHandler={() => { onDeleteItemClick(original) }}><Remove width={20} height={20} color='#FF5656' /></Link>}
            />
          )
        },
        size: 'sm',
        isSortable: false
      }
    ]
  }, [locale, canUpdate, canDelete, manualSort])
  return (
    <>
      {deleteItem && <DeleteItem text={deleteItem.name || deleteItem.first_name} onOk={() => { onDeleteOk(deleteItem) }} onCancel={onDeleteCancel} />}
      <PageToolbar
        expanded={expanded}
        setExpanded={onSetExpanded}
        left={
        (
          <>
            <CircularTick width={20} height={20} /> <span>{locale.tasks}</span>
            {canCreate && <Link onClickHandler={() => navigate('/tickets/new')}><Add width={20} height={20} color='#28afe0' /></Link>}
            <CsvButton
              download={locale.tasks} keys={[
                locale.id,
                locale.name,
                locale.description,
                locale.project,
                `${locale.project}_${locale.id}`,
                locale.project_responsible,
                locale.assigned,
                locale.started_at,
                locale.expires_at,
                locale.finished
              ]} data={transformedRows}
            />
            <Switch value={onlyOpenTickets} onChangeValue={toggleClosedProjectsFilter} disabled={false} />
          </>
        )
      } center={<SearchField resetSearch={resetSearch} value={search} onChange={setSearch} />}
      >
        <FilterList />
      </PageToolbar>
      <TableHolder $isExpanded={expanded}>
        <Table columns={columns} data={tickets} initialPageSize={limit} initialSortBy={initialSortBy}>
          {({ setPageSize }) => (
            <TablePagination
              rowsPerPage={limit}
              rowsPerPageOptions={ROWS_PER_PAGE}
              count={-1}
              page={offset / limit}
              labelRowsPerPage={locale.rows_per_page}
              labelDisplayedRows={({
                from,
                to
              }) => `${from}-${to}`}
              onRowsPerPageChange={(e) => {
                setLimit(e.target.value)
                setPageSize(e.target.value)
              }}
              onPageChange={(e,
                number) => setOffset(number * limit)}
            />
          )}
        </Table>
      </TableHolder>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Tickets)
