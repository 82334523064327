import { ERROR_ACTION } from '../error/actions.js'

const ERROR_TYPE_DOC_LIB_GET = 'ERROR_TYPE_DOC_LIB_GET'
const ERROR_TYPE_DOC_LIB_NO_ACCESS = 'ERROR_TYPE_DOC_LIB_NO_ACCESS'
const ERROR_TYPE_FILE_SEARCH = 'ERROR_TYPE_FILE_SEARCH'
const ERROR_TYPE_FOLDER_CREATED_ADD = 'ERROR_TYPE_FOLDER_CREATED_ADD'

export const FILES_GET_ROOT_FOLDER_ACTION = 'FILES_GET_ROOT_FOLDER_ACTION'
export const FILES_GET_SUB_FOLDER_ACTION = 'FILES_GET_SUB_FOLDER_ACTION'

export const FILES_BEGIN_SEARCH_ACTION = 'FILES_BEGIN_SEARCH_ACTION'
export const FILES_END_SEARCH_ACTION = 'FILES_END_SEARCH_ACTION'
export const FILES_RESET_SEARCH_ACTION = 'FILES_RESET_SEARCH_ACTION'

/**
 * Reset search
 */
export function resetFileSearch (itemType) {
  return {
    type: FILES_RESET_SEARCH_ACTION,
    itemType
  }
}

export default function file (store, msal) {
  /**
   * Get files and folders for a root folder item
   */
  function getRootFolder (siteItem) {
    return async function (dispatch) {
      const { siteUrl, path } = getItemMeta(siteItem)
      if (!siteUrl) {
        dispatch({
          type: FILES_GET_ROOT_FOLDER_ACTION,
          siteItem,
          children: []
        })
        return
      }
      try {
        dispatch({
          type: FILES_GET_ROOT_FOLDER_ACTION,
          siteItem,
          path,
          children: await getFilesHelper({ siteUrl, path })
        })
      } catch (err) {
        dispatch(documentLibraryError(err))
      }
    }
  }

  /**
   * Get files and folders from a sub folder
   */
  function getSubFolder (siteItem, folderItem) {
    return async function (dispatch) {
      const { siteUrl } = getItemMeta(siteItem)
      const { path, id } = folderItem
      try {
        dispatch({
          type: FILES_GET_SUB_FOLDER_ACTION,
          siteItem,
          parent: folderItem,
          children: await getFilesHelper({ siteUrl, path, id })
        })
      } catch (err) {
        dispatch(documentLibraryError(err))
      }
    }
  }

  /**
   * Helper function for getting files and folders
   */
  async function getFilesHelper ({ siteUrl, path, id }) {
    const { userData } = store.getState().login
    const spToken = await getSpAccessToken(userData)
    const result = await getDocumentLibraryFiles({
      siteUrl,
      path,
      id
    }, spToken)

    const folders = result.Folders.results.filter(folder => {
      return folder.Name !== 'Forms'
    }).map(folder => {
      return {
        type: 'folder',
        children: [],
        id: folder.UniqueId,
        nodeId: `folder:${folder.UniqueId}`,
        name: folder.Name,
        title: folder.Title,
        itemCount: folder.ItemCount,
        created: folder.TimeCreated,
        modified: folder.TimeLastModified,
        path: `${path}/${folder.Name}`
      }
    }).sort(compareFilenames)

    const files = result.Files.results.filter(file => {
      const length = parseInt(file.Length, 10)
      return !isNaN(length) && length > 0
    }).map(file => {
      return {
        type: 'file',
        id: file.UniqueId,
        nodeId: `file:${file.UniqueId}`,
        name: file.Name,
        title: file.Title,
        created: file.TimeCreated,
        modified: file.TimeLastModified,
        path,
        uri: absoluteUrl(userData.sc_root, file.ServerRelativeUrl),
        serverRelativeUrl: file.ServerRelativeUrl
      }
    }).sort(compareFilenames)

    return folders.concat(files)
  }

  /**
   * Search files in currently selected context.
   */
  function searchFiles (siteItem, query) {
    return async function (dispatch) {
      if (!query) {
        return dispatch(resetFileSearch(siteItem?.type))
      }

      const { userData } = store.getState().login
      const scRoot = userData.sc_root

      const { siteUrl } = getItemMeta(siteItem)
      const serverRelativeUrl = getServerRelativeUrl(siteItem)

      let allResults = []

      dispatch({
        type: FILES_BEGIN_SEARCH_ACTION,
        siteItem,
        query
      })

      /**
       * This function can be called multiple times if the search is going on
       */
      function onResults (results) {
        results = results.filter(file => {
          const length = parseInt(file.Length, 10)
          return !isNaN(length) && length > 0
        }).map(file => {
          return {
            type: 'file',
            id: file.UniqueId,
            name: file.Name,
            title: file.Title,
            created: file.TimeCreated,
            modified: file.TimeLastModified,
            uri: absoluteUrl(scRoot, file.ServerRelativeUrl),
            serverRelativeUrl: file.ServerRelativeUrl
          }
        })
        allResults = allResults.concat(results)
      }

      /**
       * Called when the search has ended and there are no more results
       */
      function onEnd () {
        dispatch({
          type: FILES_END_SEARCH_ACTION,
          siteItem,
          results: allResults
        })
      }

      try {
        const spToken = await getSpAccessToken(userData)
        await searchDocumentLibrary({
          siteUrl,
          serverRelativeUrl,
          query,
          onResults,
          onEnd
        }, spToken)
      } catch (err) {
        return dispatch({
          type: ERROR_ACTION,
          errorType: ERROR_TYPE_FILE_SEARCH,
          errorMessage: err.message
        })
      }
    }
  }

  function createFolder (siteItem, item, name) {
    function getSiteItemUrl () {
      switch (siteItem.type) {
        case 'contact':
        case 'project':
        case 'department':
          return `${(new URL(siteItem.url)).pathname}`
        case 'category':
          return `${(new URL(siteItem.collection_url)).pathname}`
      }
      return ''
    }

    return async function (dispatch) {
      try {
        const { userData } = store.getState().login
        const spToken = await getSpAccessToken(userData)
        const { siteUrl } = getItemMeta(siteItem)
        const relativeUrl = `${getSiteItemUrl()}/${item.path}`

        const options = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${spToken}`,
            'Content-Type': 'application/json;odata=verbose'
          },
          body: JSON.stringify({
            __metadata: {
              type: 'SP.Folder'
            },
            ServerRelativeUrl: `${relativeUrl}/${name}`
          })
        }

        const response = await fetch(`${siteUrl}/_api/web/folders`, options)
        if (!response.ok) {
          throw new Error(ERROR_TYPE_FOLDER_CREATED_ADD)
        }

        if (item.id === 'root') {
          dispatch(getRootFolder(siteItem))
        } else {
          dispatch(getSubFolder(siteItem, item))
        }
      } catch (e) {
        dispatch({
          type: ERROR_ACTION,
          errorType: ERROR_TYPE_FOLDER_CREATED_ADD
        })
      }
    }
  }

  /**
   * Fetch an access token for SharePoint
   */
  function getSpAccessToken (userData) {
    const url = new URL(userData.sc_root)
    const scopes = [`${url.protocol}//${url.host}//.default`]
    return getAccessToken(scopes, userData.email)
  }

  /**
   * Common access token function.
   */
  async function getAccessToken (scopes, email) {
    const account = msal.getAccountByUsername(email)
    const request = { scopes, account }
    try {
      const result = await msal.acquireTokenSilent(request)
      return result.accessToken
    } catch (err) {
      console.error('Failed to get access token', err)
      console.info('Trying acquireTokenRedirect')
      await msal.acquireTokenRedirect(request)
    }
  }

  /**
   * Returns server relative url to a document folder for a particular item
   */
  function getServerRelativeUrl (siteItem) {
    switch (siteItem.type) {
      case 'contact':
      case 'project':
      case 'department':
        return `${(new URL(siteItem.url)).pathname}/files`
      case 'category':
        return `${(new URL(siteItem.collection_url)).pathname}/${siteItem.id}`
    }
    return ''
  }

  /**
   * Get files and folders in a document library folder.
   *
   * siteUrl: url to site
   * path: path to file
   * id: unique folder id
   * spToken: sharepoint access token
   */
  async function getDocumentLibraryFiles ({ siteUrl, path, id }, spToken) {
    const url = id ? `${siteUrl}/_api/Web/GetFolderById('${id}')?$expand=Folders,Files` : `${siteUrl}/_api/Web/GetFolderByServerRelativeUrl('${path}')?$expand=Folders,Files`
    const res = await fetch(url, getRequestOptions(spToken))
    if (!res.ok) {
      const error = new Error('')
      if (res.status === 403) {
        // If we don't have access we don't need an extra message
        error.status = 403
      } else {
        // Some other error. Provide more details.
        error.message = `getDocumentLibraryFiles() failed (${res.status})`
      }
      throw error
    }
    return (await res.json()).d
  }

  /**
   * Search files in a document library.
   *
   * siteUrl: url to site
   * serverRelativeUrl: server relative url to list
   * query: search string
   * onResults: callback on results
   * onEnd: callback when finished
   * spToken: sharepoint access token
   */
  async function searchDocumentLibrary ({
    siteUrl,
    serverRelativeUrl,
    query,
    onResults,
    onEnd
  }, spToken) {
    const opts = getRequestOptions(spToken)

    const GET = async (url) => {
      const res = await fetch(url, opts)
      if (!res.ok) {
        throw new Error(`partial search failed (${res.status})`)
      }
      const data = await res.json()
      const { results } = data.d
      if (Array.isArray(results)) {
        onResults(results.map(f => f.File))
      }

      const nextUrl = data?.d?.__next
      if (typeof nextUrl === 'string') {
        await GET(nextUrl)
      } else {
        onEnd()
      }
    }

    const list = await getList(siteUrl, serverRelativeUrl, opts)
    const startUrl = `${siteUrl}/_api/Web/Lists/getById('${list.Id}')/items?$expand=File,FieldValuesAsText&$select=File,FieldValuesAsText&$filter=FSObjType eq '0' and substringof('${query}',FileLeafRef)`
    await GET(startUrl)
  }

  /**
   * Get a list by its relative url.
   *
   * siteUrl: url to site
   * serverRelativeUrl: server relative url to list
   * opts: auth options
   */
  async function getList (siteUrl, listRelativeUrl, opts) {
    const select = '$select=Title,RootFolder/ServerRelativeUrl'
    const expand = '$expand=RootFolder'
    const filter = `$filter=RootFolder/ServerRelativeUrl eq '${listRelativeUrl}'`
    const url = `${siteUrl}/_api/web/lists?${select}&${expand}&${filter}`

    const res = await fetch(url, opts)
    if (!res.ok) {
      throw new Error(`selecting list failed (${res.status})`)
    }

    const data = await res.json()
    if (Array.isArray(data.d?.results) && data.d.results.length > 0) {
      const listUri = data.d.results[0].__metadata.id
      const res = await fetch(listUri, opts)
      if (!res.ok) {
        throw new Error(`fetching list data failed (${res.status})`)
      }
      return (await res.json()).d
    } else {
      throw new Error(`no relative url for site '${siteUrl}'`)
    }
  }

  /**
   * Categories are handled differently than contacts and projects
   */
  function getItemMeta (item) {
    if (item.type === 'category') {
      return { siteUrl: item.collection_url, path: `${item.id}` }
    } else {
      return { siteUrl: item.url, path: 'files' }
    }
  }

  /**
   *
   */
  function documentLibraryError (err) {
    console.error('documentLibraryerror', err)
    if (err.status === 403) {
      return {
        type: ERROR_ACTION,
        errorType: ERROR_TYPE_DOC_LIB_NO_ACCESS
      }
    } else {
      return {
        type: ERROR_ACTION,
        errorType: ERROR_TYPE_DOC_LIB_GET,
        errorMessage: err.message
      }
    }
  }

  /**
   * Returns request options needed to interact with the
   * SharePoint REST api.
   */
  function getRequestOptions (token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json;odata=verbose',
        'Content-Type': 'application/json;odata=verbose'
      }
    }
  }

  /**
   * Return an absolute url using host origin and a server relative url
   */
  function absoluteUrl (siteUrl, serverRelativeUrl) {
    const parsed = new URL(siteUrl)
    return encodeURI(`${parsed.origin}${serverRelativeUrl || ''}`)
  }

  /**
   * Case insensitive comparision of file names
   */
  function compareFilenames (_lhs, _rhs) {
    const lhs = _lhs.name.toLowerCase()
    const rhs = _rhs.name.toLowerCase()
    if (lhs < rhs) return -1
    if (lhs === rhs) return 0
    if (lhs > rhs) return 1
  }

  return {
    getRootFolder,
    getSubFolder,
    searchFiles,
    resetFileSearch,
    createFolder
  }
}
