import React, { Fragment, useCallback, useState } from 'react'
import {
  styled,
  Link,
  Grid
} from '@pergas-common/pergas-components'
import {
  Phone,
  Email,
  Contact,
  Teams,
  Remove,
  OpenExternal
} from '@pergas-common/pergas-icons'

import { useFormikContext } from 'formik'
import { EditPersonRole } from './dialogs/EditPersonRole'

const LinkSpan = styled.span`
  display: inline-block;
  width: 20px;
`
const stopPropagation = (e) => e.stopPropagation()

const items = ({ children }) => {
  const { values, setFieldValue } = useFormikContext()
  const [edit, setEdit] = useState(null)

  const onDelete = useCallback((selected) => {
    const containing = Array.isArray(values.person_role) ? [...values.person_role] : []
    const index = containing.findIndex((p) => p.person_id === selected.person_id && p.role_id === selected.role_id)
    if (index > -1) {
      containing[index] = selected
      containing.splice(index, 1)
      setFieldValue('person_role', containing)
    }
  }, [setFieldValue, values.person_role])

  const results = values?.person_role || []
  const withoutResponsible = results.filter((r) => r.role_internal_name !== 'responsible')
  const personRows = withoutResponsible.map((person) => {
    const phone = person.person_phone ?? person.address?.phone ?? null
    const email = person.person_email ?? person.address?.email ?? null
    return (
      <Fragment key={`${person.person_id}_${person.role_id}`}>
        <Grid.Item><Contact width={18} height={18} /><span>{!!person.person_name && person.person_name}</span></Grid.Item>
        <Grid.Item><span>{!!person.role_name && person.role_name}</span></Grid.Item>
        <Grid.Item>{!!phone && <Link href={`tel:${phone}`}><span>{phone}</span></Link>}</Grid.Item>
        <Grid.Item actions>
          <LinkSpan>{!!email && <Link onClickHandler={stopPropagation} href={`https://teams.microsoft.com/l/call/0/0?users=${email}`}><Teams width={20} height={20} /></Link>}</LinkSpan>
          <LinkSpan>{!!phone && <Link href={`tel:${phone}`}><Phone width={20} height={20} /></Link>}</LinkSpan>
          <LinkSpan>{!!email && <Link href={`mailto:${email}`}><Email width={16} height={16} /></Link>}</LinkSpan>
          <LinkSpan><Link onClickHandler={(e) => { stopPropagation(e); setEdit(person) }}><OpenExternal color='#28afe0' width={20} height={20} /></Link></LinkSpan>
          <LinkSpan><Link onClickHandler={(e) => { stopPropagation(e); onDelete(person) }}><Remove width={20} height={20} color='#FF5656' /></Link></LinkSpan>
        </Grid.Item>
      </Fragment>
    )
  })

  return (
    <>
      {!!edit && (
        <EditPersonRole
          isOpen={!!edit}
          selected={edit}
          onCloseRequest={() => {
            setEdit(null)
          }}
        />
      )}
      <Grid.Content columns='2fr 2fr 1fr 2fr'>{personRows}</Grid.Content>
      {children}
    </>
  )
}
export const PersonRoleGrid = items
