import { busy, notBusy } from '../busy/actions.js'
import {
  handleAddErrors,
  handleGetErrors,
  handleUpdateErrors,
  handleDeleteErrors
} from '../error/actions.js'
import { getAuthTokens } from '../common.js'

export const EMPLOYEE_CREATE_ACTION = 'EMPLOYEE_CREATE_ACTION'
export const EMPLOYEE_SHOW_ADD_DIALOG_ACTION = 'EMPLOYEE_SHOW_ADD_DIALOG_ACTION'
export const EMPLOYEE_HIDE_ADD_DIALOG_ACTION = 'EMPLOYEE_HIDE_ADD_DIALOG_ACTION'
export const EMPLOYEE_GET_OK_ACTION = 'EMPLOYEE_GET_OK_ACTION'
export const EMPLOYEE_UPDATE_ACTION = 'EMPLOYEE_UPDATE_ACTION'
export const EMPLOYEE_SHOW_EDIT_DIALOG_ACTION = 'EMPLOYEE_SHOW_EDIT_DIALOG_ACTION'
export const EMPLOYEE_HIDE_EDIT_DIALOG_ACTION = 'EMPLOYEE_HIDE_EDIT_DIALOG_ACTION'
export const EMPLOYEE_DELETE_ACTION = 'EMPLOYEE_DELETE_ACTION'
export const EMPLOYEE_SHOW_DELETE_DIALOG_ACTION = 'EMPLOYEE_SHOW_DELETE_DIALOG_ACTION'
export const EMPLOYEE_HIDE_DELETE_DIALOG_ACTION = 'EMPLOYEE_HIDE_DELETE_DIALOG_ACTION'

export const EMPLOYEE_GET_PAGE_ITEMS_ACTION = 'EMPLOYEE_GET_PAGE_ITEMS_ACTION'
export const EMPLOYEE_SET_ORDER_ACTION = 'EMPLOYEE_SET_ORDER_ACTION'
export const EMPLOYEE_SET_LIMIT_ACTION = 'EMPLOYEE_SET_LIMIT_ACTION'
export const EMPLOYEE_SET_OFFSET_ACTION = 'EMPLOYEE_SET_OFFSET_ACTION'
export const EMPLOYEE_SET_SEARCH_ACTION = 'EMPLOYEE_SET_SEARCH_ACTION'
export const EMPLOYEE_RESET_SEARCH_ACTION = 'EMPLOYEE_RESET_SEARCH_ACTION'

export function employeeCreateAction (employee) {
  return {
    type: EMPLOYEE_CREATE_ACTION,
    employee
  }
}

export function employeeUpdateAction (employee) {
  return {
    type: EMPLOYEE_UPDATE_ACTION,
    employee
  }
}

export function employeeDeleteAction (employee) {
  return {
    type: EMPLOYEE_DELETE_ACTION,
    employee
  }
}

export function employee (api, store) {
  /**
   * Add dialog
   */
  function showAddEmployeeDialog () {
    return {
      type: EMPLOYEE_SHOW_ADD_DIALOG_ACTION
    }
  }

  function hideAddEmployeeDialog () {
    return {
      type: EMPLOYEE_HIDE_ADD_DIALOG_ACTION
    }
  }

  /**
   * Edit dialog
   */
  function showEditEmployeeDialog (employee) {
    return {
      type: EMPLOYEE_SHOW_EDIT_DIALOG_ACTION,
      employee
    }
  }

  function hideEditEmployeeDialog () {
    return {
      type: EMPLOYEE_HIDE_EDIT_DIALOG_ACTION
    }
  }

  /**
   * Delete dialog
   */
  function showDeleteEmployeeDialog (employee) {
    return {
      type: EMPLOYEE_SHOW_DELETE_DIALOG_ACTION,
      employee
    }
  }

  function hideDeleteEmployeeDialog () {
    return {
      type: EMPLOYEE_HIDE_DELETE_DIALOG_ACTION
    }
  }

  /**
   * CREATE
   */
  function addEmployee (employee) {
    return function (dispatch) {
      dispatch(busy())
      api.addEmployee(employee, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleAddErrors('employee', dispatch))
    }
  }

  /**
   * READ
   */
  function getEmployees () {
    return function (dispatch) {
      dispatch(busy())
      api.getEmployees(getAuthTokens(store)).then(result => {
        dispatch(notBusy())
        dispatch(receivedEmployees(result.data.value))
      }).catch(handleGetErrors('employee', dispatch))
    }
  }

  function receivedEmployees (items) {
    return {
      type: EMPLOYEE_GET_OK_ACTION,
      items
    }
  }

  /**
   * UPDATE
   */
  function updateEmployee (employee) {
    return function (dispatch) {
      dispatch(busy())
      api.updateEmployee(employee, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleUpdateErrors('employee', dispatch))
    }
  }

  /**
   * DELETE
   */
  function deleteEmployee (employee) {
    return function (dispatch) {
      dispatch(busy())
      api.deleteEmployee(employee, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleDeleteErrors('employee', dispatch))
    }
  }

  /**
   * Get items for a page
   */
  function getPageItems () {
    const state = store.getState()
    const { search, limit, offset, orderBy, order } = state.employee

    const query = []
    if (typeof search === 'string' && search.length > 0) {
      query.push({ key: 'name', value: search, op: '~' })
    }

    const sort = orderBy ? `${orderBy}.${order}` : null

    return function (dispatch) {
      dispatch(busy())
      api.getEmployees({
        query,
        limit,
        offset,
        sort
      }, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
        dispatch({
          type: EMPLOYEE_GET_PAGE_ITEMS_ACTION,
          pageItems: result.data.value
        })
      }).catch(handleGetErrors('employee', dispatch))
    }
  }

  /**
   * Sort order
   */
  function setOrder (orderBy, order) {
    return {
      type: EMPLOYEE_SET_ORDER_ACTION,
      orderBy,
      order
    }
  }

  /**
   * Set limit for pagination
   */
  function setLimit (limit) {
    return {
      type: EMPLOYEE_SET_LIMIT_ACTION,
      limit
    }
  }

  /**
   * Set offset for pagination
   */
  function setOffset (offset) {
    return {
      type: EMPLOYEE_SET_OFFSET_ACTION,
      offset
    }
  }

  /**
   * Set search
   */
  function setSearch (search) {
    return {
      type: EMPLOYEE_SET_SEARCH_ACTION,
      search
    }
  }

  /**
   * Reset search
   */
  function resetSearch () {
    return {
      type: EMPLOYEE_RESET_SEARCH_ACTION
    }
  }
  return {
    showAddEmployeeDialog,
    hideAddEmployeeDialog,
    showEditEmployeeDialog,
    hideEditEmployeeDialog,
    showDeleteEmployeeDialog,
    hideDeleteEmployeeDialog,
    addEmployee,
    getEmployees,
    updateEmployee,
    deleteEmployee,

    getPageItems,
    setOrder,
    setLimit,
    setOffset,
    setSearch,
    resetSearch
  }
}
