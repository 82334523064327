import { errBusy } from '../busy/actions.js'
import ERROR_TYPES from './error-types'

export const ERROR_ACTION = 'ERROR_ACTION'
export const RESET_ERROR_ACTION = 'RESET_ERROR_ACTION'

const ERROR_TYPE_AUTH = 'ERROR_TYPE_AUTH'
const ERROR_TYPE_NETWORK = 'ERROR_TYPE_NETWORK'
const ERROR_TYPE_UNKNOWN = 'ERROR_TYPE_UNKNOWN'

export function handleAddErrors (scope, dispatch, requestId) {
  return function (err) {
    dispatch(errBusy({ requestId }))
    const { response } = err
    if (response) {
      if (response.status === 400) {
        dispatch(crudError(err, ERROR_TYPES[scope].add))
      } else if (response.status === 401) {
        dispatch(authError())
      } else if (response.status === 403) {
        dispatch(crudError(err, ERROR_TYPES[scope].noAddPermission))
      } else {
        dispatch(unknownError(err))
      }
    } else {
      dispatch(networkError(err))
    }
  }
}

export function handleGetErrors (scope, dispatch, requestId) {
  return function (err) {
    dispatch(errBusy({ requestId }))
    const { response } = err
    if (response) {
      if (response.status === 400) {
        dispatch(crudError(err, ERROR_TYPES[scope].get))
      } else if (response.status === 401) {
        dispatch(authError())
      } else if (response.status === 403) {
        dispatch(crudError(err, ERROR_TYPES[scope].noGetPermission))
      } else if (response.status === 404) {
        dispatch(crudError(err, ERROR_TYPES[scope].notFound))
      }
    } else {
      dispatch(networkError(err))
    }
  }
}

export function handleUpdateErrors (scope, dispatch, requestId) {
  return function (err) {
    dispatch(errBusy({ requestId }))
    const { response } = err
    if (response) {
      if (response.status === 400) {
        dispatch(crudError(err, ERROR_TYPES[scope].update))
      } else if (response.status === 401) {
        dispatch(authError())
      } else if (response.status === 403) {
        dispatch(crudError(err, ERROR_TYPES[scope].noUpdatePermission))
      } else if (response.status === 404) {
        dispatch(crudError(err, ERROR_TYPES[scope].notFound))
      } else if (response.status === 409) {
        dispatch(crudError(err, ERROR_TYPES[scope].conflict))
      } else {
        dispatch(unknownError(err))
      }
    } else {
      dispatch(networkError(err))
    }
  }
}

export function handleDeleteErrors (scope, dispatch, requestId) {
  return function (err) {
    dispatch(errBusy({ requestId }))
    const { response } = err
    if (response) {
      if (response.status === 400) {
        dispatch(crudError(err, ERROR_TYPES[scope].del))
      } else if (response.status === 401) {
        dispatch(authError())
      } else if (response.status === 403) {
        dispatch(crudError(err, ERROR_TYPES[scope].noDeletePermission))
      } else if (response.status === 404) {
        dispatch(crudError(err, ERROR_TYPES[scope].notFound))
      } else {
        dispatch(unknownError(err))
      }
    } else {
      dispatch(networkError(err))
    }
  }
}

function crudError (err, errorType = ERROR_TYPE_UNKNOWN) {
  return {
    type: ERROR_ACTION,
    errorType,
    errorMessage: err.message
  }
}

function authError () {
  return {
    type: ERROR_ACTION,
    errorType: ERROR_TYPE_AUTH
  }
}

export function networkError (err) {
  return {
    type: ERROR_ACTION,
    errorType: ERROR_TYPE_NETWORK,
    errorMessage: err.message
  }
}

export function unknownError (err) {
  return {
    type: ERROR_ACTION,
    errorType: ERROR_TYPE_UNKNOWN,
    errorMessage: err.message
  }
}

export function resetError () {
  return {
    type: RESET_ERROR_ACTION
  }
}
