import { busy, notBusy } from '../busy/actions.js'
import {
  handleAddErrors,
  handleGetErrors,
  handleUpdateErrors,
  handleDeleteErrors
} from '../error/actions.js'
import { getAuthTokens } from '../common.js'

export const ROLE_CREATE_ACTION = 'ROLE_CREATE_ACTION'
export const ROLE_SHOW_ADD_DIALOG_ACTION = 'ROLE_SHOW_ADD_DIALOG_ACTION'
export const ROLE_HIDE_ADD_DIALOG_ACTION = 'ROLE_HIDE_ADD_DIALOG_ACTION'
export const ROLE_GET_OK_ACTION = 'ROLE_GET_OK_ACTION'
export const ROLE_UPDATE_ACTION = 'ROLE_UPDATE_ACTION'
export const ROLE_SHOW_EDIT_DIALOG_ACTION = 'ROLE_SHOW_EDIT_DIALOG_ACTION'
export const ROLE_HIDE_EDIT_DIALOG_ACTION = 'ROLE_HIDE_EDIT_DIALOG_ACTION'
export const ROLE_DELETE_ACTION = 'ROLE_DELETE_ACTION'
export const ROLE_SHOW_DELETE_DIALOG_ACTION = 'ROLE_SHOW_DELETE_DIALOG_ACTION'
export const ROLE_HIDE_DELETE_DIALOG_ACTION = 'ROLE_HIDE_DELETE_DIALOG_ACTION'

export const ROLE_GET_PAGE_ITEMS_ACTION = 'ROLE_GET_PAGE_ITEMS_ACTION'
export const ROLE_SET_ORDER_ACTION = 'ROLE_SET_ORDER_ACTION'
export const ROLE_SET_LIMIT_ACTION = 'ROLE_SET_LIMIT_ACTION'
export const ROLE_SET_OFFSET_ACTION = 'ROLE_SET_OFFSET_ACTION'
export const ROLE_SET_SEARCH_ACTION = 'ROLE_SET_SEARCH_ACTION'
export const ROLE_RESET_SEARCH_ACTION = 'ROLE_RESET_SEARCH_ACTION'

export function roleCreateAction (role) {
  return {
    type: ROLE_CREATE_ACTION,
    role
  }
}

export function roleUpdateAction (role) {
  return {
    type: ROLE_UPDATE_ACTION,
    role
  }
}

export function roleDeleteAction (role) {
  return {
    type: ROLE_DELETE_ACTION,
    role
  }
}

export function role (api, store) {
  /**
   * Add dialog
   */
  function showAddRoleDialog () {
    return {
      type: ROLE_SHOW_ADD_DIALOG_ACTION
    }
  }

  function hideAddRoleDialog () {
    return {
      type: ROLE_HIDE_ADD_DIALOG_ACTION
    }
  }

  /**
   * Edit dialog
   */
  function showEditRoleDialog (role) {
    return {
      type: ROLE_SHOW_EDIT_DIALOG_ACTION,
      role
    }
  }

  function hideEditRoleDialog () {
    return {
      type: ROLE_HIDE_EDIT_DIALOG_ACTION
    }
  }

  /**
   * Delete dialog
   */
  function showDeleteRoleDialog (role) {
    return {
      type: ROLE_SHOW_DELETE_DIALOG_ACTION,
      role
    }
  }

  function hideDeleteRoleDialog () {
    return {
      type: ROLE_HIDE_DELETE_DIALOG_ACTION
    }
  }

  /**
   * CREATE
   */
  function addRole (role) {
    return function (dispatch) {
      dispatch(busy())
      api.addRole(role, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleAddErrors('role', dispatch))
    }
  }

  /**
   * READ
   */
  function getRoles () {
    return function (dispatch) {
      dispatch(busy())
      api.getRoles(getAuthTokens(store)).then(result => {
        dispatch(notBusy())
        dispatch(receivedRoles(result.data.value))
      }).catch(handleGetErrors('role', dispatch))
    }
  }

  function receivedRoles (items) {
    return {
      type: ROLE_GET_OK_ACTION,
      items
    }
  }

  /**
   * UPDATE
   */
  function updateRole (role) {
    return function (dispatch) {
      dispatch(busy())
      api.updateRole(role, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleUpdateErrors('role', dispatch))
    }
  }

  /**
   * DELETE
   */
  function deleteRole (role) {
    return function (dispatch) {
      dispatch(busy())
      api.deleteRole(role, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleDeleteErrors('role', dispatch))
    }
  }

  /**
   * Get items for a page
   */
  function getPageItems () {
    const state = store.getState()
    const { search, limit, offset, orderBy, order } = state.role

    const query = []
    if (typeof search === 'string' && search.length > 0) {
      query.push({ key: 'name', value: search, op: '~' })
    }

    const sort = orderBy ? `${orderBy}.${order}` : null

    return function (dispatch) {
      dispatch(busy())
      api.getRoles({
        query,
        limit,
        offset,
        sort
      }, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
        dispatch({
          type: ROLE_GET_PAGE_ITEMS_ACTION,
          pageItems: result.data.value
        })
      }).catch(handleGetErrors('role', dispatch))
    }
  }

  /**
   * Sort order
   */
  function setOrder (orderBy, order) {
    return {
      type: ROLE_SET_ORDER_ACTION,
      orderBy,
      order
    }
  }

  /**
   * Set limit for pagination
   */
  function setLimit (limit) {
    return {
      type: ROLE_SET_LIMIT_ACTION,
      limit
    }
  }

  /**
   * Set offset for pagination
   */
  function setOffset (offset) {
    return {
      type: ROLE_SET_OFFSET_ACTION,
      offset
    }
  }

  /**
   * Set search
   */
  function setSearch (search) {
    return {
      type: ROLE_SET_SEARCH_ACTION,
      search
    }
  }

  /**
   * Reset search
   */
  function resetSearch (search) {
    return {
      type: ROLE_RESET_SEARCH_ACTION,
      search
    }
  }

  return {
    showAddRoleDialog,
    hideAddRoleDialog,
    showEditRoleDialog,
    hideEditRoleDialog,
    showDeleteRoleDialog,
    hideDeleteRoleDialog,
    addRole,
    getRoles,
    updateRole,
    deleteRole,

    getPageItems,
    setOrder,
    setLimit,
    setOffset,
    setSearch,
    resetSearch
  }
}
