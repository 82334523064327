import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { styled, media, Button, Group } from '@pergas-common/pergas-components'
import { Email, Remove, CheckboxFilled, CheckboxEmpty } from '@pergas-common/pergas-icons'
import { selectLocale } from 'domains/locale/selectors'
import { useDeleteTicketMutation } from 'domains/ticket/hooks'
import useUrlQuery from 'hooks/useUrlQuery'
import { DeleteItemV2 } from 'components/dialogs/DeleteItem'
import AsyncLoader from 'components/AsyncLoader'

const Holder = styled.div`
  display: flex;
  max-width: 850px;
  align-items: stretch;
  flex-direction: column;
  > div:nth-child(1) {
    width: 100%;
    max-width: 320px;
    flex: 0 0 32px;  
    ${media.medium`
      flex: 1;
      max-width: none;
    `}
  }
  > div:nth-child(2) {
  }
  ${media.medium`
    flex-direction: row;
  `}
`

const ButtonFilled = styled(Button)`
  background: white;
`

export default ({ values, setFieldValue, children }) => {
  const query = useUrlQuery()
  const id = query.get('id')

  const navigate = useNavigate()
  const [remove, setRemove] = useState(false)
  const locale = useSelector(selectLocale)
  const { mutation: deleteMutation, isLoading: isDeleting, isSuccessful } = useDeleteTicketMutation()

  useEffect(() => {
    if (isSuccessful) {
      navigate('/tickets', { replace: true })
    }
  }, [navigate, isSuccessful])

  const deleteAsyncLoader = <AsyncLoader loading={isDeleting}><Remove color='#b13a22' width={20} height={20} /></AsyncLoader>
  const deleteButton = <Button type='button' disabled={!id} onClick={() => { setRemove(true) }}><span>{locale.delete}</span>{deleteAsyncLoader}</Button>
  const shareButton = (
    <Button
      type='button' disabled={!id} onClick={() => {
        window.location = `mailto:?subject=${values.name}&body=${window.location.href}`
      }}
    ><span>{locale.share}</span><Email color='#34b3e1' width={20} height={20} />
    </Button>
  )
  const toggleTicketDone = (
    <ButtonFilled
      type='button' disabled={!id} onClick={() => {
        if (values.ended_at) {
          setFieldValue('ended_at', null)
          return
        }
        setFieldValue('ended_at', new Date().toISOString())
      }}
    >
      <span>{locale.finished}</span>
      {values.ended_at ? <CheckboxFilled width={18} height={18} color='#34b3e1' /> : <CheckboxEmpty width={18} height={18} color='#34b3e1' />}
    </ButtonFilled>
  )

  return (
    <Holder>
      <div>
        {remove && <DeleteItemV2
          text={values.name}
          isOpen={remove}
          onCloseRequest={() => {
            setRemove(false)
          }}
          onSubmit={() => {
            deleteMutation({ id })
            setRemove(false)
          }}
                   />}
        <Group.Button>
          {shareButton}
          {deleteButton}
        </Group.Button>
        <Group.Button>
          {children}
        </Group.Button>
      </div>
      <div>
        <Group.Button />
        <Group.Button>
          {toggleTicketDone}
        </Group.Button>
      </div>
    </Holder>
  )
}
