import React from 'react'
import Tooltip from '@mui/material/Tooltip'

const LightTooltip = ({ title, children }) => {
  return (
    <Tooltip title={title}>
      {children}
    </Tooltip>
  )
}

export default LightTooltip
