import React, { useCallback, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { styled, Modal, Button, Group } from '@pergas-common/pergas-components'
import { Company, Add, Close } from '@pergas-common/pergas-icons'
import { MenuItem } from '@mui/material'
import redux, { api } from 'domains/index.js'
import { selectLocale } from 'domains/locale/selectors'
import useRequest from 'hooks/useRequest'
import useDebounce from 'hooks/useDebounce'
import EditDialog from './EditDialog'
import TextField from './TextField'
import { AddItemIcon } from '../icons'
import { sortArray } from '../../util'
import { InputGroup, Select } from '../Input'

const Holder = styled.div`
  display: flex;
  height: 130px;
  flex-direction: column;
`

const ContentHolder = styled.div`
  max-width: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 32px;
  align-self: center;
`

const useAvailableRoles = (collection, roles) => {
  const { values } = useFormikContext()

  const selectableRoles = Array.isArray(roles) && roles.filter((r) => {
    if (collection && values?.collection_role?.length > 0) {
      const hasRole = values.collection_role.some((cr) => cr.collection_id === collection.id && cr.role_id === r.id)
      return !hasRole && r.internal_name !== 'responsible'
    }
    return r.internal_name !== 'responsible'
  })

  return selectableRoles || []
}

/*
A couple of things going on in this component.
1. We support querying for contacts & roles.
2. We handle both editing and adding of a collection role.
3. We filter the data based on rules such as:
   a) don't show the collection if they are already in the list when creating a new collection role.
   b) don't show their role if they are already are assigned to that specific role.
*/
export const AddContactRoleV2 = ({ isOpen, onCloseRequest }) => {
  const { values, setFieldValue } = useFormikContext()
  const locale = useSelector(selectLocale)
  const [contact, setContact] = useState(null)
  const [role, setRole] = useState(null)
  const [getContacts, contacts, contactStatus] = useRequest(api.getContacts, 'contact')
  const [getRoles, roles, rolesStatus] = useRequest(api.getRoles, 'roles')

  const [debouncedContactsRequest] = useDebounce(getContacts, 300)
  const [debouncedRoleRequest] = useDebounce(getRoles, 300)
  const availableRoles = useAvailableRoles(contact, roles)

  const disableSubmit = contact === null || role === null || availableRoles.length === 0

  const onSelect = useCallback((c) => {
    const containing = Array.isArray(values.collection_role) ? [...values.collection_role] : []
    const index = containing.findIndex((cr) => cr.collection_id === c.collection_id && cr.role_id === c.role_id)
    if (index === -1) {
      setFieldValue('collection_role', [...containing, c])
    }
    onCloseRequest()
  }, [setFieldValue, onCloseRequest, values])

  useEffect(() => {
    getContacts({ sort: 'name.asc', limit: 50 })
  }, [getContacts])

  useEffect(() => {
    getRoles({ sort: 'name.asc', limit: 50 })
  }, [getRoles])

  return (
    <Modal
      title={locale.contact_role} titleIcon={<Company width={20} height={20} />} isOpen={isOpen} size='lg' footer={() => (
        <Group.Button>
          <Button onClick={() => {
            onCloseRequest()
          }}
          ><span>{locale.cancel}</span><Close width={18} height={18} color='#28afe0' />
          </Button>
          <Button
            disabled={disableSubmit} onClick={() => {
              const contactRole = {
                collection_id: contact.id,
                collection_name: contact.name,
                role_id: role.id,
                role_name: role.name,
                role_internal_name: role.internal_name
              }
              onSelect(contactRole)
            }}
          ><span>{locale.add}</span><Add width={20} height={20} color='#28afe0' />
          </Button>
        </Group.Button>
      )}
    >
      {() => (
        <Holder>
          <ContentHolder>
            <InputGroup fullWidth>
              <Select
                name='contact'
                label={locale.contact}
                defaultValue={null}
                request={(input) => {
                  debouncedContactsRequest({ query: [{ key: 'name', op: '~', value: input }], sort: 'name.asc', limit: 50 })
                }}
                requestStatus={contactStatus === 'pending'}
                items={contacts ?? []}
                handleChange={(_, id, contact) => {
                  setContact({ ...contact, id })
                }}
              />
              <Select
                name='role'
                label={locale.role}
                defaultValue={null}
                disabled={!contact}
                request={(input) => {
                  debouncedRoleRequest({ query: [{ key: 'name', op: '~', value: input }], sort: 'name.asc', limit: 50 })
                }}
                requestStatus={rolesStatus === 'pending'}
                items={availableRoles}
                handleChange={(_, id, role) => {
                  setRole({ ...role, id })
                }}
              />
            </InputGroup>
          </ContentHolder>
        </Holder>
      )}
    </Modal>
  )
}

/**
 * Enables the user to select a contact and a role from
 * two dropdowns. The user can only select a role for a
 * contact that hasn't already been selected.
 */
const AddContactRole = ({
  strings,
  contacts,
  roles,
  contactRole,
  getData,
  onOk,
  onCancel
}) => {
  useEffect(getData, [])

  const [contactId, setContactId] = useState(0)
  const [roleId, setRoleId] = useState(0)

  const saveDisabled = () => contactId === 0 || roleId === 0

  const localOnOk = () => {
    const contact = contacts.find(c => c.id === contactId)
    const role = roles.find(r => r.id === roleId)
    onOk({
      collection_id: contactId,
      collection_name: (contact && contact.name) || '',
      role_id: roleId,
      role_name: (role && role.name) || '',
      role_internal_name: (role && role.internal_name) || ''
    })
  }

  const renderContacts = () => {
    const sorted = sortArray(contacts, 'name')
    return sorted.map(item => (
      <MenuItem key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    )).concat(<MenuItem key='0' value={0}>---</MenuItem>)
  }

  const renderRoles = () => {
    // Filter out roles that has already been set for the
    // currently selected contact
    const filteredRoles = roles.filter(role => {
      if (contactId) {
        return !contactRole.some(cr => {
          return cr.collection_id === contactId && cr.role_id === role.id
        })
      } else {
        return true
      }
    })
    const sortedRoles = sortArray(filteredRoles, 'name')
    return sortedRoles.map(item => (
      <MenuItem key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    )).concat(<MenuItem key='0' value={0}>---</MenuItem>)
  }

  const handleChangeContact = (event) => {
    setContactId(event.target.value)
    setRoleId(0)
  }

  const handleChangeRole = (event) => {
    setRoleId(event.target.value)
  }

  return (
    <EditDialog
      titleText={strings.contact_role_add}
      TitleIcon={AddItemIcon}
      onOk={localOnOk}
      onCancel={onCancel}
      saveDisabled={saveDisabled()}
    >
      <TextField
        size='small'
        required
        select
        label={strings.contact}
        value={contacts.length > 0 ? contactId : 0}
        onChange={handleChangeContact}
      >
        {renderContacts()}
      </TextField>
      <TextField
        size='small'
        required
        select
        label={strings.role}
        value={roles.length > 0 ? roleId : 0}
        onChange={handleChangeRole}
        disabled={contactId === 0}
      >
        {renderRoles()}
      </TextField>
    </EditDialog>
  )
}

const mapStateToProps = (state) => {
  return {
    strings: state.locale.strings,
    contacts: state.contact.items,
    roles: state.role.items
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions } = redux
  return {
    getData: () => {
      dispatch(actions.contact.getContacts())
      dispatch(actions.role.getRoles())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddContactRole)
