import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { Add, Email, Remove, Teams, Globe, Phone, Company, Contact, OpenExternal } from '@pergas-common/pergas-icons'
import {
  styled,
  Layout,
  Button,
  Link,
  Group,
  media,
  Waves,
  Topbar,
  Card
} from '@pergas-common/pergas-components'
import { urlAsHttps } from 'util'
import { selectLocale } from '../../redux/locale/selectors'
import { makeSelectorPermissionContactPersons } from '../../redux/permission/selectors'
import { AddTagV2 } from '../dialogs/AddTag'
import redux, { api } from '../../redux'
import useRequest from '../../hooks/useRequest'
import useDebounce from '../../hooks/useDebounce'
import useUrlQuery from '../../hooks/useUrlQuery'
import { DeleteItemV2 } from '../dialogs/DeleteItem'
import { ContactPersonGridHoc } from '../ContactPersonGrid'
import { useContactsRequest } from '../../redux/contact/hooks'
import { InputGroup, Select, Input, Textarea } from '../Input'
import { TagList } from '../Tags'
import { Save } from '@mui/icons-material'
import BackArrow from '../BackArrow'

const Divider = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 320px;
  width: 100%;
  height 0.5px;
  background: ${({ theme }) => theme.palette.border.primary};
`

const TagHolder = styled.div`
  width: 100%;
  ${media.medium`
  width: 92%;
`}
  height: 268px;
`

const Spacer = styled.div`
  padding-right: 8px;
`

const Background = styled.div`
  background: ${({ theme }) => theme.palette.brand.secondary};
`

const Name = styled.span`
  ${({ theme }) => theme.typography.body};
  color: ${({ theme }) => theme.palette.common.primary};
`

const CenterLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const Overflow = styled.div`
  overflow-y: hidden;
  height: 218px;
`

const REQUEST_PARAMS = {
  limit: 50,
  sort: 'name.asc'
}

const OBJECT_TYPE_REQUEST_PARAMS = {
  query: [{ key: 'object_type_type', op: '=', value: 'contact_person' }],
  ...REQUEST_PARAMS
}

const isNewContactPersons = (pathname) => (pathname === '/contact-persons/new')

const GroupedButtons = ({ values, disableDelete, disabled, isEditMode, setIsEditMode, isSubmitting, setIsDeleteDialogOpen }) => {
  const locale = useSelector(selectLocale)
  const query = useUrlQuery()
  const id = query.get('id')
  const editButton = <Button type='button' onClick={(e) => { e.preventDefault(); setIsEditMode(true) }}><><span>{locale.update}</span><OpenExternal color='#34b3e1' width={20} height={20} /></></Button>
  const submitButton = <Button disabled={disabled} type='submit' form='submitContactPerson'><><span>{locale.save}</span>{isSubmitting ? <Waves width={20} height={20} /> : <Save style={{ color: '#34b3e1', width: 20, height: 20 }} />}</></Button>
  const shareButton = (
    <Button
      type='button' disabled={!id} onClick={() => {
        window.location = `mailto:?subject=${values.first_name}${values.last_name}&body=${window.location.href}`
      }}
    ><span>{locale.share}</span><Email color='#34b3e1' width={20} height={20} />
    </Button>
  )
  return (
    <>
      <Group.Button>
        <Button type='button' disabled={disableDelete} onClick={() => { setIsDeleteDialogOpen(true) }}><span>{locale.delete}</span><Remove color='#b13a22' width={20} height={20} /></Button>
        {isEditMode ? submitButton : editButton}
        {shareButton}
      </Group.Button>
    </>
  )
}

const validate = (values) => {
  const errors = {}
  if (!values.first_name) {
    errors.first_name = 'Required'
  }

  if (!values.last_name) {
    errors.last_name = 'Required'
  }

  return errors
}

const { actions } = redux
const stopPropagation = (e) => e.stopPropagation()

const ContactPersonDetailForm = ({ contactPerson }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectByPermissionContactPersons = useMemo(makeSelectorPermissionContactPersons, [])
  const permissions = useSelector(selectByPermissionContactPersons)
  const query = useUrlQuery()
  const id = query.get('id')
  const location = useLocation()
  const { pathname, state } = location
  const locale = useSelector(selectLocale)

  const [isEditMode, setIsEditMode] = useState(isNewContactPersons(pathname))
  const [isTagDialogOpen, setIsTagDialogOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const [getContacts, contacts, contactStatus] = useRequest(api.getContacts, 'contact')
  const [getObjectTypes, objectTypes, objectTypesStatus] = useRequest(api.getObjectTypes, 'objectType')

  // eslint-disable-next-line no-unused-vars
  const [updateContactPerson, contactPersonUpdated, updateContactPersonStatus] = useRequest(api.updateContactPerson, 'contactPerson')
  const [addContactPerson, contactPersonAdded, addContactPersonStatus] = useRequest(api.addContactPerson, 'contactPerson')

  useEffect(() => {
    if (contactPersonAdded) {
      navigate(`/contact-persons/edit?id=${contactPersonAdded.id}`, { replace: true })
    } else if (contactPersonUpdated) {
      navigate(`/contact-persons/edit?id=${contactPersonUpdated.id}`, { replace: true })
    }
  }, [contactPersonAdded, contactPersonUpdated])

  const [debouncedContactsRequest] = useDebounce(getContacts, 300)
  const [debouncedObjectTypesRequest] = useDebounce(getObjectTypes, 300)

  useEffect(() => {
    getContacts(REQUEST_PARAMS)
    getObjectTypes(OBJECT_TYPE_REQUEST_PARAMS)
  }, [id, getContacts, getObjectTypes])

  const deleteContactPerson = useCallback(() => {
    setIsDeleteDialogOpen(false)
    dispatch(actions.contactPerson.deleteContactPerson(contactPerson))
    navigate('/contact-persons', { replace: true })
  }, [dispatch, setIsDeleteDialogOpen])

  const preFillByContact = useCallback((c) => {
    if (!c || !c.address) return
    setFieldValue('address', c.address.address)
    setFieldValue('homepage', c.address.homepage)
    setFieldValue('city', c.address.city)
    setFieldValue('postal_code', c.address.postal_code)
  })
  const {
    name = '',
    description = '',
    first_name = '',
    last_name = '',
    object_type_name = '',
    object_type_id = '',
    collection_name = state?.contact?.name || '',
    collection_id = state?.contact?.id || 0,
    tags = []
  } = contactPerson || {}

  const {
    phone = '',
    email = '',
    postal_code: postalCode = '',
    homepage = '',
    city = '',
    address: _address = ''
  } = contactPerson ? contactPerson.address : {}

  const { values, dirty, errors, isSubmitting, setFieldValue, handleChange, handleSubmit } = useFormik({
    validate,
    enableReinitialize: true,
    initialValues: {
      first_name,
      last_name,
      description,
      collection_id,
      object_type_id,
      tags,
      address: _address === null ? '' : _address,
      phone: phone === null ? '' : phone,
      email: email === null ? '' : email,
      postal_code: postalCode === null ? '' : postalCode,
      homepage: homepage === null ? '' : homepage,
      city: city === null ? '' : city
    },
    onSubmit: async (_values) => {
      const { tags: _tags, ...rest } = values
      let tags = null
      if (Array.isArray(_tags)) {
        tags = _values.tags.map((tag) => tag)
      }

      const payload = {
        ...rest,
        tags: tags,
        address: {
          phone: values.phone,
          email: values.email,
          postal_code: values.postal_code,
          homepage: values.homepage,
          city: values.city,
          address: values.address
        }
      }
      if (!id) {
        await addContactPerson(payload)
        return
      }
      await updateContactPerson({ id: id, ...payload })
    }
  })
  const inputError = Object.keys(errors).length !== 0
  const disableSubmit = inputError || isSubmitting || !dirty
  const defaultCollection = { name: collection_name || '', id: collection_id || 0 } // eslint-disable-line camelcase
  const defaultObjectTypeId = { name: object_type_name || '', id: object_type_id || 0 } // eslint-disable-line camelcase
  const lockInput = (updateContactPersonStatus === 'pending' || addContactPersonStatus === 'pending' || !isEditMode)
  const isFetchingContact = contactStatus === 'pending'
  const isFetchingObjectTypes = objectTypesStatus === 'pending'
  const homepageUri = urlAsHttps(values.homepage)

  return (
    <>
      <Background>
        <Topbar left={<Name>{name && name}</Name>} />
      </Background>
      <Layout.Main>
        <Layout.Aside>
          <form onSubmit={handleSubmit} id='submitContactPerson'>
            {isTagDialogOpen && <AddTagV2
              tagType='contact_person'
              isOpen={isTagDialogOpen}
              items={values.tags}
              onCloseRequest={() => {
                setIsTagDialogOpen(false)
              }}
              onSelect={(selectedTag) => {
                const tag = { ...selectedTag, tag_id: selectedTag.id }
                if (values.tags == null) {
                  setFieldValue('tags', [tag])
                  return
                }
                const tagAlreadyExist = values.tags.some(({ tag_id: tagId }) => tagId === selectedTag.id)
                if (!tagAlreadyExist) {
                  setFieldValue('tags', [tag, ...values.tags])
                }
              }}
                                />}

            {isDeleteDialogOpen && <DeleteItemV2
              text={name}
              isOpen={isDeleteDialogOpen}
              onCloseRequest={() => {
                setIsDeleteDialogOpen(false)
              }}
              onSubmit={deleteContactPerson}
                                   />}
            <Input name='first_name' label={locale.first_name} disabled={lockInput} value={values.first_name} handleChange={handleChange} />
            <Input name='last_name' label={locale.last_name} disabled={lockInput} value={values.last_name} handleChange={handleChange} />
            <Textarea name='description' label={locale.description} disabled={lockInput} value={values.description} handleChange={handleChange} />
            <Divider />
            <Select
              name='collection_id'
              label={locale.contact}
              disabled={lockInput}
              defaultValue={defaultCollection}
              request={(input) => {
                debouncedContactsRequest({ query: [{ key: 'name', op: '~', value: input }], sort: 'name.asc', limit: 50 })
              }}
              requestStatus={isFetchingContact}
              items={contacts}
              handleChange={(fieldName, id) => {
                const prefill = contacts.find((c) => c.id === id)
                preFillByContact(prefill)
                setFieldValue('collection_id', id)
              }}
            >
              <Company
                onClick={() => {
                  if (values.collection_id) {
                    navigate(`/contacts/edit?id=${values.collection_id}`)
                  }
                }}
                color='#28afe0' width={18} height={18}
              />
            </Select>
            <Input name='address' label={locale.address} disabled={lockInput} value={values.address} handleChange={handleChange} />
            <InputGroup>
              <Input name='postal_code' label={locale.postal_code} disabled={lockInput} value={values.postal_code} handleChange={handleChange} />
              <Input name='city' label={locale.city} disabled={lockInput} value={values.city} handleChange={handleChange} />
            </InputGroup>
            <Divider />
            <Input name='phone' disabled={lockInput} value={values.phone} handleChange={handleChange}>
              <Link onClickHandler={stopPropagation} href={`tel:${values.phone}`}>
                <Phone width={18} height={18} color='#28afe0' />
              </Link>
            </Input>
            <Input name='email' disabled={lockInput} value={values.email} handleChange={handleChange}>
              <Link onClickHandler={stopPropagation} href={`mailto:${values.email}`}>
                <Email width={16} height={16} color='#28afe0' />
              </Link>
            </Input>
            <Input name='homepage' disabled={lockInput} value={values.homepage} handleChange={handleChange}>
              <Link onClickHandler={stopPropagation} href={homepageUri}>
                <Globe width={18} height={18} color='#28afe0' />
              </Link>
            </Input>
            <Input name='microsoft_teams' disabled value={`https://teams.microsoft.com/l/call/0/0?users=${values.email}`}>
              <Link onClickHandler={stopPropagation} href={`https://teams.microsoft.com/l/call/0/0?users=${values.email}`}>
                <Teams width={18} height={18} />
              </Link>
            </Input>
            <Divider />
            <Select
              name='object_type_id'
              label={locale.contact_person_object_type}
              disabled={lockInput}
              defaultValue={defaultObjectTypeId}
              request={(input) => {
                debouncedObjectTypesRequest({
                  query: [
                    { key: 'object_type_type', op: '=', value: 'contact_person' },
                    { key: 'name', op: '~', value: input }
                  ],
                  limit: 50
                })
              }}
              requestStatus={isFetchingObjectTypes}
              items={objectTypes}
              handleChange={setFieldValue}
            />
            <TagHolder>
              <Card.Content>
                <Card.Header title={locale.tags}>
                  {!lockInput && (
                    <Link
                      onClickHandler={() => {
                        setIsTagDialogOpen(true)
                      }}
                    >
                      <Add color='#28afe0' width={20} height={20} />
                    </Link>
                  )}
                </Card.Header>
                <Overflow>
                  <TagList name='tags' disabled={lockInput} tags={values.tags} handleChange={setFieldValue} />
                </Overflow>
              </Card.Content>
            </TagHolder>
          </form>
        </Layout.Aside>
        <Layout.Section>
          <GroupedButtons values={values} isEditMode={isEditMode} setIsEditMode={setIsEditMode} disableDelete={!contactPerson} disabled={disableSubmit} isSubmitting={isSubmitting} setIsDeleteDialogOpen={setIsDeleteDialogOpen} />
          <Card.Content>
            <Card.Header title={locale.contact_persons_in_same_contact}>
              {permissions.canCreate && <Link onClickHandler={() => { navigate('/contact-persons/new', { state: { contact: { name: contactPerson?.collection_name, id: contactPerson?.collection_id } } }) }}><Add width={20} height={20} color='#28afe0' /></Link>}
            </Card.Header>
            <Overflow>
              <ContactPersonGridHoc request={useContactsRequest} currentUserId={contactPerson && contactPerson.id} collectionId={values.collection_id} />
            </Overflow>
          </Card.Content>
        </Layout.Section>
      </Layout.Main>
    </>
  )
}

const ContactPersonDetails = () => {
  const query = useUrlQuery()
  const id = query.get('id')
  const location = useLocation()
  const { pathname } = location
  const navigate = useNavigate()
  const selectByPermissionContactPersons = useMemo(makeSelectorPermissionContactPersons, [])
  const permissions = useSelector(selectByPermissionContactPersons)
  const locale = useSelector(selectLocale)
  const [getContactPersons, contactPersons, contactPersonsStatus] = useRequest(api.getContactPersons, 'contactPerson')
  const isFetchingContactPersons = contactPersonsStatus === 'pending'

  useEffect(() => {
    if (id) {
      getContactPersons({ query: [{ key: 'id', op: '=', value: id }] })
    }
  }, [location.key, id, getContactPersons])

  const contactPerson = contactPersons && contactPersons.length > 0 && contactPersons[0]
  return (
    <>
      <div>
        <Topbar
          left={
            <>
              <BackArrow />
              <Spacer>
                <Contact width={20} height={20} />
              </Spacer>
              <Spacer><b>{locale.contact_persons}</b></Spacer>
              {permissions.canCreate && !isNewContactPersons(pathname) && (
                <Link onClickHandler={() => { navigate('/contact-persons/new') }}><Add width={20} height={20} color='#28afe0' /></Link>
              )}
            </>
        }
        />
      </div>
      {isNewContactPersons(pathname) ? <ContactPersonDetailForm key={location.key} /> : isFetchingContactPersons ? <Layout.Main><Layout.Aside><CenterLoader><Waves width={40} height={40} /></CenterLoader></Layout.Aside></Layout.Main> : <ContactPersonDetailForm key={location.key} contactPerson={contactPerson} />}
    </>
  )
}

export default ContactPersonDetails
