export const TOOLBAR_BG_COLOR = '#206a85'
export const DIALOG_BG_COLOR = '#546c78'
export const LOGO_COLOR = '#206a85'
export const BUTTON_COLOR = '#206a85'
export const RED_COLOR = '#ac0000'
export const TOOLBAR_HEIGHT = '50px'
export const TOPBAR_STYLE = {
  background: TOOLBAR_BG_COLOR,
  height: TOOLBAR_HEIGHT,
  minHeight: TOOLBAR_HEIGHT,
  paddingLeft: '0px'
}
// TODO remove
export const TOOLBAR_STYLE = {
  background: TOOLBAR_BG_COLOR,
  height: TOOLBAR_HEIGHT,
  minHeight: TOOLBAR_HEIGHT
}
export const listItemStyle = {
  width: '250px',
  height: '50px',
  marginLeft: '30px',
  paddingLeft: '0px',
  paddingRight: '0px',
  borderBottom: '1px solid #707070'
}
export const listItemTextStyle = {
  borderBottom: '1px solid #707070'
}
export const FAVORITE_COLOR = '#208524'
export const DEFAULT_OBJECT_TYPE_COLOR = '#206a85'
