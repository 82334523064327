export const SET_APP_PATH_ACTION = 'SET_APP_PATH_ACTION'

/**
 * Action for setting the application path.
 */
export function setAppPath (path) {
  return {
    type: SET_APP_PATH_ACTION,
    path
  }
}
