import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  styled,
  spacing
} from '@pergas-common/pergas-components'
import { api } from 'domains'
import { selectLocale } from 'domains/locale/selectors'
import useRequest from '../../hooks/useRequest'
import useDebounce from '../../hooks/useDebounce.js'
import { Select } from '../Input'

const Holder = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
    max-width: 320px;
    width: 100%;
}
`

const FilterColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
`

const Label = styled.label`
  display: inline-flex;
  align-items: flex-end;
  > div {
    height: 48px;
    margin-left: ${spacing(1)};
  }
`

const FilterProject = ({ defaultValue, applyFilter }) => {
  const [project, setProject] = useState(defaultValue)
  const locale = useSelector(selectLocale)

  const [request, result = [], status] = useRequest(api.getProjects, 'project')
  const [debouncedRequest] = useDebounce(request, 300)

  useEffect(() => {
    request({ query: [{ key: 'name', op: '=', value: '' }], sort: 'name.asc', limit: 50 })
  }, [request])

  return (
    <>
      <Holder>
        <FilterColumn>
          <Label>
            <Select
              name='project'
              label={locale.project}
              defaultValue={project}
              cb={({ selectedItem, inputValue }) => {
                if (inputValue === '') {
                  applyFilter(null)
                  setProject(null)
                }
                if (selectedItem && inputValue !== '') {
                  applyFilter(selectedItem.id, selectedItem)
                  setProject(selectedItem)
                }
              }}
              request={(input) => {
                debouncedRequest({ query: [{ key: 'name', op: '~', value: input }], sort: 'name.asc', limit: 50 })
              }}
              requestStatus={status === 'pending'}
              items={[{ id: 0, name: '' }, ...result ?? []]}
              handleChange={(_, id, contact) => {}}
            />
          </Label>
        </FilterColumn>
      </Holder>
    </>
  )
}

export default FilterProject
