import { pageDefault } from '../common.js'
import {
  DEPARTMENT_CREATE_ACTION,
  DEPARTMENT_SHOW_ADD_DIALOG_ACTION,
  DEPARTMENT_HIDE_ADD_DIALOG_ACTION,
  DEPARTMENT_GET_OK_ACTION,
  DEPARTMENT_UPDATE_ACTION,
  DEPARTMENT_SHOW_EDIT_DIALOG_ACTION,
  DEPARTMENT_HIDE_EDIT_DIALOG_ACTION,
  DEPARTMENT_DELETE_ACTION,
  DEPARTMENT_SHOW_DELETE_DIALOG_ACTION,
  DEPARTMENT_HIDE_DELETE_DIALOG_ACTION,

  DEPARTMENT_GET_PAGE_ITEMS_ACTION,
  DEPARTMENT_SET_ORDER_ACTION,
  DEPARTMENT_SET_LIMIT_ACTION,
  DEPARTMENT_SET_OFFSET_ACTION,
  DEPARTMENT_SET_SEARCH_ACTION,
  DEPARTMENT_RESET_SEARCH_ACTION,

  DEPARTMENT_SET_SELECTED_ITEM_ID
} from './actions.js'

const initialState = pageDefault('name', 'department')

/**
 * Reducer for handling department.
 */
export function department (state = initialState, action) { // eslint-disable-line
  switch (action.type) {
    case DEPARTMENT_GET_OK_ACTION:
      return Object.assign({}, state, {
        items: action.items.slice()
      })
    case DEPARTMENT_CREATE_ACTION:
      return Object.assign({}, state, {
        items: state.items.concat(action.department),
        pageItems: [action.department].concat(state.pageItems)
      })
    case DEPARTMENT_SHOW_ADD_DIALOG_ACTION:
      return Object.assign({}, state, {
        addItem: true
      })
    case DEPARTMENT_HIDE_ADD_DIALOG_ACTION:
      return Object.assign({}, state, {
        addItem: false
      })
    case DEPARTMENT_UPDATE_ACTION:
      return Object.assign({}, state, {
        items: state.items.map(d => {
          return d.id === action.department.id ? action.department : d
        }),
        pageItems: state.pageItems.map(d => {
          if (d.id === action.department.id) {
            return action.department
          }
          return d
        })
      })
    case DEPARTMENT_SHOW_EDIT_DIALOG_ACTION:
      return Object.assign({}, state, {
        editItem: action.department
      })
    case DEPARTMENT_HIDE_EDIT_DIALOG_ACTION:
      return Object.assign({}, state, {
        editItem: null
      })
    case DEPARTMENT_DELETE_ACTION:
      return Object.assign({}, state, {
        items: state.items.filter(d => d.id !== action.department.id),
        pageItems: state.pageItems.filter(d => d.id !== action.department.id)
      })
    case DEPARTMENT_SHOW_DELETE_DIALOG_ACTION:
      return Object.assign({}, state, {
        deleteItem: action.department
      })
    case DEPARTMENT_HIDE_DELETE_DIALOG_ACTION:
      return Object.assign({}, state, {
        deleteItem: null
      })

    case DEPARTMENT_GET_PAGE_ITEMS_ACTION:
      return Object.assign({}, state, { pageItems: action.pageItems })
    case DEPARTMENT_SET_ORDER_ACTION: {
      const { order, orderBy } = action
      return Object.assign({}, state, { order, orderBy, offset: 0 })
    }
    case DEPARTMENT_SET_LIMIT_ACTION:
      return Object.assign({}, state, { limit: action.limit, offset: 0 })
    case DEPARTMENT_SET_OFFSET_ACTION:
      return Object.assign({}, state, { offset: action.offset })
    case DEPARTMENT_SET_SEARCH_ACTION:
      return Object.assign({}, state, { search: action.search, offset: 0 })
    case DEPARTMENT_RESET_SEARCH_ACTION:
      return Object.assign({}, state, { search: '', offset: 0 })

    case DEPARTMENT_SET_SELECTED_ITEM_ID:
      return { ...state, selectedItemId: action.selectedItemId }

    default:
      return state
  }
}
