import { pageDefault } from '../common.js'
import {
  TAG_CREATE_ACTION,
  TAG_SHOW_ADD_DIALOG_ACTION,
  TAG_HIDE_ADD_DIALOG_ACTION,
  TAG_GET_OK_ACTION,
  TAG_UPDATE_ACTION,
  TAG_SHOW_EDIT_DIALOG_ACTION,
  TAG_HIDE_EDIT_DIALOG_ACTION,
  TAG_DELETE_ACTION,
  TAG_SHOW_DELETE_DIALOG_ACTION,
  TAG_HIDE_DELETE_DIALOG_ACTION,

  TAG_GET_PAGE_ITEMS_ACTION,
  TAG_SET_ORDER_ACTION,
  TAG_SET_LIMIT_ACTION,
  TAG_SET_OFFSET_ACTION,
  TAG_SET_SEARCH_ACTION,
  TAG_RESET_SEARCH_ACTION
} from './actions.js'

const initialState = pageDefault('name', 'tag')

/**
 * Reducer for handling tags.
 */
export function tag (state = initialState, action) {
  switch (action.type) {
    case TAG_GET_OK_ACTION:
      return Object.assign({}, state, {
        items: action.items.slice()
      })
    case TAG_CREATE_ACTION:
      return Object.assign({}, state, {
        items: state.items.concat(action.tag),
        pageItems: [action.tag].concat(state.pageItems)
      })
    case TAG_SHOW_ADD_DIALOG_ACTION:
      return Object.assign({}, state, {
        addItem: true
      })
    case TAG_HIDE_ADD_DIALOG_ACTION:
      return Object.assign({}, state, {
        addItem: false
      })
    case TAG_UPDATE_ACTION:
      return Object.assign({}, state, {
        items: state.items.map(tag => tag.id === action.tag.id ? action.tag : tag),
        pageItems: state.pageItems.map(tag => tag.id === action.tag.id ? action.tag : tag)
      })
    case TAG_SHOW_EDIT_DIALOG_ACTION:
      return Object.assign({}, state, {
        editItem: action.tag
      })
    case TAG_HIDE_EDIT_DIALOG_ACTION:
      return Object.assign({}, state, {
        editItem: null
      })
    case TAG_DELETE_ACTION:
      return Object.assign({}, state, {
        items: state.items.filter(tag => tag.id !== action.tag.id),
        pageItems: state.pageItems.filter(tag => tag.id !== action.tag.id)
      })
    case TAG_SHOW_DELETE_DIALOG_ACTION:
      return Object.assign({}, state, {
        deleteItem: action.tag
      })
    case TAG_HIDE_DELETE_DIALOG_ACTION:
      return Object.assign({}, state, {
        deleteItem: null
      })

    case TAG_GET_PAGE_ITEMS_ACTION:
      return Object.assign({}, state, { pageItems: action.pageItems })
    case TAG_SET_ORDER_ACTION: {
      const { order, orderBy } = action
      return Object.assign({}, state, { order, orderBy, offset: 0 })
    }
    case TAG_SET_LIMIT_ACTION:
      return Object.assign({}, state, { limit: action.limit, offset: 0 })
    case TAG_SET_OFFSET_ACTION:
      return Object.assign({}, state, { offset: action.offset })
    case TAG_SET_SEARCH_ACTION:
      return Object.assign({}, state, { search: action.search, offset: 0 })
    case TAG_RESET_SEARCH_ACTION: {
      return Object.assign({}, state, { search: '', offset: 0 })
    }
    default:
      return state
  }
}
