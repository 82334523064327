import { useMemo, useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useRequest from 'hooks/useRequest'
import { useRequestId } from 'hooks/useRequestId'
import redux, { api } from 'domains'
import { busy, notBusy } from 'domains/busy/reducer'
import { makeSelectMetadataByRequestId, selectStatusByRequestId } from 'domains/busy/selectors'
import { handleGetErrors, handleAddErrors, handleDeleteErrors, handleUpdateErrors } from 'domains/error/actions'
import { makeSelectorByAuthTokens } from 'domains/login/selectors'
import { makeSelectorById, makeSelectorContact } from './selectors'
/*
  * Deprecation notice
  * This function is deprecated and will be removed in a later version.
*/
export const useContactsRequest = ({ collectionId }) => {
  const [getContactPersons, contactPersons, contactPersonsStatus] = useRequest(api.getContactPersons)
  const onCollectionId = useCallback(async (collectionId, _offset) => {
    await getContactPersons({ query: [{ key: 'collection_id', op: '=', value: collectionId }], sort: 'name.asc', limit: 50 })
  }, [getContactPersons])

  useEffect(() => {
    if (collectionId) {
      onCollectionId(collectionId)
    }
  }, [onCollectionId, collectionId])
  return [contactPersons, contactPersonsStatus === 'pending']
}

/*
  * Deprecation notice
  * This function is deprecated and will be removed in a later version.
*/
export const useProjectRequest = ({ collectionId }) => {
  const [getProject, project, projectStatus] = useRequest(api.getProjects)
  const onCollectionId = useCallback(async (collectionId, _offset) => {
    await getProject({ query: [{ key: 'collection_id', op: '=', value: collectionId }], sort: 'name.asc', limit: 50 })
  }, [getProject])

  useEffect(() => {
    if (collectionId) {
      onCollectionId(collectionId)
    }
  }, [onCollectionId, collectionId])
  return [project, projectStatus === 'pending']
}

const { actions } = redux

export function useGetContactByIdQuery (id) {
  const reqId = useRequestId(id)
  const dispatch = useDispatch()
  const [refetching, setRefetching] = useState(false)
  const selectContactById = useMemo(makeSelectorById, [])
  const selectAuthTokens = useMemo(makeSelectorByAuthTokens, [])

  const status = useSelector((state) => selectStatusByRequestId(state, reqId))
  const data = useSelector((state) => selectContactById(state, id))
  const authTokens = useSelector(selectAuthTokens)

  const effects = useCallback((requestId, params) => {
    dispatch(busy({ requestId }))
    api.getContacts(params, authTokens).then(result => {
      dispatch(actions.contact.receivedContacts(result.data.value))
      dispatch(notBusy({ requestId }))
    }).catch(handleGetErrors('contact', dispatch, requestId))
  }, [dispatch, authTokens])

  useEffect(() => {
    if (status === undefined || refetching) {
      effects(reqId, { query: [{ key: 'id', op: '=', value: id }] })
      setRefetching(false)
    }
  }, [effects, id, reqId, status, refetching, setRefetching])

  const refetch = useCallback(() => {
    setRefetching(true)
  }, [setRefetching])

  const isLoading = status === 'pending' || status === undefined
  const isError = status === 'rejected'
  const isSuccessful = status === 'fulfilled'
  const isUnitialized = status === undefined
  return { isUnitialized, isError, isSuccessful, data, refetch, isLoading }
}

export function useGetContactQuery (params = { limit: 50 }) {
  const reqId = useRequestId()
  const dispatch = useDispatch()
  const [refetching, setRefetching] = useState(false)
  const selectContact = useMemo(makeSelectorContact, [])
  const selectAuthTokens = useMemo(makeSelectorByAuthTokens, [])

  const status = useSelector((state) => selectStatusByRequestId(state, reqId))
  const data = useSelector(selectContact)
  const authTokens = useSelector(selectAuthTokens)

  const effects = useCallback((requestId, params) => {
    dispatch(busy({ requestId }))
    api.getContacts(params, authTokens).then(result => {
      dispatch(actions.contact.receivedContacts(result.data.value))
      dispatch(notBusy({ requestId }))
    }).catch(handleGetErrors('contact', dispatch, requestId))
  }, [dispatch, authTokens])

  useEffect(() => {
    if (status === undefined || refetching) {
      effects(reqId, params)
      setRefetching(false)
    }
  }, [effects, params, reqId, status, refetching, setRefetching])

  const refetch = useCallback(() => {
    setRefetching(true)
  }, [setRefetching])

  const isLoading = status === 'pending' || status === undefined
  const isError = status === 'rejected'
  const isSuccessful = status === 'fulfilled'
  const isUnitialized = status === undefined
  return { isUnitialized, isError, isSuccessful, data, refetch, isLoading }
}

export function useAddContactMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()
  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])
  const selectAuthTokens = useMemo(makeSelectorByAuthTokens, [])

  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))
  const authTokens = useSelector(selectAuthTokens)

  const mutation = useCallback((contact) => {
    dispatch(busy({ requestId }))
    api.addContact(contact, authTokens).then(result => {
      dispatch(notBusy({ requestId, id: result.data.value?.id }))
    }).catch(handleAddErrors('contact', dispatch))
  }, [dispatch, requestId, authTokens])

  const isUnitialized = status === 'pending' || status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'

  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}

export function useUpdateContactMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()

  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])
  const selectAuthTokens = useMemo(makeSelectorByAuthTokens, [])
  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))
  const authTokens = useSelector(selectAuthTokens)

  const mutation = useCallback((contact) => {
    dispatch(busy({ requestId }))
    api.updateContact(contact, authTokens).then(result => {
      dispatch(notBusy({ requestId, id: result.data.value?.id }))
    }).catch(handleUpdateErrors('contact', dispatch, requestId))
  }, [dispatch, requestId])

  const isUnitialized = status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'

  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}

export function useDeleteContactMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()

  const selectAuthTokens = useMemo(makeSelectorByAuthTokens, [])
  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])
  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))
  const authTokens = useSelector(selectAuthTokens)

  const mutation = useCallback((contact) => {
    dispatch(busy({ requestId }))
    api.deleteContact(contact, authTokens).then(() => {
      dispatch(notBusy({ requestId }))
      dispatch(actions.contact.getPageItems())
    }).catch(handleDeleteErrors('contact', dispatch, requestId))
  }, [dispatch, requestId])

  const isUnitialized = status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'

  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}

export function useFavoriteContactMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()

  const selectAuthTokens = useMemo(makeSelectorByAuthTokens, [])
  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])
  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))
  const authTokens = useSelector(selectAuthTokens)

  const mutation = useCallback((contact) => {
    const favorite = !contact.is_favorite
    dispatch(busy({ requestId }))
    api.setContactFavorite(contact.id, favorite, authTokens).then(() => {
      dispatch(notBusy({ requestId }))
    }).catch(handleDeleteErrors('contact', dispatch, requestId))
  }, [dispatch, requestId])

  const isUnitialized = status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'

  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}
