import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { TablePagination } from '@mui/material'
import { Link, styled, Tag } from '@pergas-common/pergas-components'
import { Table, Td } from '../../pergas-components/index.js'
import { Add, Favorite, FavoriteFilled, Teams, Remove, OpenExternal } from '@pergas-common/pergas-icons'
import redux from 'domains'
import storage from '../../redux/storage.js'
import { makeSelectorByEmployeeId } from 'domains/login/selectors'
import SearchField from '../SearchField.js'
import DeleteItem from '../dialogs/DeleteItem.js'
import EditCategory from '../dialogs/EditCategory.js'
import PageToolbar from './PageToolbar.js'
import {
  createDateAccessor,
  sharePointAccessor
} from './columns.js'

import { sortArray } from '../../util.js'
import { CategoryIcon } from '../icons.js'
import { DEFAULT_OBJECT_TYPE_COLOR } from '../style.js'
import { css } from 'styled-components'
import { makeSelectorFilterQueries } from '../../redux/common.js'
import Filterbar from '../filter/Filterbar.js'
import All from '../filter/FilterAll.js'
import FilterMine from '../filter/FilterMine.js'
import FilterDepartment from '../filter/FilterDepartment.js'
import FilterEmployee from '../filter/FilterEmployee'
import { selectLocale } from '../../redux/locale/selectors.js'
import FileView from '../FileView.jsx'

const PageHolder = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
`

const TableHolder = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-grow: 1;
  flex-basis: 50px;

  ${({ $isExpanded }) => $isExpanded && css`
    top: 220px;
  `};
`

const NameHolder = styled.div`
  display: inline-block;
  margin: 0 8px;
`

const TagHolder = styled.span`
  margin-right: 8px;
`

const Spacer = styled.div`
  display: inline-block;
  margin: 2px;
`

const ROWS_PER_PAGE = [
  25,
  50,
  100,
  500,
  1000
]

const FilterList = () => {
  const dispatch = useDispatch()
  const selectFilterQueries = useMemo(makeSelectorFilterQueries, [])
  const selectMyEmployeeId = useMemo(makeSelectorByEmployeeId, [])

  const filterQueries = useSelector((state) => selectFilterQueries(state, 'category'))
  const myEmployeeId = useSelector((state) => selectMyEmployeeId(state))
  const locale = useSelector(selectLocale)

  const filterByMine = filterQueries?.find(({ key, value }) => key === 'person_role.person_id' && value === myEmployeeId)
  const filterByOtherResponsible = filterQueries?.find(({ key, value }) => key === 'person_role.person_id' && value !== myEmployeeId)
  const filterByAll = filterQueries.filter(({ key }) => key === 'person_role.person_id').length === 0

  const isMine = filterByMine && !filterByAll && !filterByOtherResponsible
  const isAll = filterByAll && !isMine && !filterByOtherResponsible
  const isOther = filterByOtherResponsible && !isMine && !isAll

  const responsibleFilter = useCallback((id, metadata = {}) => {
    const included = filterQueries.filter(({ key }) => key !== 'person_role.person_id' && key !== 'person_role.role_id')
    if (!id) {
      dispatch(redux.actions.category.setFilterQueries(included))
    } else {
      dispatch(redux.actions.category.setFilterQueries([...included, { key: 'person_role.person_id', value: id, op: '=', ...metadata }, { key: 'person_role.role_id', value: 1, op: '=' }]))
    }
  }, [dispatch, filterQueries])

  const collectionFilter = useCallback((id, metadata = {}) => {
    const included = filterQueries.filter(({ key }) => key !== 'collection_id')
    if (!id) {
      dispatch(redux.actions.category.setFilterQueries(included))
    } else {
      dispatch(redux.actions.category.setFilterQueries([...included, { key: 'collection_id', value: id, op: '=', ...metadata }]))
    }
  }, [dispatch, filterQueries])

  return (
    <Filterbar.Content>
      <Filterbar.Grouped border>
        <legend>{locale.responsible}</legend>
        <Filterbar.Column offset={1}>
          <All
            applyFilter={responsibleFilter} checked={isAll}
          />
          <FilterMine
            applyFilter={responsibleFilter} checked={isMine}
          />
        </Filterbar.Column>
        <Filterbar.Row offset={0}><FilterEmployee defaultValue={filterByOtherResponsible} checked={isOther} applyFilter={responsibleFilter} /></Filterbar.Row>
      </Filterbar.Grouped>
      <Filterbar.Grouped>
        <Filterbar.Row offset={-1}><FilterDepartment radio={false} applyFilter={collectionFilter} rules={filterQueries} /></Filterbar.Row>
      </Filterbar.Grouped>
    </Filterbar.Content>
  )
}

const CategoryPage = ({
  locale,
  rows,
  limit,
  offset,
  orderBy,
  order,
  search,
  resetSearch,
  getPageItems,
  setOrder,
  setLimit,
  setOffset,
  setSearch,
  fileSearchState,
  canUpdate,
  canDelete,
  deleteItem,
  onDeleteItemClick,
  onDeleteOk,
  onDeleteCancel,
  editItem,
  onEditItemClick,
  onEditOk,
  onEditCancel,
  canCreate,
  onShowAddDialog,
  onFavoriteItemClick,
  sharePointSaveEnabled,
  selectedItemId,
  setSelectedItemId,
  getRootFolder
}) => {
  const settings = storage.getPageSettings('category')
  const [expanded, setExpanded] = useState(settings.toolbarExpanded || false)

  function onSetExpanded (set) {
    storage.putPageSetting('category', { toolbarExpanded: !expanded })
    setExpanded(set)
  }

  const [showFileView, setShowFileView] = useState(settings.showFileView || false)
  function onToggleFileView () {
    storage.putPageSetting('category', { showFileView: !showFileView })
    setShowFileView(!showFileView)
  }

  function onSetFileViewHeight (height) {
    storage.putPageSetting('category', { fileViewHeight: height })
  }

  const selectFilterQueries = useMemo(makeSelectorFilterQueries, [])
  const filterQueries = useSelector((state) => selectFilterQueries(state, 'category'))

  useEffect(getPageItems, [limit, offset, orderBy, order, search, filterQueries])
  const manualSort = useCallback(({ id, isSorted, isSortedDesc }) => {
    if (isSorted && !isSortedDesc) {
      setOrder(id, 'desc')
    } else if (isSorted && isSortedDesc) {
      setOrder('', '')
    } else {
      setOrder(id, 'asc')
    }
  }, [setOrder])

  const selectedRowIds = useMemo(() => {
    const index = rows.findIndex(item => item.id === selectedItemId)
    if (index !== -1) {
      return { [String(index)]: true }
    } else {
      return {}
    }
  }, [rows, selectedItemId])

  const columns = useMemo(() => {
    return [
      {
        Header: locale.name,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (
            <Td
              {...cell.getCellProps()} left={
                <>
                  <CategoryIcon style={{ width: 16, height: 16 }} />
                  <NameHolder>
                    {!!original.name && <span>{original.name}</span>}
                  </NameHolder>
                </>
            }
            />
          )
        },
        canSort: true,
        id: 'name',
        size: 'md',
        manualSort,
        sortType: () => {}
      },
      {
        id: 'object_type_name',
        Header: locale.category_object_type,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return <Td {...cell.getCellProps()} center={original.object_type_name && <Tag color={original.object_type_color || DEFAULT_OBJECT_TYPE_COLOR} textColor={original.object_type_color ? '#FFFFFF' : '#3a4a54'} border='#969696'>{original.object_type_name}</Tag>} />
        },
        size: 'sm',
        canSort: true,
        sortType: () => {},
        manualSort
      },
      {
        Header: locale.department,
        manualSort,
        accessor: 'collection_name',
        sortType: () => {}
      },
      {
        id: 'person_role',
        Header: locale.responsible,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          const person = original.person_role && original.person_role.length > 0 && original.person_role.find((role) => role.role_internal_name === 'responsible')
          return (<Td {...cell.getCellProps()} left={person && person.person_name} />)
        },
        isSortable: false
      },
      {
        id: 'tags_string',
        Header: locale.tags,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          const tags = original.tags && original.tags.length > 0 && sortArray(original.tags, 'name').slice(0, 2).map(({ color, id, name }) => {
            return <TagHolder key={id}><Tag textColor='#FFFFFF' color={color}>{name}</Tag></TagHolder>
          })
          return (<Td {...cell.getCellProps()} left={tags && tags} />)
        },
        canSort: true,
        sortType: () => {},
        manualSort
      },
      {
        id: 'updated_at',
        Header: locale.updated_at,
        manualSort,
        accessor: createDateAccessor('updated_at'),
        sortType: () => {}
      },
      {
        Header: locale.tool_belt,
        Cell: ({ cell: { row: { original } } }) => {
          return (
            <Td
              left={
                <>
                  {sharePointSaveEnabled && sharePointAccessor(original)}
                  {original.teams_url && <Spacer><Link href={original.teams_url}><Teams width={20} height={20} /></Link></Spacer>}
                  <Spacer><Link onClickHandler={() => { onFavoriteItemClick(original) }}>{original.is_favorite ? <FavoriteFilled color='#28afe0' width={20} height={20} /> : <Favorite color='#28afe0' width={20} height={20} />}</Link></Spacer>
                  {canUpdate && <Spacer><Link onClickHandler={() => { onEditItemClick(original) }}><OpenExternal color='#28afe0' width={20} height={20} /></Link></Spacer>}
                </>
              }
              right={canDelete && <Link onClickHandler={() => { onDeleteItemClick(original) }}><Remove width={20} height={20} color='#FF5656' /></Link>}
            />
          )
        },
        size: 'sm',
        isSortable: false
      }
    ]
  }, [locale, canUpdate, canDelete, sharePointSaveEnabled, manualSort, onDeleteItemClick, onEditItemClick, onFavoriteItemClick])

  let initialSortBy = []
  if (orderBy) {
    initialSortBy = [{
      id: orderBy,
      desc: order === 'desc'
    }]
  }

  const selectedItem = rows.find(i => i.id === selectedItemId)

  return (
    <PageHolder>
      {editItem && <EditCategory isEditing item={editItem} onOk={onEditOk} onCancel={onEditCancel} />}
      {deleteItem && <DeleteItem text={deleteItem.name || deleteItem.first_name} onOk={() => { onDeleteOk(deleteItem) }} onCancel={onDeleteCancel} />}
      <PageToolbar expanded={expanded} setExpanded={onSetExpanded} left={<><CategoryIcon style={{ width: 20, height: 20 }} /><span>{locale.categories}</span> {canCreate && <Link onClickHandler={onShowAddDialog}><Add width={20} height={20} color='#28afe0' /></Link>}</>} center={<SearchField resetSearch={resetSearch} value={search} onChange={setSearch} />}>
        <FilterList />
      </PageToolbar>
      <TableHolder $isExpanded={expanded}>
        <Table
          columns={columns}
          data={rows}
          initialPageSize={limit}
          initialSortBy={initialSortBy}
          selectedRowIds={selectedRowIds}
          onRowClick={(e, row, toggleAllPageRowsSelected) => {
            getRootFolder(row.original)
            setSelectedItemId(row.original.id)
            toggleAllPageRowsSelected(false)
            row.toggleRowSelected(true)
          }}
        >
          {({ setPageSize }) => (
            <TablePagination
              rowsPerPage={limit}
              rowsPerPageOptions={ROWS_PER_PAGE}
              count={-1}
              page={offset / limit}
              labelRowsPerPage={locale.rows_per_page}
              labelDisplayedRows={({ from, to }) => `${from}-${to}`}
              onRowsPerPageChange={(e) => {
                setLimit(e.target.value)
                setPageSize(e.target.value)
              }}
              onPageChange={(e, number) => setOffset(number * limit)}
            />
          )}
        </Table>
      </TableHolder>
      <FileView siteItem={selectedItem} right={<></>} onShowToggle={onToggleFileView} isOpen={showFileView} initialHeight={settings.fileViewHeight} onSetHeight={onSetFileViewHeight} searchState={fileSearchState.category} />
    </PageHolder>
  )
}

const mapStateToProps = (state) => {
  const { locale, category, files } = state
  const permissions = state.login.permissions
  const {
    pageItems,
    limit,
    offset,
    orderBy,
    order,
    search
  } = category
  return {
    locale: locale.strings,
    rows: pageItems,
    limit,
    offset,
    orderBy,
    order,
    search,
    canUpdate: permissions.category.canUpdate,
    canDelete: permissions.category.canDelete,
    canCreate: permissions.category.canCreate,
    deleteItem: state.category.deleteItem,
    editItem: state.category.editItem,
    sharePointSaveEnabled: state.login.userData.sharePointSaveEnabled,
    fileSearchState: files.search,
    selectedItemId: category.selectedItemId
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions: { category, file } } = redux
  return {
    onShowAddDialog: () => {
      dispatch(category.showAddCategoryDialog())
    },

    onEditOk: (c) => {
      dispatch(category.hideEditCategoryDialog())
      dispatch(category.updateCategory(c))
    },
    onEditItemClick: (c) => {
      dispatch(category.showEditCategoryDialog(c))
    },
    onEditCancel: () => {
      dispatch(category.hideEditCategoryDialog())
    },

    onFavoriteItemClick: (c) => {
      dispatch(category.toggleFavorite(c))
    },

    onDeleteOk: (c) => {
      dispatch(category.hideDeleteCategoryDialog())
      dispatch(category.deleteCategory(c))
    },
    onDeleteItemClick: (c) => {
      dispatch(category.showDeleteCategoryDialog(c))
    },
    onDeleteCancel: () => {
      dispatch(category.hideDeleteCategoryDialog())
    },

    getPageItems: () => dispatch(category.getPageItems()),
    setOrder: (orderBy, order) => dispatch(category.setOrder(orderBy, order)),
    setLimit: (limit) => dispatch(category.setLimit(limit)),
    setOffset: (offset) => dispatch(category.setOffset(offset)),
    setSearch: (search) => dispatch(category.setSearch(search)),
    resetSearch: () => dispatch(category.resetSearch()),
    setSelectedItemId: (id) => dispatch(category.setSelectedItemId(id)),
    getRootFolder: (item) => dispatch(file.getRootFolder(item))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPage)
