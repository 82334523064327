import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { useDispatch, useSelector, connect } from 'react-redux'
import { useNavigate, Link as RLink } from 'react-router-dom'
import { css } from 'styled-components'
import dayjs from 'dayjs'
import { createSelector } from '@reduxjs/toolkit'
import { TablePagination } from '@mui/material'
import { makeSelectorByTemplatesEnabled, makeSelectorByEmployeeId } from 'domains/login/selectors'
import { makeSelectorSelectedItemId } from 'domains/project/selectors'
import { selectLocale } from '../../redux/locale/selectors'
import PageToolbar from './PageToolbar.js'
import redux from '../../redux/index.js'
import storage from '../../redux/storage.js'
import SearchField from '../SearchField.js'
import DeleteItem from '../dialogs/DeleteItem.js'
import {
  createDateAccessor,
  isExpired,
  sharePointAccessor
} from './columns.js'
import { Link, LinkStyle, styled, Tag } from '@pergas-common/pergas-components'
import { Table, Td } from '../../pergas-components/index.js'
import {
  Add,
  CheckBook,
  Favorite,
  FavoriteFilled,
  Teams,
  Remove,
  ArrowDown,
  ArrowRight,
  OpenExternal,
  CircularTick,
  TemplateManager
} from '@pergas-common/pergas-icons'

import { sortArray, trimNewLineFromString } from '../../util.js'
import { DEFAULT_OBJECT_TYPE_COLOR } from '../style.js'
import { SubRow, SubRowContent } from '../SubRow'
import FilterFavoriteButton from '../filter/FilterFavoriteButton'
import Filterbar from '../filter/Filterbar'
import FilterEmployee from '../filter/FilterEmployee'
import FilterObjectType from '../filter/FilterObjectType'
import All from '../filter/FilterAll'
import FilterMine from '../filter/FilterMine'
import FilterContact from '../filter/FilterContact'
import { makeSelectorFilterQueries } from '../../redux/common'
import { Switch } from '../Input'
import CsvButton from '../CsvButton'
import FileView from '../FileView.jsx'

const { actions } = redux

const PageHolder = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
`

const TableHolder = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-grow: 1;
  flex-basis: 50px;

  ${({ $isExpanded }) => $isExpanded && css`
    top: 220px;
  `};
`

const TagHolder = styled.span`
  margin-right: 8px;
`

const Spacer = styled.div`
  display: inline-block;
  margin: 2px;
`

const NameHolder = styled.div`
  display: inline-block;
  margin: 0 8px;
`

const Label = styled.span`
  margin-left: 24px
`

const InternalLink = styled(RLink)`
  ${LinkStyle}
  svg {
    vertical-align: middle;
    margin-left: 8px;
  }
`

const Expired = styled.span`
  color: ${({ date }) => isExpired(date)
? `
    #FF5656
  `
: 'inherit'};
`

const Strikethrough = styled.span`
  text-decoration: ${({ isDone }) => isDone ? 'line-through' : 'none'};
`

const ROWS_PER_PAGE = [
  25,
  50,
  100,
  500,
  1000
]

const FilterList = () => {
  const dispatch = useDispatch()
  const selectFilterQueries = useMemo(makeSelectorFilterQueries, [])
  const selectMyEmployeeId = useMemo(makeSelectorByEmployeeId, [])

  const filterQueries = useSelector((state) => selectFilterQueries(state, 'project'))
  const myEmployeeId = useSelector((state) => selectMyEmployeeId(state))
  const locale = useSelector(selectLocale)

  const filterByMine = filterQueries?.find(({ key, value }) => key === 'person_role.person_id' && value === myEmployeeId)
  const filterByProjectResponsible = filterQueries?.find(({ key, value }) => key === 'person_role.person_id' && value !== myEmployeeId)
  const filterByAll = filterQueries.filter(({ key }) => key === 'person_role.person_id').length === 0
  const filterByContactResponsible = filterQueries?.find(({ key }) => key === 'collection_person_role.person_id')

  const isMine = filterByMine && !filterByAll && !filterByProjectResponsible
  const isAll = filterByAll && !isMine && !filterByProjectResponsible
  const isOther = filterByProjectResponsible && !isMine && !isAll

  const responsibleFilter = useCallback((id, metadata = {}) => {
    const included = filterQueries.filter(({ key }) => key !== 'person_role.person_id' && key !== 'person_role.role_id')
    if (!id) {
      dispatch(redux.actions.project.setFilterQueries(included))
    } else {
      dispatch(redux.actions.project.setFilterQueries([...included, { key: 'person_role.person_id', value: id, op: '=', ...metadata }, { key: 'person_role.role_id', value: 1, op: '=' }]))
    }
  }, [dispatch, filterQueries])

  const contactFilter = useCallback((id, metadata = {}) => {
    const included = filterQueries.filter(({ key }) => key !== 'collection_id')
    if (!id) {
      dispatch(redux.actions.project.setFilterQueries(included))
    } else {
      dispatch(redux.actions.project.setFilterQueries([...included, { key: 'collection_id', value: id, op: '=', ...metadata }]))
    }
  }, [dispatch, filterQueries])

  const contactResponsibleFilter = useCallback((id, metadata = {}) => {
    const included = filterQueries.filter(({ key }) => key !== 'collection_person_role.person_id' && key !== 'collection_person_role.role_id')
    if (!id) {
      dispatch(redux.actions.project.setFilterQueries(included))
    } else {
      dispatch(redux.actions.project.setFilterQueries([...included, { key: 'collection_person_role.person_id', value: id, op: '=', ...metadata }, { key: 'collection_person_role.role_id', value: 1, op: '=' }]))
    }
  }, [dispatch, filterQueries])

  const objectTypeFilter = useCallback((id, metadata = {}) => {
    const included = filterQueries.filter(({ key }) => key !== 'object_type_id')
    if (!id) {
      dispatch(redux.actions.project.setFilterQueries(included))
    } else {
      dispatch(redux.actions.project.setFilterQueries([...included, { key: 'object_type_id', value: id, op: '=', ...metadata }]))
    }
  }, [dispatch, filterQueries])
  return (
    <Filterbar.Content>
      <Filterbar.Grouped border>
        <legend>{locale.project_responsible}</legend>
        <Filterbar.Column offset={1}>
          <All
            applyFilter={responsibleFilter} checked={isAll}
          />
          <FilterMine
            applyFilter={responsibleFilter} checked={isMine}
          />
        </Filterbar.Column>
        <Filterbar.Row offset={-0}><FilterEmployee
          checked={isOther} defaultValue={filterByProjectResponsible} applyFilter={responsibleFilter}
                                   />
        </Filterbar.Row>
      </Filterbar.Grouped>
      <Filterbar.Grouped>
        <Filterbar.Row offset={-1}>
          <FilterEmployee
            label={locale.contact_responsible}
            radio={false}
            defaultValue={filterByContactResponsible}
            applyFilter={contactResponsibleFilter}
          />
          <FilterContact
            label={locale.contact}
            radio={false}
            applyFilter={(id, selected) => {
              contactFilter(id, selected)
            }}
            rules={filterQueries}
          />
        </Filterbar.Row>
      </Filterbar.Grouped>
      <Filterbar.Grouped>
        <Filterbar.Row offset={-1}>
          <FilterObjectType
            type='project' applyFilter={objectTypeFilter} rules={filterQueries}
          />
        </Filterbar.Row>
      </Filterbar.Grouped>
      <Filterbar.Grouped>
        <Filterbar.Row offset={-2} />
      </Filterbar.Grouped>
    </Filterbar.Content>
  )
}

const makeSelectorByTicketToDelete = () => createSelector(
  (state) => state.ticket.deleteItem,
  (item) => {
    return item
  }
)

const makeSelectorByProjectId = () => createSelector(
  (state) => state.ticket.normalizedItems,
  (_, projectId) => projectId,
  (items, projectId) => {
    const tickets = []
    const ticketKeys = Object.keys(items)
    ticketKeys.forEach((id) => {
      if (items[id].project_id === projectId) {
        tickets.push(items[id])
      }
    })
    return tickets.sort((a, b) => new Date(a.expires_at) - new Date(b.expires_at))
  }
)

const makeSelectorPermissionTicket = () => createSelector(
  (state) => state.login.permissions,
  (permissions) => {
    return permissions.ticket
  }
)

const AsyncTicketsDropdown = ({ row: { original } }) => {
  const { id } = original
  const dispatch = useDispatch()

  const selectByProjectId = useMemo(makeSelectorByProjectId, [])
  const selectByPermissionTicket = useMemo(makeSelectorPermissionTicket, [])

  const tickets = useSelector((state) => selectByProjectId(state, id))
  const permissions = useSelector(selectByPermissionTicket)
  const locale = useSelector(selectLocale)

  const getTickets = useCallback((projectId) => {
    dispatch(actions.ticket.getTickets({
      query: [{ key: 'project_id', op: '=', value: projectId }],
      sort: 'expires_at.desc'
    }))
  }, [dispatch])

  const promptDeleteDialog = useCallback((ticket) => {
    dispatch(actions.ticket.showDeleteTicketDialog(ticket))
  }, [])

  useEffect(() => {
    getTickets(id)
  }, [id])
  if (tickets.length === 0) {
    return (
      <SubRow
        key={id} subRowChildren={[{
          element: (
            <SubRowContent flex={2}>
              <Spacer>
                <Spacer>
                  <Spacer>
                    <Label>{locale.tasks_no_tasks}</Label>
                  </Spacer>
                </Spacer>
              </Spacer>
            </SubRowContent>
          ),
          key: 'empty'
        }]} colSpan={7} isFirst isLast
      />
    )
  }

  const renderTickets = tickets.map((ticket, index) => {
    const { id, name, person_role: personRole, ended_at: endedAt, tags } = ticket
    const isFirst = index === 0
    const isLast = index === tickets.length - 1
    const formattedDate = ticket.expires_at ? dayjs(ticket.expires_at).format('YYYY-MM-DD') : ''

    const tagList = tags && tags.length > 0 && sortArray(tags, 'name').slice(0, 2).map(({ color, id, name }) => {
      return <TagHolder key={id}><Tag textColor='#FFFFFF' color={color}>{name}</Tag></TagHolder>
    })

    const subRowChildren = [{
      element: (
        <SubRowContent flex={2}>
          <CircularTick width={18} height={18} />
          <Spacer />
          {!!original.name && <InternalLink to={`/tickets/edit?id=${id}`}><Strikethrough isDone={endedAt}>{name}</Strikethrough></InternalLink>}
        </SubRowContent>
      ),
      key: `${id}_name`
    },
    {
      element: (
        <SubRowContent>
          {personRole && personRole.length > 0 && personRole[0].person_name}
        </SubRowContent>
      ),
      key: `${id}_person_role`
    },
    {
      element: (
        <SubRowContent>
          <Expired date={formattedDate}>{formattedDate}</Expired>
        </SubRowContent>
      ),
      key: `${id}_expired_at`
    },
    {
      element: (
        <SubRowContent flex={2} borderRight={false}>
          {tagList}
        </SubRowContent>
      ),
      key: `${id}_tags`
    }
    ]

    return (
      <SubRow key={id} subRowChildren={subRowChildren} colSpan={6} isFirst={isFirst} isLast={isLast}>
        <Td
          right={permissions.canDelete && <Link
            onClickHandler={(e) => {
              e.preventDefault()
              promptDeleteDialog(ticket)
            }} icon={<Remove width={20} height={20} color='#FF5656' />}
                                          />}
        />
      </SubRow>
    )
  })
  return renderTickets
}

const ProjectPage = ({
  locale,
  rows,
  limit,
  offset,
  orderBy,
  order,
  search,
  resetSearch,
  fileSearchState,
  filterQueries,
  getPageItems,
  setSelectedItemId,
  setOrder,
  setLimit,
  setOffset,
  setSearch,
  setFilterQueries,
  canUpdate,
  canDelete,
  deleteItem,
  onDeleteItemClick,
  onDeleteOk,
  onDeleteCancel,
  onEditItemClick,
  canCreate,
  onFavoriteItemClick,
  sharePointSaveEnabled,
  getRootFolder
}) => {
  useEffect(getPageItems, [limit, offset, orderBy, order, search, filterQueries])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const settings = storage.getPageSettings('project')
  const [expanded, setExpanded] = useState(settings.toolbarExpanded || false)

  function onSetExpanded (set) {
    storage.putPageSetting('project', { toolbarExpanded: !expanded })
    setExpanded(set)
  }

  const [showFileView, setShowFileView] = useState(settings.showFileView || false)
  function onToggleFileView () {
    storage.putPageSetting('project', { showFileView: !showFileView })
    setShowFileView(!showFileView)
  }

  function onSetFileViewHeight (height) {
    storage.putPageSetting('project', { fileViewHeight: height })
  }

  const selectSelectedItemId = useMemo(makeSelectorSelectedItemId, [])
  const selectedItemId = useSelector((state) => selectSelectedItemId(state))

  const selectorByTemplatesEnabled = useMemo(makeSelectorByTemplatesEnabled, [])
  const templatesEnabled = useSelector((state) => selectorByTemplatesEnabled(state))
  const manualSort = useCallback(({ id, isSorted, isSortedDesc }) => {
    if (isSorted && !isSortedDesc) {
      setOrder(id, 'desc')
    } else if (isSorted && isSortedDesc) {
      setOrder('', '')
    } else {
      setOrder(id, 'asc')
    }
  }, [setOrder])
  const selectorByDeleteTicket = useMemo(makeSelectorByTicketToDelete, [])
  const deleteTicket = useSelector(selectorByDeleteTicket)

  const onlyOpenProjects = filterQueries.find(({ key, value }) => key === 'ended_at' && value === null)

  const toggleClosedProjectsFilter = useCallback(() => {
    const rest = filterQueries.filter(({ key, value }) => key !== 'ended_at' && value !== null)
    const isOnlySHowingOpen = filterQueries.find(({ key, value }) => key === 'ended_at' && value === null)
    if (isOnlySHowingOpen) {
      dispatch(redux.actions.project.setFilterQueries([...rest]))
    } else {
      dispatch(redux.actions.project.setFilterQueries([...rest, { key: 'ended_at', value: null, op: '=' }]))
    }
  }, [dispatch, filterQueries])
  const onDeleteTicket = useCallback((ticket) => {
    dispatch(actions.ticket.hideDeleteTicketDialog())
    dispatch(actions.ticket.deleteTicket(ticket))
  }, [dispatch])

  const onDeleteTicketCancel = useCallback(() => {
    dispatch(actions.ticket.hideDeleteTicketDialog())
  }, [dispatch])

  const renderRowSubComponent = useCallback(
    ({ row, rowProps }) => (
      <AsyncTicketsDropdown
        row={row}
      />
    ),
    []
  )

  const transformedRows = useMemo(() => rows.map((r) => {
    const getResponsibleName = (l) => l?.find((pr) => pr.role_internal_name === 'responsible')?.person_name ?? ''
    const row = {
      [locale.id]: r.id,
      [locale.name]: r.name,
      [locale.description]: trimNewLineFromString(r.description),
      [locale.contact]: r.collection_name ?? '',
      [locale.project_object_type]: r.object_type_name ?? '',
      [locale.project_responsible]: getResponsibleName(r.person_role),
      [locale.contact_responsible]: getResponsibleName(r.collection_person_role)
      // TODO add collection responsible
    }
    if (r.started_at) {
      row[locale.started_at] = dayjs(r.started_at).format('YYYY-MM-DD')
    } else {
      row[locale.started_at] = ''
    }

    if (r.expires_at) {
      row[locale.expires_at] = dayjs(r.expires_at).format('YYYY-MM-DD')
    } else {
      row[locale.expires_at] = ''
    }

    if (r.ended_at) {
      row[locale.finished] = dayjs(r.ended_at).format('YYYY-MM-DD')
    } else {
      row[locale.finished] = ''
    }
    return row
  }), [rows, locale])

  const onExpandArrow = (row) => {
    return (e) => {
      e.stopPropagation()
      row.toggleRowExpanded()
    }
  }

  const selectedRowIds = useMemo(() => {
    const index = rows.findIndex(item => item.id === selectedItemId)
    if (index !== -1) {
      return { [String(index)]: true }
    } else {
      return {}
    }
  }, [rows, selectedItemId])

  const columns = useMemo(() => {
    return [
      {
        Header: locale.project_name,
        Cell: ({ cell }) => {
          const { row } = cell
          const { isExpanded, original } = row
          const Arrow = isExpanded ? ArrowDown : ArrowRight
          return (
            <Td
              {...cell.getCellProps()} left={
                <>
                  <Arrow width={12} height={12} style={{ cursor: 'pointer' }} onClick={onExpandArrow(row)} />
                  <Spacer />
                  <Spacer />
                  <CheckBook width={18} height={18} />
                  <NameHolder>
                    {!!original.name && <InternalLink to={`/projects/edit?id=${original.id}`}><Strikethrough isDone={original.ended_at}>{original.name}</Strikethrough></InternalLink>}
                  </NameHolder>
                </>
}
            />
          )
        },
        canSort: true,
        id: 'name',
        size: 'md',
        manualSort,
        sortType: () => {}
      },
      {
        Header: locale.contact,
        manualSort,
        accessor: 'collection_name',
        sortType: () => {},
        size: 'md'
      },
      {
        id: 'object_type_name',
        Header: locale.project_object_type,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return <Td {...cell.getCellProps()} left={original.object_type_name && <Tag color={original.object_type_color || DEFAULT_OBJECT_TYPE_COLOR} textColor={original.object_type_color ? '#FFFFFF' : '#3a4a54'} border='#969696'>{original.object_type_name}</Tag>} />
        },
        canSort: true,
        sortType: () => {},
        manualSort
      },
      {
        id: 'person_role',
        Header: locale.project_responsible,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          const person = original.person_role && original.person_role.length > 0 && original.person_role.find((role) => role.role_internal_name === 'responsible')
          return (<Td {...cell.getCellProps()} left={person && person.person_name} />)
        },
        isSortable: false
      },
      {
        id: 'tags_string',
        Header: locale.tags,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          const tags = original.tags && original.tags.length > 0 && sortArray(original.tags, 'name').slice(0, 2).map(({ color, id, name }) => {
            return <TagHolder key={id}><Tag textColor='#FFFFFF' color={color}>{name}</Tag></TagHolder>
          })
          return (<Td {...cell.getCellProps()} left={tags && tags} />)
        },
        canSort: true,
        sortType: () => {},
        manualSort
      },
      {
        id: 'started_at',
        Header: locale.started_at,
        manualSort,
        accessor: createDateAccessor('started_at', 'YYYY-MM-DD'),
        sortType: () => {}
      },
      {
        id: 'expires_at',
        Header: locale.expires_at,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          const formattedDate = original.expires_at ? dayjs(original.expires_at).format('YYYY-MM-DD') : ''
          return (<Td {...cell.getCellProps()} left={<Expired date={formattedDate}>{formattedDate}</Expired>} />)
        },
        canSort: true,
        sortType: () => {},
        manualSort
      },
      {
        Header: locale.tool_belt,
        Cell: ({ cell: { row: { original } } }) => {
          return (
            <Td
              left={
                <>
                  {sharePointSaveEnabled && sharePointAccessor(original)}
                  {templatesEnabled && <Spacer><Link href={`https://pds-tm.pergas.se?pds_id=${original.id}&pds_type=project`}><TemplateManager width={18} height={14} /></Link></Spacer>}
                  {original.teams_url && <Spacer><Link href={original.teams_url}><Teams width={20} height={20} /></Link></Spacer>}
                  <Spacer>
                    <Link onClickHandler={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      onFavoriteItemClick(original)
                    }}
                    >{original.is_favorite ? <FavoriteFilled color='#28afe0' width={20} height={20} /> : <Favorite color='#28afe0' width={20} height={20} />}
                    </Link>
                  </Spacer>
                  {canUpdate && <Spacer><Link onClickHandler={(e) => { e.stopPropagation(); navigate(`/projects/edit?id=${original.id}`) }}><OpenExternal color='#28afe0' width={20} height={20} /></Link></Spacer>}
                </>
              }
              right={canDelete && (
                <Link onClickHandler={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onDeleteItemClick(original)
                }}
                >
                  <Remove width={20} height={20} color='#FF5656' />
                </Link>
              )}
            />
          )
        },
        size: 'md',
        isSortable: false
      }
    ]
  }, [locale, canUpdate, canDelete, sharePointSaveEnabled, manualSort, onDeleteItemClick, onEditItemClick, onFavoriteItemClick, selectedRowIds])

  // TODO using old pagination, update with <Next> arrows instead

  let initialSortBy = []
  if (orderBy) {
    initialSortBy = [{
      id: orderBy,
      desc: order === 'desc'
    }]
  }

  const selectedItem = rows.find(i => i.id === selectedItemId)

  return (
    <PageHolder>
      {deleteItem && <DeleteItem text={deleteItem.name || deleteItem.first_name} onOk={() => { onDeleteOk(deleteItem) }} onCancel={onDeleteCancel} />}
      {deleteTicket && <DeleteItem text={deleteTicket.name || deleteTicket.first_name} onOk={() => { onDeleteTicket(deleteTicket) }} onCancel={onDeleteTicketCancel} />}
      <PageToolbar
        expanded={expanded} setExpanded={onSetExpanded}left={
          <>
            <CheckBook width={20} height={20} />
            <span>{locale.projects}</span>
            {canCreate && <Link onClickHandler={() => { navigate('/projects/new') }}><Add width={20} height={20} color='#28afe0' /></Link>}
            <CsvButton download={locale.project} keys={[locale.id, locale.name, locale.description, locale.contact, locale.project_object_type, locale.project_responsible, locale.contact_responsible, locale.started_at, locale.expires_at, locale.finished]} data={transformedRows} />
            <FilterFavoriteButton filterQueries={filterQueries} onFilterChange={setFilterQueries} />
            <Switch value={onlyOpenProjects} onChangeValue={toggleClosedProjectsFilter} disabled={false} />
          </>
} center={<SearchField resetSearch={resetSearch} value={search} onChange={setSearch} />}
      >
        <FilterList />
      </PageToolbar>
      <TableHolder $isExpanded={expanded}>
        <Table
          columns={columns}
          data={rows}
          initialPageSize={limit}
          initialSortBy={initialSortBy}
          renderRowSubComponent={renderRowSubComponent}
          selectedRowIds={selectedRowIds}
          onRowClick={(e, row, toggleAllPageRowsSelected) => {
            getRootFolder(row.original)
            setSelectedItemId(row.original.id)
            toggleAllPageRowsSelected(false)
            row.toggleRowSelected(true)
          }}
        >
          {({ setPageSize }) => (
            <TablePagination
              rowsPerPage={limit}
              rowsPerPageOptions={ROWS_PER_PAGE}
              count={-1}
              page={offset / limit}
              labelRowsPerPage={locale.rows_per_page}
              labelDisplayedRows={({ from, to }) => `${from}-${to}`}
              onRowsPerPageChange={(e) => {
                setLimit(e.target.value)
                setPageSize(e.target.value)
              }}
              onPageChange={(e, number) => setOffset(number * limit)}
            />
          )}
        </Table>
      </TableHolder>
      {sharePointSaveEnabled && <FileView siteItem={selectedItem} right={<></>} onShowToggle={onToggleFileView} isOpen={showFileView} initialHeight={settings.fileViewHeight} onSetHeight={onSetFileViewHeight} searchState={fileSearchState.project} />}
    </PageHolder>
  )
}

const mapStateToProps = (state) => {
  const { locale, project, files } = state
  const permissions = state.login.permissions
  const {
    pageItems,
    limit,
    offset,
    orderBy,
    order,
    search,
    filterQueries
  } = project
  return {
    locale: locale.strings,
    rows: pageItems,
    limit,
    offset,
    orderBy,
    order,
    search,
    filterQueries,
    canUpdate: permissions.project.canUpdate,
    canDelete: permissions.project.canDelete,
    canCreate: permissions.project.canCreate,
    deleteItem: state.project.deleteItem,
    sharePointSaveEnabled: state.login.userData.sharePointSaveEnabled,
    fileSearchState: files.search
  }
}

const mapDispatchToProps = (dispatch) => {
  const { project, file } = actions
  return {
    onFavoriteItemClick: (p) => {
      dispatch(project.toggleFavorite(p))
    },

    onDeleteItemClick: (p) => {
      dispatch(project.showDeleteProjectDialog(p))
    },
    onDeleteOk: (p) => {
      dispatch(project.hideDeleteProjectDialog())
      dispatch(project.deleteProject(p))
    },
    onDeleteCancel: () => {
      dispatch(project.hideDeleteProjectDialog())
    },

    getPageItems: () => dispatch(project.getPageItems()),
    setOrder: (orderBy, order) => dispatch(project.setOrder(orderBy, order)),
    setLimit: (limit) => dispatch(project.setLimit(limit)),
    setOffset: (offset) => dispatch(project.setOffset(offset)),
    setSearch: (search) => dispatch(project.setSearch(search)),
    resetSearch: () => dispatch(project.resetSearch()),
    setFilterQueries: (filterQueries) => dispatch(project.setFilterQueries(filterQueries)),
    setSelectedItemId: (id) => {
      dispatch(project.setSelectedItemId(id))
    },
    getRootFolder: (item) => {
      dispatch(file.getRootFolder(item))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage)
