import { createSelector } from '@reduxjs/toolkit'

export const makeSelectorByAuthTokens = () => createSelector(
  (state) => state.login.userData,
  (userData) => {
    const { token, idToken, accessToken } = userData
    return {
      token,
      idToken,
      spToken: accessToken
    }
  }
)

export const makeSelectorBySharepointContactsEnabled = () => createSelector(
  (state) => state.login,
  (slice) => {
    return slice.userData.sharePointContactsEnabled
  }
)

export const makeSelectorByTemplatesEnabled = () => createSelector(
  (state) => state.login,
  (slice) => {
    return slice.userData.sharePointTemplatesEnabled
  }
)

export const makeSelectorBySharepointSaveEnabled = () => createSelector(
  (state) => state.login,
  (slice) => {
    return slice.userData.sharePointSaveEnabled
  }
)

export const makeSelectorByName = () => createSelector(
  (state) => state.login,
  (slice) => {
    return slice.userData.name
  }
)

export const makeSelectorByAvatar = () => createSelector(
  (state) => state.login,
  (slice) => {
    return slice.avatar
  }
)

export const makeSelectorByRemainingTime = () => createSelector(
  (state) => state.login,
  (slice) => {
    return slice.timeLeft / 1000 / 60
  }
)

export const makeSelectorByTenantName = () => createSelector(
  (state) => state.login,
  (slice) => {
    return slice.userData.tenant_name
  }
)

export const makeSelectorByEmployeeId = () => createSelector(
  (state) => state.login,
  (slice) => {
    return slice.userData.employee_id
  }
)
