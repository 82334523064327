import { CHANGE_LOCALE_ACTION } from './actions.js'
import storage from '../storage'

export function locale (resources) {
  const LOCALE_DEFAULT = storage.getLocale() || 'sv'

  const getStrings = locale => {
    let strings = resources[locale]
    if (!strings) {
      console.error('Invalid language, defaulting to en')
      locale = 'en'
      strings = resources.en
    }
    return Object.assign({}, { locale, strings })
  }

  /**
   * Reducer for language resources
   */
  return function (state = getStrings(LOCALE_DEFAULT), action) { // eslint-disable-line
    switch (action.type) {
      case CHANGE_LOCALE_ACTION:
        return getStrings(action.locale)
      default:
        return state
    }
  }
}
