import React from 'react'
import { styled, Link, Waves } from '@pergas-common/pergas-components'
import { Folder, Sharepoint, Warning } from '@pergas-common/pergas-icons'
import dayjs from 'dayjs'

const Spacer = styled.div`
  display: inline-block;
  margin: 2px;
`

export const createDateAccessor = (prop, format = 'YYYY-MM-DD HH:mm') => {
  return (row) => {
    if (typeof row[prop] === 'string') {
      const d = dayjs(row[prop])
      return d.format(format)
    } else {
      return ''
    }
  }
}

export const isExpired = (date, format = 'YYYY-MM-DD') => {
  return dayjs().format(format) > date
}

export const sharePointAccessor = (row) => {
  function renderSharePointLinks () {
    const url = row.url ? `${row.url}/files/Forms/AllItems.aspx` : null
    if (row.type === 'category') {
      return (<Spacer><Link href={`${row.url}`}><Folder width={20} height={20} /></Link></Spacer>)
    }
    return (
      <>
        <Spacer><Link href={`${url}`}><Folder width={20} height={20} /></Link></Spacer>
        <Spacer><Link href={`${row.url}`}><Sharepoint width={20} height={20} /></Link></Spacer>
      </>
    )
  }
  switch (row.sharepoint_status) {
    case 'finished':
      return renderSharePointLinks()
    case 'error':
      return <Warning width={18} height={18} color='#FF5656' />
    case 'creating':
    case 'updating':
      return <Waves width={24} height={24} color='#20a3a9' />
    default:
      return row.url ? renderSharePointLinks() : null
  }
}
