import { io } from 'socket.io-client'

import {
  contactCreateAction,
  contactUpdateAction,
  contactDeleteAction
} from './contact/actions.js'
import {
  contactPersonCreateAction,
  contactPersonUpdateAction,
  contactPersonDeleteAction
} from './contact-person/actions.js'
import {
  projectCreateAction,
  projectUpdateAction,
  projectDeleteAction
} from './project/actions.js'
import {
  ticketCreateAction,
  ticketUpdateAction,
  ticketDeleteAction
} from './ticket/actions.js'
import {
  departmentCreateAction,
  departmentUpdateAction,
  departmentDeleteAction
} from './department/actions.js'
import {
  categoryCreateAction,
  categoryUpdateAction,
  categoryDeleteAction
} from './category/actions.js'
import {
  employeeCreateAction,
  employeeUpdateAction,
  employeeDeleteAction
} from './employee/actions.js'
import {
  tagCreateAction,
  tagUpdateAction,
  tagDeleteAction
} from './tag/actions.js'
import {
  roleCreateAction,
  roleUpdateAction,
  roleDeleteAction
} from './role/actions.js'
import {
  objectTypeCreateAction,
  objectTypeUpdateAction,
  objectTypeDeleteAction
} from './object-type/actions.js'
import {
  timeEntryCreateAction,
  timeEntryUpdateAction,
  timeEntryDeleteAction
} from './time/actions.js'
import {
  accountCreateAction,
  accountUpdateAction,
  accountDeleteAction
} from './account/actions.js'

class SocketIOClient {
  constructor () {
    this.socket = undefined
  }

  connect (token, dispatch) {
    if (this.socket) {
      console.info('socket.io already connected, will re-connect on new socket')
      const socket = this.socket
      this.socket = undefined
      socket.disconnect()
      setTimeout(() => this.connect(token, dispatch), 500)
    } else {
      const socket = this.socket = io(process.env.REACT_APP_WS_URL, {
        path: '/ws',
        extraHeaders: {
          authorization: `Bearer ${token}`,
          'x-pds-app': 'pds-cm'
        }
      })

      const messageHandler = createMessageHandler(dispatch)
      socket.on('connect', (reason) => {
        console.info('socket.io connected, registering message handler')
        socket.removeAllListeners('message')
        socket.on('message', messageHandler)
      })
    }
  }
}

function createMessageHandler (dispatch) {
  function messageHandler (message) {
    if (message.type) {
      console.info(`socket.io message '${message.type}'`, message.data)
    } else if (message.error) {
      console.error(`socket.io error '${message.error}' ${message.code}`)
    }
    switch (message.type) {
      case 'contact/create':
        dispatch(contactCreateAction(message.data))
        break
      case 'contact/update':
      case 'contact/favorite':
      case 'event/error/contact.sharepoint.create':
      case 'event/error/contact.sharepoint.update':
        dispatch(contactUpdateAction(message.data))
        break
      case 'contact/delete':
        dispatch(contactDeleteAction(message.data))
        break

      case 'contact-person/create':
        dispatch(contactPersonCreateAction(message.data))
        break
      case 'contact-person/update':
        dispatch(contactPersonUpdateAction(message.data))
        break
      case 'contact-person/delete':
        dispatch(contactPersonDeleteAction(message.data))
        break

      case 'project/create':
        dispatch(projectCreateAction(message.data))
        break
      case 'project/update':
      case 'project/favorite':
      case 'event/error/project.sharepoint.create':
      case 'event/error/project.sharepoint.update':
        dispatch(projectUpdateAction(message.data))
        break
      case 'project/delete':
        dispatch(projectDeleteAction(message.data))
        break

      case 'ticket/create':
        dispatch(ticketCreateAction(message.data))
        break
      case 'ticket/update':
        dispatch(ticketUpdateAction(message.data))
        break
      case 'ticket/delete':
        dispatch(ticketDeleteAction(message.data))
        break

      case 'department/create':
        dispatch(departmentCreateAction(message.data))
        break
      case 'department/update':
      case 'department/favorite':
      case 'event/error/department.sharepoint.create':
      case 'event/error/department.sharepoint.update':
        dispatch(departmentUpdateAction(message.data))
        break
      case 'department/delete':
        dispatch(departmentDeleteAction(message.data))
        break

      case 'category/create':
        dispatch(categoryCreateAction(message.data))
        break
      case 'category/update':
      case 'category/favorite':
      case 'event/error/category.sharepoint.create':
      case 'event/error/category.sharepoint.update':
        dispatch(categoryUpdateAction(message.data))
        break
      case 'category/delete':
        dispatch(categoryDeleteAction(message.data))
        break

      case 'employee/create':
        dispatch(employeeCreateAction(message.data))
        break
      case 'employee/update':
        dispatch(employeeUpdateAction(message.data))
        break
      case 'employee/delete':
        dispatch(employeeDeleteAction(message.data))
        break

      case 'tag/create':
        dispatch(tagCreateAction(message.data))
        break
      case 'tag/update':
        dispatch(tagUpdateAction(message.data))
        break
      case 'tag/delete':
        dispatch(tagDeleteAction(message.data))
        break

      case 'role/create':
        dispatch(roleCreateAction(message.data))
        break
      case 'role/update':
        dispatch(roleUpdateAction(message.data))
        break
      case 'role/delete':
        dispatch(roleDeleteAction(message.data))
        break

      case 'object-type/create':
        dispatch(objectTypeCreateAction(message.data))
        break
      case 'object-type/update':
        dispatch(objectTypeUpdateAction(message.data))
        break
      case 'object-type/delete':
        dispatch(objectTypeDeleteAction(message.data))
        break

      case 'time-entry/create':
        dispatch(timeEntryCreateAction(message.data))
        break
      case 'time-entry/update':
        dispatch(timeEntryUpdateAction(message.data))
        break
      case 'time-entry/delete':
        dispatch(timeEntryDeleteAction(message.data))
        break

      case 'account/create':
        dispatch(accountCreateAction(message.data))
        break
      case 'account/update':
        dispatch(accountUpdateAction(message.data))
        break
      case 'account/delete':
        dispatch(accountDeleteAction(message.data))
        break

      default:
        if (message.type) {
          console.info(`unhandled websocket message ${message.type}`, message.data)
        }
    }
  }

  return messageHandler
}

export default new SocketIOClient()
