import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  styled,
  spacing
} from '@pergas-common/pergas-components'
import { api } from 'domains'
import { selectLocale } from 'domains/locale/selectors'
import useRequest from '../../hooks/useRequest'
import useDebounce from '../../hooks/useDebounce.js'
import { Select } from '../Input'

const Holder = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
    max-width: 320px;
    width: 100%;
}
`

const FilterColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
`

const Label = styled.label`
  display: inline-flex;
  align-items: flex-end;
  > div {
    height: 48px;
    margin-left: ${spacing(1)};
  }
`

const SelectLabel = ({ type }) => {
  const locale = useSelector(selectLocale)
  switch (type) {
    case 'project':
      return <span>{locale.project_object_type}</span>
    case 'contact_person':
      return <span>{locale.contact_person_object_type}</span>
    default:
      return <span>{locale.contact_object_type}</span>
  }
}

const FilterObjectType = ({ type, applyFilter, rules }) => {
  const objectType = rules.find(({ key }) => key === 'object_type_id') ?? { id: 0, name: '' }

  const [request, result = [], status] = useRequest(api.getObjectTypes, 'objectType')
  const [debouncedRequest] = useDebounce(request, 300)

  useEffect(() => {
    request({ query: [{ key: 'object_type_type', op: '=', value: type }, { key: 'name', op: '=', value: '' }], sort: 'name.asc', limit: 50 })
  }, [request])

  return (
    <>
      <Holder>
        <FilterColumn>
          <Label>
            <Select
              name='object_type'
              label={<SelectLabel type={type} />}
              defaultValue={objectType}
              cb={({ selectedItem, inputValue }) => {
                if (inputValue === '') {
                  applyFilter(null)
                }
                if (selectedItem && inputValue !== '') {
                  applyFilter(selectedItem.id, selectedItem)
                }
              }}
              request={(input) => {
                debouncedRequest({ query: [{ key: 'object_type_type', op: '=', value: type }, { key: 'name', op: '~', value: input }], sort: 'name.asc', limit: 50 })
              }}
              requestStatus={status === 'pending'}
              items={[{ id: 0, name: '' }, ...result ?? []]}
              handleChange={(_, id, contact) => {}}
            />
          </Label>
        </FilterColumn>
      </Holder>
    </>
  )
}

export default FilterObjectType
