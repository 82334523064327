import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Link,
  styled
} from '@pergas-common/pergas-components'
import {
  Back
} from '@pergas-common/pergas-icons'
import { selectLocale } from 'domains/locale/selectors'

const Text = styled.span`
  vertical-align: super;
  ${({ theme }) => theme.typography.body};
`
export default function () {
  const navigate = useNavigate()
  const locale = useSelector(selectLocale)

  return <><Link onClickHandler={() => { navigate(-1) }}><Back width={20} height={20} /><Text>{locale.back}</Text></Link></>
}
