import parseUrl from 'url-parse'
import { busy, notBusy } from '../busy/actions.js'
import {
  networkError,
  unknownError,
  resetError,
  ERROR_ACTION
} from '../error/actions.js'
import { decodeToken } from '../../util'
import storage from '../storage'
import {
  HEARTBEAT_INTERVAL,
  RELOGIN_TIME_THRESHOLD
} from '../../config.js'

export const LOGIN_SUCCESS_ACTION = 'LOGIN_SUCCESS_ACTION'
export const LOGIN_IS_LOGGING_IN_ACTION = 'LOGIN_IS_LOGGING_IN_ACTION'
export const LOGIN_GOT_USER_AVATAR_ACTION = 'LOGIN_GOT_USER_AVATAR_ACTION'
export const LOGIN_HEARTBEAT_ACTION = 'LOGIN_HEARTBEAT_ACTION'
export const ERROR_TYPE_LOGIN = 'ERROR_TYPE_LOGIN'
export const LOGOUT_ACTION = 'LOGOUT_ACTION'

let heartBeatInterval = null

export function initLogin (api, store, msal) {
  /**
   * Login heart beat function.
   */
  function doStartHeartbeat () {
    return function (dispatch) {
      if (heartBeatInterval) {
        console.info('clearing old heartbeat!')
        clearInterval(heartBeatInterval)
        heartBeatInterval = null
      }
      console.info('starting login heartbeat!')
      function heartBeat () {
        const userData = store.getState().login.userData
        const { idToken } = userData
        const payload = decodeToken(idToken)
        const timeLeft = payload.exp * 1000 - Date.now()
        dispatch({ type: LOGIN_HEARTBEAT_ACTION, timeLeft })
        if (timeLeft < RELOGIN_TIME_THRESHOLD) {
          storage.deleteTokens()
          window.location.reload()
        }
      }
      heartBeatInterval = setInterval(heartBeat, HEARTBEAT_INTERVAL)
      heartBeat()
    }
  }

  /**
   * Main login function.
   */
  function doLogin () {
    return function (dispatch) {
      dispatch(resetError())

      /**
       * Begin logging in.
       * Check if id token is valid. If not, fetch a new one and continue
       * to the next step.
       */
      function begin () {
        dispatch({ type: LOGIN_IS_LOGGING_IN_ACTION })

        function loginPopup (request = {}) {
          dispatch(busy())
          console.info('msal.loginPopup()')
          msal.loginPopup(request).then(result => {
            dispatch(notBusy())
            apiTokenStep(result.idToken)
          }).catch((err) => {
            console.error('msal.loginPopup() failed', err)
            dispatch(notBusy())
            dispatch(loginError('LOGIN_ERROR_ID_TOKEN'))
          })
        }

        const userData = { ...store.getState().login.userData }
        const loginHint = userData?.email
        if (typeof loginHint === 'string') {
          console.info('msal.ssoSilent() with login hint', loginHint)
          const silentRequest = { loginHint }
          dispatch(busy())
          msal.ssoSilent(silentRequest).then(result => {
            dispatch(notBusy())
            apiTokenStep(result.idToken)
          }).catch((err) => {
            console.error('msal.ssoSilent() failed', err)
            console.info('trying popup..')
            dispatch(notBusy())
            loginPopup(silentRequest)
          })
        } else {
          loginPopup()
        }
      }

      /**
       * Second step.
       * Check if api token is valid. If not, fetch a new one and continue
       * to the next step.
       */
      function apiTokenStep (idToken) {
        dispatch(busy())
        api.login(idToken).then(result => {
          dispatch(notBusy())
          const userData = result.data.value
          const tenantSettings = userData?.tenant_settings || {}

          const sharePointSaveEnabled = tenantSettings.sharepoint_save_enabled || false
          const sharePointContactsEnabled = tenantSettings.sharepoint_contacts_enabled || false
          const sharePointTemplatesEnabled = tenantSettings.sharepoint_templates_enabled || false
          userData.sharePointSaveEnabled = sharePointSaveEnabled
          userData.sharePointTemplatesEnabled = sharePointTemplatesEnabled
          userData.sharePointContactsEnabled = sharePointContactsEnabled
          userData.idToken = idToken

          if (sharePointSaveEnabled) {
            // Sharepoint is used, so we should continue getting
            // access tokens.
            accessTokenStep(idToken, userData)
          } else {
            const payload = decodeToken(idToken)
            const account = msal.getAccountByUsername(payload.preferred_username)
            getGraphAccessToken(account, (err, graphAccessToken) => {
              if (err) {
                console.error('getGraphAccessToken() failed', err)
                dispatch(loginError('LOGIN_ERROR_GRAPH_TOKEN'))
              } else {
                userData.graphAccessToken = graphAccessToken
                dispatch(loginSuccessful(userData))
                getUserAvatar(graphAccessToken)
              }
            })
          }
        }).catch(err => {
          console.error('api.login() failed', err)
          dispatch(notBusy())
          const { response } = err
          if (response) {
            if (response.status === 401) {
              dispatch(loginError('LOGIN_ERROR_API_TOKEN'))
            } else {
              dispatch(unknownError(err))
            }
          } else {
            dispatch(networkError(err))
          }
        })
      }

      /**
       * Third and final step.
       */
      function accessTokenStep (idToken, userData) {
        dispatch(busy())
        const payload = decodeToken(idToken)
        const account = msal.getAccountByUsername(payload.preferred_username)
        const url = parseUrl(userData.sc_root)
        const spHost = `${url.protocol}//${url.host}`
        getSpAccessToken(spHost, account, (err, accessToken) => {
          dispatch(notBusy())
          if (err) {
            console.error('getSpAccessToken() failed', err)
            dispatch(loginError('LOGIN_ERROR_SHAREPOINT_TOKEN'))
          } else {
            userData.accessToken = accessToken
            getGraphAccessToken(account, (err, graphAccessToken) => {
              if (err) {
                console.error('getGraphAccessToken() failed', err)
                dispatch(loginError('LOGIN_ERROR_GRAPH_TOKEN'))
              } else {
                userData.graphAccessToken = graphAccessToken
                dispatch(loginSuccessful(userData))
                getUserAvatar(graphAccessToken)
              }
            })
          }
        })
      }

      function getUserAvatar (token) {
        const url = 'https://graph.microsoft.com/v1.0/me/photo/$value?size=96x96'
        const xhr = new XMLHttpRequest()
        xhr.onload = function () {
          const reader = new FileReader()
          reader.onloadend = function () {
            const avatar = reader.result
            if (avatar.startsWith('data:image/jpeg;base64,')) {
              console.info('got user avatar, caching it!')
              setTimeout(() => storage.putUserAvatar(avatar), 50)
              dispatch({ type: LOGIN_GOT_USER_AVATAR_ACTION, avatar })
            } else {
              console.error('invalid avatar image format')
            }
          }
          if (xhr.status === 200) {
            reader.readAsDataURL(xhr.response)
          } else {
            console.error('failed to get avatar')
          }
        }
        xhr.open('GET', url)
        xhr.setRequestHeader('Authorization', `Bearer ${token}`)
        xhr.responseType = 'blob'
        xhr.send()
      }

      begin()
    }
  }

  /**
   * Fetch an access token for SharePoint
   */
  function getSpAccessToken (spHost, account, cb) {
    console.log('getting sharepoint access token')
    getAccessToken({
      scopes: [`${spHost}//.default`],
      account
    }, cb)
  }

  /**
   * Fetch an access token for Graph API
   */
  function getGraphAccessToken (account, cb) {
    console.log('getting graph api access token')
    getAccessToken({
      scopes: ['user.read'],
      account
    }, cb)
  }

  function getAccessToken (request, cb) {
    console.info('msal.acquireTokenSilent()', request)
    msal.acquireTokenSilent(request).then(result => {
      cb(null, result.accessToken)
    }).catch((err) => {
      console.warn('msal.acquireTokenSilent failed', err)
      console.log('trying aquireTokenRedirect')
      // This happens when the user must give consent.
      // The next time around the silent call will succeed.
      return msal.acquireTokenRedirect(request)
    })
  }

  function loginSuccessful (ud) {
    const userData = { ...ud }
    console.info('login successful!', userData)
    setTimeout(() => storage.putUserData(userData), 50)
    return {
      type: LOGIN_SUCCESS_ACTION,
      userData
    }
  }

  function loginError (errorMessage) {
    return {
      type: ERROR_ACTION,
      errorType: ERROR_TYPE_LOGIN,
      errorMessage
    }
  }

  function doLogout () {
    storage.deleteUserData()
    storage.deleteUserAvatar()
    return function (dispatch) {
      msal.logout().then(res => {
        console.log('msal.logout() successful!')
      }).catch(err => {
        console.error('msal.logout() failed', err)
      })
    }
  }

  return {
    doStartHeartbeat,
    doLogin,
    doLogout
  }
}
