import React from 'react'
import { Topbar, spacing, styled } from '@pergas-common/pergas-components'
import BackArrow from './BackArrow'

const Holder = styled.div`
  height: 100%;
  overflow-y: auto;
`

const Margin = styled.div`
    margin: 0 ${spacing(0.5)};
`

export default ({ children, icon, link, title }) => {
  return (
    <div>
      <Topbar
        left={
          <>
            <BackArrow />
            <Margin>{icon}</Margin>
            <Margin><b>{title}</b></Margin>
            <Margin>{link && link}</Margin>
          </>
      }
      />
      <Holder>
        {children}
      </Holder>
    </div>
  )
}
