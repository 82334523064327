import React from 'react'
import { connect, useSelector } from 'react-redux'
import {
  styled,
  Modal,
  Group,
  Button
} from '@pergas-common/pergas-components'
import {
  Add,
  Remove
} from '@pergas-common/pergas-icons'
import { WarningOutlined } from '@mui/icons-material'
import { TimeEntryIcon } from '../icons.js'
import {
  Paper,
  MenuItem,
  TextField,
  TableContainer,
  TableBody,
  TableCell,
  Table,
  TableRow,
  TableHead,
  IconButton,
  Box
} from '@mui/material'
import PageToolbar from './PageToolbar.js'
import redux from '../../redux/index.js'
import { selectLocale } from '../../redux/locale/selectors.js'

const tableStyle = {
  width: '90%',
  marginTop: '1em',
  marginLeft: '1em',
  marginRight: '1em'
}
const inputStyle = {
  width: '100%',
  marginTop: '1em',
  marginLeft: '1em',
  marginRight: '1em'
}

const getTodayString = () => {
  const zeroPad = (value) => {
    return value < 10 ? `0${value}` : value
  }
  const today = new Date()
  return `${today.getFullYear()}-${zeroPad(today.getMonth() + 1)}-${zeroPad(today.getDate())}`
}

const rowHasTimeInput = (row) => {
  return row.mon || row.tue || row.wed || row.thu || row.fri || row.sat || row.sun
}

const renderList = (items) => {
  return items.map(item => (
    <MenuItem key={item.id} value={item.id}>
      {item.name || item.title}
    </MenuItem>
  )).concat(<MenuItem key='0' value={0}>---</MenuItem>)
}

const renderProjectTickets = (tickets, projectId) => {
  const filteredTickets = tickets.filter(ticket => {
    return ticket.project_id === projectId
  })
  return renderList(filteredTickets)
}

class TimeInputPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = { deleteTimeEntry: null }
  }

  componentDidMount () {
    this.props.getData()
  }

  handleSetTime (id, day, event) {
    const {
      time,
      onAddTimeEntry,
      onUpdateTimeEntry
    } = this.props
    const dayValues = time.items.filter(row => row.id === id)
    const entry = dayValues[0]
    if (entry) {
      entry[day] = parseInt(event.target.value)
      if (id && id !== 0) {
        onUpdateTimeEntry(entry)
      } else {
        onAddTimeEntry(entry)
      }
    }
  }

  renderCell (row, day) {
    const permissions = this.props.permissions
    const disabled = row.project_id === 0 || !permissions.timeEntry.canUpdate
    return (
      <TextField
        size='small'
        style={{ width: '80%' }}
        type='number'
        margin='dense'
        variant='outlined'
        disabled={disabled}
        onChange={(event) => this.handleSetTime(row.id, day, event)}
        value={row[day] || 0}
      />
    )
  }

  renderProjectSelector (row) {
    const {
      projects,
      tickets,
      time,
      onUpdateRowProject,
      onUpdateRowTicket
    } = this.props

    const getItemName = (items, itemId) => {
      const matches = items.filter(item => item.id === itemId)
      return matches[0] ? matches[0].name : 'N/A'
    }

    if (row.project_id && rowHasTimeInput(row)) {
      return (
        <TableCell component='th' scope='row'>
          {getItemName(projects, row.project_id)}
          {row.ticket_id ? ` -> ${getItemName(tickets, row.ticket_id)}` : ''}
        </TableCell>
      )
    } else {
      const reportedTicketsIds = time.items.map(item => {
        return item.ticket_id
      })
      const unusedTickets = tickets.filter(p => {
        return p.id === row.ticket_id || !reportedTicketsIds.includes(p.id)
      })

      return (
        <TableCell component='th' scope='row'>
          <TextField
            size='small'
            select
            value={row.project_id}
            style={{ width: '100%' }}
            onChange={(event) => onUpdateRowProject(event.target.value)}
            margin='dense'
            variant='outlined'
          >
            {renderList(projects)}
          </TextField>
          <TextField
            size='small'
            select
            value={row.ticket_id}
            style={{ width: '100%', display: row.project_id ? 'block' : 'none' }}
            onChange={(event) => onUpdateRowTicket(event.target.value)}
            margin='dense'
            variant='outlined'
          >
            {renderProjectTickets(unusedTickets, row.project_id)}
          </TextField>
        </TableCell>
      )
    }
  }

  renderRow (row) {
    if (!row) return null
    const { permissions } = this.props
    return (
      <TableRow key={row.id}>
        {this.renderProjectSelector(row)}
        <TableCell align='right'>{this.renderCell(row, 'mon')}</TableCell>
        <TableCell align='right'>{this.renderCell(row, 'tue')}</TableCell>
        <TableCell align='right'>{this.renderCell(row, 'wed')}</TableCell>
        <TableCell align='right'>{this.renderCell(row, 'thu')}</TableCell>
        <TableCell align='right'>{this.renderCell(row, 'fri')}</TableCell>
        <TableCell align='right'>{this.renderCell(row, 'sat')}</TableCell>
        <TableCell align='right'>{this.renderCell(row, 'sun')}</TableCell>
        {
          permissions.timeEntry.canDelete &&
            <TableCell align='right'>
              <IconButton onClick={() => this.setState({ deleteTimeEntry: row })}>
                <Remove width={20} height={20} color='#FF5656' />
              </IconButton>
            </TableCell>
        }
      </TableRow>
    )
  }

  handleSelectEmployee (employeeId) {
    const {
      time,
      onGetTimeEntries,
      onSelectEmployee
    } = this.props
    onSelectEmployee(employeeId)
    if (time.date) {
      onGetTimeEntries(time.date, employeeId)
    }
  }

  handleSetDate (dateStr) {
    const {
      onGetTimeEntries,
      onSetDate,
      time
    } = this.props
    try {
      const splittedDateStr = dateStr.split('-')
      const date = new Date()
      date.setFullYear(parseInt(splittedDateStr[0]))
      date.setMonth(parseInt(splittedDateStr[1]) - 1)
      date.setDate(parseInt(splittedDateStr[2]))
      if (!isNaN(date.getTime())) {
        onSetDate(date)
        onGetTimeEntries(date, time.employee_id || 0)
      }
    } catch (e) {
      console.error(e.message)
    }
  }

  renderDate (dayIndex) {
    const { time } = this.props
    const date = new Date(time.date.getTime())
    const currentDayIndex = date.getDay()
    const dayDiff = currentDayIndex - dayIndex - 1 // start with monday
    date.setDate(date.getDate() - dayDiff)
    return `${date.getDate()}/${date.getMonth() + 1}`
  }

  render () {
    const { deleteTimeEntry } = this.state
    const {
      employees,
      loginEmail,
      locale,
      time,
      onAddRow,
      permissions,
      onDeleteTimeEntry
    } = this.props

    let loggedInEmployeeId = 0
    if (!time.employee_id) {
      employees.forEach(employee => {
        if (employee?.address?.email === loginEmail) {
          loggedInEmployeeId = employee.id
          setTimeout(() => this.handleSelectEmployee(employee.id), 10)
        }
      })
    }

    const handleAddRow = () => {
      onAddRow(time.employee_id, time.date.getFullYear(), getWeek(time.date))
    }

    const canAddRow = () => {
      if (time.employee_id === 0) return false
      if (time.items.length === 0) return true
      return time.items.every(t => t.id !== 0)
    }

    const renderAddButton = () => {
      if (!permissions.timeEntry.canCreate) return null
      return (
        <IconButton disabled={!canAddRow()} onClick={handleAddRow}>
          <Add width={20} height={20} color='#28afe0' />
        </IconButton>
      )
    }

    return (
      <>
        {
          deleteTimeEntry &&
            <ConfirmDelete
              isOpen={!!deleteTimeEntry}
              onSubmit={() => {
                onDeleteTimeEntry(deleteTimeEntry)
                this.setState({ deleteTimeEntry: null })
              }}
              onCancel={() => this.setState({ deleteTimeEntry: null })}
            />
        }

        <PageToolbar left={<><TimeEntryIcon style={{ width: 20, height: 20 }} /> <span>{locale.time_entry}</span> </>} />
        <Box display='flex' flexDirection='col'>
          <Box p={1}>
            <TextField
              size='small'
              select
              label={locale.employee}
              value={time.employee_id || loggedInEmployeeId}
              style={inputStyle}
              onChange={(event) => this.handleSelectEmployee(event.target.value)}
              margin='dense'
              variant='outlined'
            >
              {renderList(employees)}
            </TextField>
          </Box>
          <Box p={1}>
            <TextField
              size='small'
              label={locale.date}
              type='date'
              variant='outlined'
              margin='dense'
              style={inputStyle}
              defaultValue={getTodayString()}
              disabled={time.employee_id === 0}
              onChange={(event) => this.handleSetDate(event.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Box>
        </Box>
        <TableContainer component={Paper} style={tableStyle}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={9}>
                  {time.date.getFullYear()}, {locale.week}: {getWeek(time.date)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{locale.project}</TableCell>
                <TableCell align='right'>{locale.weekday_mon} {this.renderDate(0)}</TableCell>
                <TableCell align='right'>{locale.weekday_tue} {this.renderDate(1)}</TableCell>
                <TableCell align='right'>{locale.weekday_wed} {this.renderDate(2)}</TableCell>
                <TableCell align='right'>{locale.weekday_thu} {this.renderDate(3)}</TableCell>
                <TableCell align='right'>{locale.weekday_fri} {this.renderDate(4)}</TableCell>
                <TableCell align='right'>{locale.weekday_sat} {this.renderDate(5)}</TableCell>
                <TableCell align='right'>{locale.weekday_sun} {this.renderDate(6)}</TableCell>
                <TableCell align='right' />
              </TableRow>
            </TableHead>
            <TableBody>
              {time.items.map(row => this.renderRow(row))}
            </TableBody>
          </Table>
          {renderAddButton()}
        </TableContainer>
      </>
    )
  }
}

const ConfirmDelete = ({ isOpen, onSubmit, onCancel }) => {
  const locale = useSelector(selectLocale)
  return (
    <Modal
      title={locale.delete} titleIcon={<WarningOutlined style={{ width: '20px', height: '20px' }} />} isOpen={isOpen} onCloseRequest={onCancel} size='md' footer={() => (
        <Group.Button>
          <Button onClick={onSubmit}>{locale.ok}</Button>
          <Button onClick={onCancel}>{locale.cancel}</Button>
        </Group.Button>
      )}
    >
      {() => (
        <ConfirmDeleteBody><span>{locale.time_entry_delete_confirm}</span></ConfirmDeleteBody>
      )}
    </Modal>
  )
}

const ConfirmDeleteBody = styled.div`
  height: 50px;
  padding: 16px;
  > span {
    ${({ theme }) => theme.typography.body};
    color: ${({ theme }) => theme.palette.brand.secondary};
  }
`

const mapStateToProps = (state) => {
  return {
    locale: state.locale.strings,
    projects: state.project.items,
    tickets: state.ticket.items,
    employees: state.employee.items,
    time: state.time,
    loginEmail: state.login.userData.email,
    permissions: state.login.permissions
  }
}

const getWeek = (d) => {
  // Thursday in current week decides the year.
  const date = new Date(d)
  date.setHours(0, 0, 0, 0)
  date.setDate(d.getDate() + 3 - (d.getDay() + 6) % 7)
  // January 4 is always in week 1.
  const week1 = new Date(date.getFullYear(), 0, 4)
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7)
}

const mapDispatchToProps = (dispatch) => {
  const { actions } = redux
  return {
    onAddRow: (employeeId, fullYear, weekNr) => {
      dispatch(actions.time.addNewTimeRow(employeeId, fullYear, weekNr))
    },
    onSetDate: (date) => {
      dispatch(actions.time.setDate(date))
    },
    onSelectEmployee: (employeeId) => {
      dispatch(actions.time.selectTimeEntryEmployee(employeeId))
    },
    onDeleteTimeEntry: (entry) => {
      dispatch(actions.time.deleteTimeEntry(entry))
    },
    onAddTimeEntry: (entry) => {
      dispatch(actions.time.addTimeEntry(entry))
    },
    onGetTimeEntries: (date, employeeId) => {
      dispatch(
        actions.time.getTimeEntries(
          date.getFullYear(),
          getWeek(date),
          employeeId
        )
      )
    },
    onUpdateRowProject: (projectId) => {
      dispatch(actions.time.setTimeEntryTicket(0))
      dispatch(actions.time.setTimeEntryProject(projectId))
    },
    onUpdateRowTicket: (ticketId) => {
      dispatch(actions.time.setTimeEntryTicket(ticketId))
    },
    onUpdateTimeEntry: (entry) => {
      dispatch(actions.time.updateTimeEntry(entry))
    },
    getData: () => {
      dispatch(actions.employee.getEmployees())
      dispatch(actions.project.getProjects())
      dispatch(actions.ticket.getTickets())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeInputPage)
