import React from 'react'
import { connect } from 'react-redux'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  TextField
} from '@mui/material'
import { AccountIcon } from '../icons'
import redux from '../../redux/index.js'
import EditBase from './EditBase'
import { sortArray } from '../../util'
import FLAGS from '@pergas-pds/pds-permissions/flags'
import SCOPES from '@pergas-pds/pds-permissions/scopes'

const inputLabelProps = { shrink: true }

class EditAccount extends EditBase {
  constructor (props) {
    super(props)
    const { strings } = this.props
    this.title = { text: strings.account, Icon: AccountIcon }

    const item = Object.assign({
      first_name: '',
      last_name: '',
      email: '',
      tenant_id: 0,
      permissions: []
    }, props.item)

    item.permissions = item.permissions || []

    const saveDisabled = this.isSaveDisabled(item)
    this.state = { item, saveDisabled }
  }

  componentDidMount () {
    this.props.getData()
  }

  isSaveDisabled (item) {
    return (item.first_name.length === 0 ||
            item.last_name.length === 0 ||
            item.email.length === 0 ||
            item.tenant_id === 0)
  }

  hasPermission (name, flag) {
    const p = this.state.item.permissions.find(p => p.name === name)
    return Boolean(p && p.flags & flag)
  }

  hasAllPermissions (name) {
    const p = this.state.item.permissions.find(p => p.name === name)
    return Boolean(p && p.flags === FLAGS.ALL)
  }

  setPermission (name, flag, set, render = true) {
    const { userPermissions } = this.props
    const { item } = this.state

    let permissions = item.permissions.slice()

    if (permissions.some(p => p.name === name)) {
      permissions = permissions.map(p => {
        if (p.name === name) {
          const flags = set ? p.flags | flag : p.flags & ~flag
          return flags ? { ...p, flags } : null
        } else {
          return p
        }
      }).filter(Boolean)
    } else if (set) {
      const permission = userPermissions.find(p => p.name === name)
      if (permission) {
        const p = { ...permission }
        p.flags = flag
        permissions.push(p)
      }
    }

    item.permissions = permissions
    if (render) this.setState({ item })
  }

  isAdmin () {
    return this.state.item.permissions.some(p => p.name === 'admin')
  }

  renderAdminPermissionCheckbox () {
    const { item } = this.state
    const { strings, adminPermission } = this.props

    const handleToggle = () => {
      if (!adminPermission) return
      if (this.isAdmin()) {
        item.permissions = []
      } else {
        item.permissions = [adminPermission]
      }
      this.setState({ item })
    }

    const renderCheckBox = () => {
      return (<Checkbox color='primary' onChange={handleToggle} />)
    }

    return (
      <FormControlLabel
        label={strings.admin}
        control={renderCheckBox()}
        checked={this.isAdmin()}
      />
    )
  }

  renderUserPermissionCheckboxes () {
    const { item } = this.state
    const { strings, userScopes } = this.props

    const isAdmin = this.isAdmin()

    const permissisionMainCheckbox = (scope) => {
      const checked = this.hasAllPermissions(scope.name)
      const control = (
        <Checkbox
          checked={checked}
          onChange={() => this.setPermission(scope.name, FLAGS.ALL, !checked)}
        />
      )
      return (
        <FormControlLabel
          key={scope.name}
          control={control}
          label={scope.localizedName}
          labelPlacement='start'
          disabled={isAdmin}
        />
      )
    }

    const columnMainCheckbox = (label, flag) => {
      const checked = userScopes.every(scope => {
        return this.hasPermission(scope.name, flag)
      })
      const handleChange = () => {
        userScopes.forEach((scope, i) => {
          const last = i === userScopes.length - 1
          this.setPermission(scope.name, flag, !checked, false)
          if (last) this.setState({ item })
        })
      }
      const control = (
        <Checkbox
          checked={checked}
          onChange={handleChange}
        />
      )
      return (
        <FormControlLabel
          style={{ marginTop: '5px' }}
          control={control}
          label={label}
          labelPlacement='bottom'
          disabled={isAdmin}
        />
      )
    }

    const createCheckBox = (scope) => {
      const checked = this.hasPermission(scope.name, FLAGS.CREATE)
      return (
        <Checkbox
          key={scope.name}
          checked={checked}
          onChange={() => this.setPermission(scope.name, FLAGS.CREATE, !checked)}
          disabled={isAdmin}
        />
      )
    }

    const readCheckBox = (scope) => {
      const checked = this.hasPermission(scope.name, FLAGS.READ)
      return (
        <Checkbox
          key={scope.name}
          checked={checked}
          onChange={() => this.setPermission(scope.name, FLAGS.READ, !checked)}
          disabled={isAdmin}
        />
      )
    }

    const updateCheckBox = (scope) => {
      const checked = this.hasPermission(scope.name, FLAGS.UPDATE)
      return (
        <Checkbox
          key={scope.name}
          checked={checked}
          onChange={() => this.setPermission(scope.name, FLAGS.UPDATE, !checked)}
          disabled={isAdmin}
        />
      )
    }

    const deleteCheckBox = (scope) => {
      const checked = this.hasPermission(scope.name, FLAGS.DELETE)
      return (
        <Checkbox
          key={scope.name}
          checked={checked}
          onChange={() => this.setPermission(scope.name, FLAGS.DELETE, !checked)}
          disabled={isAdmin}
        />
      )
    }

    return (
      <div>
        <FormControl style={{ marginRight: '15px' }}>
          <FormGroup>
            {userScopes.map(permissisionMainCheckbox)}
          </FormGroup>
        </FormControl>
        <FormControl>
          {userScopes.map(readCheckBox)}
          {columnMainCheckbox(strings.read, FLAGS.READ)}
        </FormControl>
        <FormControl>
          {userScopes.map(updateCheckBox)}
          {columnMainCheckbox(strings.update, FLAGS.UPDATE)}
        </FormControl>
        <FormControl>
          {userScopes.map(createCheckBox)}
          {columnMainCheckbox(strings.create, FLAGS.CREATE)}
        </FormControl>
        <FormControl>
          {userScopes.map(deleteCheckBox)}
          {columnMainCheckbox(strings.delete, FLAGS.DELETE)}
        </FormControl>
      </div>
    )
  }

  renderTenantList () {
    const tenants = sortArray(this.props.tenants, 'name')
    return tenants.map(t => (
      <MenuItem key={t.id} value={t.id}>
        {t.name}
      </MenuItem>
    )).concat(<MenuItem key='0' value={0}>---</MenuItem>)
  }

  renderContent () {
    const { strings, tenants } = this.props
    const { item } = this.state

    const handleChange = (prop) => {
      return (event) => {
        const { value } = event.target
        const { item } = this.state
        item[prop] = value
        const saveDisabled = this.isSaveDisabled(item)
        this.setState({ item, saveDisabled })
      }
    }

    return (
      <>
        <div style={{ width: '40%', float: 'left' }}>
          <TextField
            size='small'
            margin='dense'
            variant='outlined'
            label={strings.first_name}
            value={item.first_name || ''}
            fullWidth
            onChange={handleChange('first_name')}
            InputLabelProps={inputLabelProps}
            autoFocus
            required
          />
          <TextField
            size='small'
            margin='dense'
            variant='outlined'
            label={strings.last_name}
            value={item.last_name || ''}
            fullWidth
            onChange={handleChange('last_name')}
            InputLabelProps={inputLabelProps}
            required
          />
          <TextField
            size='small'
            margin='dense'
            variant='outlined'
            label={strings.email}
            value={item.email || ''}
            fullWidth
            onChange={handleChange('email')}
            InputLabelProps={inputLabelProps}
            required
          />
          <TextField
            size='small'
            select
            label={strings.tenant_name}
            value={tenants.length > 0 ? (item.tenant_id || 0) : 0}
            onChange={handleChange('tenant_id')}
            fullWidth
            margin='dense'
            variant='outlined'
            InputLabelProps={inputLabelProps}
            required
          >
            {this.renderTenantList()}
          </TextField>
          {this.renderAdminPermissionCheckbox()}
        </div>
        <div style={{ width: '60%', float: 'left' }}>
          {this.renderUserPermissionCheckboxes()}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const strings = state.locale.strings
  const permissions = state.permission.items
  const userScopes = SCOPES.USER_SCOPES.map(name => {
    const localizedName = strings[name]
    return { name, localizedName }
  })
  return {
    strings,
    adminPermission: permissions.find(p => p.name === 'admin'),
    userPermissions: permissions.filter(p => p.name !== 'admin'),
    userScopes: sortArray(userScopes, 'localizedName'),
    tenants: state.tenant.items
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions } = redux
  return {
    getData: () => {
      dispatch(actions.permission.getPermissions())
      dispatch(actions.tenant.getTenants())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAccount)
