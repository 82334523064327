import { busy, notBusy } from '../busy/actions.js'
import {
  handleAddErrors,
  handleGetErrors,
  handleUpdateErrors,
  handleDeleteErrors
} from '../error/actions.js'
import { getAuthTokens } from '../common.js'

export const TAG_CREATE_ACTION = 'TAG_CREATE_ACTION'
export const TAG_SHOW_ADD_DIALOG_ACTION = 'TAG_SHOW_ADD_DIALOG_ACTION'
export const TAG_HIDE_ADD_DIALOG_ACTION = 'TAG_HIDE_ADD_DIALOG_ACTION'
export const TAG_GET_OK_ACTION = 'TAG_GET_OK_ACTION'
export const TAG_UPDATE_ACTION = 'TAG_UPDATE_ACTION'
export const TAG_SHOW_EDIT_DIALOG_ACTION = 'TAG_SHOW_EDIT_DIALOG_ACTION'
export const TAG_HIDE_EDIT_DIALOG_ACTION = 'TAG_HIDE_EDIT_DIALOG_ACTION'
export const TAG_DELETE_ACTION = 'TAG_DELETE_ACTION'
export const TAG_SHOW_DELETE_DIALOG_ACTION = 'TAG_SHOW_DELETE_DIALOG_ACTION'
export const TAG_HIDE_DELETE_DIALOG_ACTION = 'TAG_HIDE_DELETE_DIALOG_ACTION'

export const TAG_GET_PAGE_ITEMS_ACTION = 'TAG_GET_PAGE_ITEMS_ACTION'
export const TAG_SET_ORDER_ACTION = 'TAG_SET_ORDER_ACTION'
export const TAG_SET_LIMIT_ACTION = 'TAG_SET_LIMIT_ACTION'
export const TAG_SET_OFFSET_ACTION = 'TAG_SET_OFFSET_ACTION'
export const TAG_SET_SEARCH_ACTION = 'TAG_SET_SEARCH_ACTION'
export const TAG_RESET_SEARCH_ACTION = 'TAG_RESET_SEARCH_ACTION'

export function tagCreateAction (tag) {
  return {
    type: TAG_CREATE_ACTION,
    tag
  }
}

export function tagUpdateAction (tag) {
  return {
    type: TAG_UPDATE_ACTION,
    tag
  }
}

export function tagDeleteAction (tag) {
  return {
    type: TAG_DELETE_ACTION,
    tag
  }
}

export function tag (api, store) {
  /**
   * Add dialog
   */
  function showAddTagDialog () {
    return {
      type: TAG_SHOW_ADD_DIALOG_ACTION
    }
  }

  function hideAddTagDialog () {
    return {
      type: TAG_HIDE_ADD_DIALOG_ACTION
    }
  }

  /**
   * Edit dialog
   */
  function showEditTagDialog (tag) {
    return {
      type: TAG_SHOW_EDIT_DIALOG_ACTION,
      tag
    }
  }

  function hideEditTagDialog () {
    return {
      type: TAG_HIDE_EDIT_DIALOG_ACTION
    }
  }

  /**
   * Delete dialog
   */
  function showDeleteTagDialog (tag) {
    return {
      type: TAG_SHOW_DELETE_DIALOG_ACTION,
      tag
    }
  }

  function hideDeleteTagDialog () {
    return {
      type: TAG_HIDE_DELETE_DIALOG_ACTION
    }
  }

  /**
   * CREATE
   */
  function addTag (tag) {
    return function (dispatch) {
      dispatch(busy())
      api.addTag(tag, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleAddErrors('tag', dispatch))
    }
  }

  /**
   * READ
   */
  function getTags () {
    return function (dispatch) {
      dispatch(busy())
      api.getTags(getAuthTokens(store)).then(result => {
        dispatch(notBusy())
        dispatch(receivedTags(result.data.value))
      }).catch(handleGetErrors('tag', dispatch))
    }
  }

  function receivedTags (items) {
    return {
      type: TAG_GET_OK_ACTION,
      items
    }
  }

  /**
   * UPDATE
   */
  function updateTag (tag) {
    return function (dispatch) {
      dispatch(busy())
      api.updateTag(tag, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleUpdateErrors('tag', dispatch))
    }
  }

  /**
   * DELETE
   */
  function deleteTag (tag) {
    return function (dispatch) {
      dispatch(busy())
      api.deleteTag(tag, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleDeleteErrors('tag', dispatch))
    }
  }

  /**
   * Get items for a page
   */
  function getPageItems () {
    const state = store.getState()
    const { search, limit, offset, orderBy, order } = state.tag

    const query = []
    if (typeof search === 'string' && search.length > 0) {
      query.push({ key: 'name', value: search, op: '~' })
    }

    const sort = orderBy ? `${orderBy}.${order}` : null

    return function (dispatch) {
      dispatch(busy())
      api.getTags({
        query,
        limit,
        offset,
        sort
      }, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
        dispatch({
          type: TAG_GET_PAGE_ITEMS_ACTION,
          pageItems: result.data.value
        })
      }).catch(handleGetErrors('tag', dispatch))
    }
  }

  /**
   * Sort order
   */
  function setOrder (orderBy, order) {
    return {
      type: TAG_SET_ORDER_ACTION,
      orderBy,
      order
    }
  }

  /**
   * Set limit for pagination
   */
  function setLimit (limit) {
    return {
      type: TAG_SET_LIMIT_ACTION,
      limit
    }
  }

  /**
   * Set offset for pagination
   */
  function setOffset (offset) {
    return {
      type: TAG_SET_OFFSET_ACTION,
      offset
    }
  }

  /**
   * Set search
   */
  function setSearch (search) {
    return {
      type: TAG_SET_SEARCH_ACTION,
      search
    }
  }

  /**
   * Reset search
   */
  function resetSearch () {
    return {
      type: TAG_RESET_SEARCH_ACTION
    }
  }

  return {
    showAddTagDialog,
    hideAddTagDialog,
    showEditTagDialog,
    hideEditTagDialog,
    showDeleteTagDialog,
    hideDeleteTagDialog,
    addTag,
    getTags,
    updateTag,
    deleteTag,

    getPageItems,
    setOrder,
    setLimit,
    setOffset,
    setSearch,
    resetSearch
  }
}
