import { pageDefault } from '../common.js'
import {
  ROLE_CREATE_ACTION,
  ROLE_SHOW_ADD_DIALOG_ACTION,
  ROLE_HIDE_ADD_DIALOG_ACTION,
  ROLE_GET_OK_ACTION,
  ROLE_UPDATE_ACTION,
  ROLE_SHOW_EDIT_DIALOG_ACTION,
  ROLE_HIDE_EDIT_DIALOG_ACTION,
  ROLE_DELETE_ACTION,
  ROLE_SHOW_DELETE_DIALOG_ACTION,
  ROLE_HIDE_DELETE_DIALOG_ACTION,

  ROLE_GET_PAGE_ITEMS_ACTION,
  ROLE_SET_ORDER_ACTION,
  ROLE_SET_LIMIT_ACTION,
  ROLE_SET_OFFSET_ACTION,
  ROLE_SET_SEARCH_ACTION,
  ROLE_RESET_SEARCH_ACTION
} from './actions.js'

const initialState = pageDefault('name', 'role')

/**
 * Reducer for handling roles.
 */
export function role (state = initialState, action) {
  switch (action.type) {
    case ROLE_GET_OK_ACTION:
      return Object.assign({}, state, {
        items: action.items.slice()
      })
    case ROLE_CREATE_ACTION:
      return Object.assign({}, state, {
        items: state.items.concat(action.role),
        pageItems: [action.role].concat(state.pageItems)
      })
    case ROLE_SHOW_ADD_DIALOG_ACTION:
      return Object.assign({}, state, {
        addItem: true
      })
    case ROLE_HIDE_ADD_DIALOG_ACTION:
      return Object.assign({}, state, {
        addItem: false
      })
    case ROLE_UPDATE_ACTION:
      return Object.assign({}, state, {
        items: state.items.map(r => r.id === action.role.id ? action.role : r),
        pageItems: state.pageItems.map(r => r.id === action.role.id ? action.role : r)
      })
    case ROLE_SHOW_EDIT_DIALOG_ACTION:
      return Object.assign({}, state, {
        editItem: action.role
      })
    case ROLE_HIDE_EDIT_DIALOG_ACTION:
      return Object.assign({}, state, {
        editItem: null
      })
    case ROLE_DELETE_ACTION:
      return Object.assign({}, state, {
        items: state.items.filter(r => r.id !== action.role.id),
        pageItems: state.pageItems.filter(r => r.id !== action.role.id)
      })
    case ROLE_SHOW_DELETE_DIALOG_ACTION:
      return Object.assign({}, state, {
        deleteItem: action.role
      })
    case ROLE_HIDE_DELETE_DIALOG_ACTION:
      return Object.assign({}, state, {
        deleteItem: null
      })

    case ROLE_GET_PAGE_ITEMS_ACTION:
      return Object.assign({}, state, { pageItems: action.pageItems })
    case ROLE_SET_ORDER_ACTION: {
      const { order, orderBy } = action
      return Object.assign({}, state, { order, orderBy, offset: 0 })
    }
    case ROLE_SET_LIMIT_ACTION:
      return Object.assign({}, state, { limit: action.limit, offset: 0 })
    case ROLE_SET_OFFSET_ACTION:
      return Object.assign({}, state, { offset: action.offset })
    case ROLE_SET_SEARCH_ACTION:
      return Object.assign({}, state, { search: action.search, offset: 0 })
    case ROLE_RESET_SEARCH_ACTION:
      return Object.assign({}, state, { search: '', offset: 0 })

    default:
      return state
  }
}
