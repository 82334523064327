const APP_NAME = `pds.cm.${process.env.NODE_ENV}`
const USER_DATA_KEY = `${APP_NAME}.userData`
const AVATAR_KEY = `${APP_NAME}.avatar`
const LOCALE_KEY = `${APP_NAME}.locale`
const PAGES_KEY = `${APP_NAME}.pages`

function toBoolean (str) {
  return str === 'true'
}

const storage = {
  // userData
  getUserData () {
    return {
      // From pds-api
      email: localStorage.getItem(`${USER_DATA_KEY}.email`),
      name: localStorage.getItem(`${USER_DATA_KEY}.name`),
      employee_id: localStorage.getItem(`${USER_DATA_KEY}.employee_id`),
      permissions: localStorage.getItem(`${USER_DATA_KEY}.permissions`),
      token: localStorage.getItem(`${USER_DATA_KEY}.token`),
      sc_root: localStorage.getItem(`${USER_DATA_KEY}.sc_root`),
      tenant_name: localStorage.getItem(`${USER_DATA_KEY}.tenant_name`),
      sharePointSaveEnabled: toBoolean(localStorage.getItem(`${USER_DATA_KEY}.sharePointSaveEnabled`)),
      sharePointContactsEnabled: toBoolean(localStorage.getItem(`${USER_DATA_KEY}.sharePointContactsEnabled`)),

      sharePointTemplatesEnabled: toBoolean(localStorage.getItem(`${USER_DATA_KEY}.sharePointTemplatesEnabled`)),
      // From Microsoft
      idToken: localStorage.getItem(`${USER_DATA_KEY}.idToken`),
      accessToken: localStorage.getItem(`${USER_DATA_KEY}.accessToken`) || '',
      graphAccessToken: localStorage.getItem(`${USER_DATA_KEY}.graphAccessToken`) || ''
    }
  },
  putUserData (userData) {
    localStorage.setItem(`${USER_DATA_KEY}.email`, userData.email)
    localStorage.setItem(`${USER_DATA_KEY}.name`, userData.name)
    localStorage.setItem(`${USER_DATA_KEY}.employee_id`, userData.employee_id)
    localStorage.setItem(`${USER_DATA_KEY}.permissions`, userData.permissions)
    localStorage.setItem(`${USER_DATA_KEY}.sc_root`, userData.sc_root)
    localStorage.setItem(`${USER_DATA_KEY}.tenant_name`, userData.tenant_name)
    localStorage.setItem(`${USER_DATA_KEY}.sharePointSaveEnabled`, userData.sharePointSaveEnabled)
    localStorage.setItem(`${USER_DATA_KEY}.sharePointContactsEnabled`, userData.sharePointContactsEnabled)
    localStorage.setItem(`${USER_DATA_KEY}.sharePointTemplatesEnabled`, userData.sharePointTemplatesEnabled)
    localStorage.setItem(`${USER_DATA_KEY}.token`, userData.token)
    localStorage.setItem(`${USER_DATA_KEY}.idToken`, userData.idToken)
    localStorage.setItem(`${USER_DATA_KEY}.accessToken`, userData.accessToken || '')
    localStorage.setItem(`${USER_DATA_KEY}.graphAccessToken`, userData.graphAccessToken || '')
  },
  deleteUserData () {
    localStorage.removeItem(`${USER_DATA_KEY}.email`)
    localStorage.removeItem(`${USER_DATA_KEY}.name`)
    localStorage.removeItem(`${USER_DATA_KEY}.employee_id`)
    localStorage.removeItem(`${USER_DATA_KEY}.permissions`)
    localStorage.removeItem(`${USER_DATA_KEY}.sc_root`)
    localStorage.removeItem(`${USER_DATA_KEY}.tenant_name`)
    localStorage.removeItem(`${USER_DATA_KEY}.sharePointSaveEnabled`)
    localStorage.removeItem(`${USER_DATA_KEY}.sharePointContactsEnabled`)
    localStorage.removeItem(`${USER_DATA_KEY}.sharePointTemplatesEnabled`)
    localStorage.removeItem(`${USER_DATA_KEY}.token`)
    localStorage.removeItem(`${USER_DATA_KEY}.idToken`)
    localStorage.removeItem(`${USER_DATA_KEY}.accessToken`)
    localStorage.removeItem(`${USER_DATA_KEY}.graphAccessToken`)
  },
  deleteTokens () {
    localStorage.removeItem(`${USER_DATA_KEY}.token`)
    localStorage.removeItem(`${USER_DATA_KEY}.idToken`)
    localStorage.removeItem(`${USER_DATA_KEY}.accessToken`)
    localStorage.removeItem(`${USER_DATA_KEY}.graphAccessToken`)
  },

  putUserAvatar (avatar) {
    localStorage.setItem(AVATAR_KEY, avatar)
  },
  getUserAvatar () {
    return localStorage.getItem(AVATAR_KEY)
  },

  // page settings
  getPageSettings (page) {
    if (page) {
      const value = localStorage.getItem(`${PAGES_KEY}.${page}`)
      try {
        return value ? JSON.parse(value) : {}
      } catch (e) {
        console.error('failed to parse settings for page', page)
      }
    }
    return {}
  },
  putPageSetting (page, setting) {
    const settings = { ...this.getPageSettings(page), ...setting }
    localStorage.setItem(`${PAGES_KEY}.${page}`, JSON.stringify(settings))
  },

  deleteUserAvatar () {
    return localStorage.removeItem(AVATAR_KEY)
  },

  // locale
  getLocale () {
    return localStorage.getItem(LOCALE_KEY)
  },
  putLocale (locale) {
    localStorage.setItem(LOCALE_KEY, locale)
  }
}

export default storage
