import { busy, notBusy } from '../busy/actions.js'
import {
  handleAddErrors,
  handleGetErrors,
  handleUpdateErrors,
  handleDeleteErrors
} from '../error/actions.js'
import { getAuthTokens } from '../common.js'
import storage from '../storage.js'

export const TICKET_CREATE_ACTION = 'TICKET_CREATE_ACTION'
export const TICKET_SHOW_ADD_DIALOG_ACTION = 'TICKET_SHOW_ADD_DIALOG_ACTION'
export const TICKET_HIDE_ADD_DIALOG_ACTION = 'TICKET_HIDE_ADD_DIALOG_ACTION'
export const TICKET_CHANGE_ORDERING_ACTION = 'TICKET_CHANGE_ORDERING_ACTION'
export const TICKET_GET_OK_ACTION = 'TICKET_GET_OK_ACTION'
export const TICKET_UPDATE_ACTION = 'TICKET_UPDATE_ACTION'
export const TICKET_SHOW_EDIT_DIALOG_ACTION = 'TICKET_SHOW_EDIT_DIALOG_ACTION'
export const TICKET_HIDE_EDIT_DIALOG_ACTION = 'TICKET_HIDE_EDIT_DIALOG_ACTION'
export const TICKET_DELETE_ACTION = 'TICKET_DELETE_ACTION'
export const TICKET_SHOW_DELETE_DIALOG_ACTION = 'TICKET_SHOW_DELETE_DIALOG_ACTION'
export const TICKET_HIDE_DELETE_DIALOG_ACTION = 'TICKET_HIDE_DELETE_DIALOG_ACTION'

export const TICKET_SET_ORDER_ACTION = 'TICKET_SET_ORDER_ACTION'
export const TICKET_SET_LIMIT_ACTION = 'TICKET_SET_LIMIT_ACTION'
export const TICKET_SET_OFFSET_ACTION = 'TICKET_SET_OFFSET_ACTION'
export const TICKET_SET_SEARCH_ACTION = 'TICKET_SET_SEARCH_ACTION'
export const TICKET_RESET_SEARCH_ACTION = 'TICKET_RESET_SEARCH_ACTION'
export const TICKET_SET_FILTER_QUERIES_ACTION = 'TICKET_SET_FILTER_QUERIES_ACTION'

export function ticketCreateAction (ticket) {
  return {
    type: TICKET_CREATE_ACTION,
    ticket
  }
}

export function ticketUpdateAction (ticket) {
  return {
    type: TICKET_UPDATE_ACTION,
    ticket
  }
}

export function ticketDeleteAction (ticket) {
  return {
    type: TICKET_DELETE_ACTION,
    ticket
  }
}

export function ticket (api, store) {
  function showDeleteTicketDialog (ticket) {
    return {
      type: TICKET_SHOW_DELETE_DIALOG_ACTION,
      ticket
    }
  }

  function hideDeleteTicketDialog () {
    return {
      type: TICKET_HIDE_DELETE_DIALOG_ACTION
    }
  }

  function setOrder (orderBy, order) {
    return {
      type: TICKET_SET_ORDER_ACTION,
      orderBy,
      order
    }
  }

  function setLimit (limit) {
    return {
      type: TICKET_SET_LIMIT_ACTION,
      limit
    }
  }

  /**
   * Set offset for pagination
   */
  function setOffset (offset) {
    return {
      type: TICKET_SET_OFFSET_ACTION,
      offset
    }
  }

  /**
   * Set search
   */
  function setSearch (search) {
    return {
      type: TICKET_SET_SEARCH_ACTION,
      search
    }
  }

  /**
   * Reset search
   */
  function resetSearch () {
    return {
      type: TICKET_RESET_SEARCH_ACTION
    }
  }

  /**
   * Set filter queries
   */
  function setFilterQueries (filterQueries) {
    storage.putPageSetting('ticket', { filterQueries })
    return {
      type: TICKET_SET_FILTER_QUERIES_ACTION,
      filterQueries
    }
  }

  /**
   * CREATE
   */
  function addTicket (ticket) {
    return function (dispatch) {
      dispatch(busy())
      api.addTicket(ticket, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleAddErrors('ticket', dispatch))
    }
  }

  /**
   * READ
   * Reads query params from state
   * But these are cached between views which might not be what you always want.
   * To get around that, this function supports custom query params.
  */
  function getTickets (params = {}) {
    const state = store.getState()
    const { search, limit, offset, orderBy, order, filterQueries } = state.ticket

    let query = []
    if (typeof search === 'string' && search.length > 0) {
      const split = search.split(',').map(s => s.trim()).filter(Boolean)
      if (split.length) {
        query.push({ key: 'name', value: split[0], op: '~' })
      }
    }
    const sort = orderBy ? `${orderBy}.${order}` : null

    if (Array.isArray(filterQueries) && filterQueries.length > 0) {
      query = [...query, ...filterQueries]
    }

    if (Object.keys(params).length === 0) {
      params = { query, limit, offset, sort }
    }

    return function (dispatch) {
      dispatch(busy())
      api.getTickets(params, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
        dispatch(receivedTickets(result.data.value))
      }).catch(handleGetErrors('ticket', dispatch))
    }
  }

  function receivedTickets (items) {
    return {
      type: TICKET_GET_OK_ACTION,
      items
    }
  }

  /**
   * UPDATE
   */
  function updateTicket (ticket) {
    return function (dispatch) {
      dispatch(busy())
      api.updateTicket(ticket, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleUpdateErrors('ticket', dispatch))
    }
  }

  /**
   * DELETE
   */
  function deleteTicket (ticket) {
    return function (dispatch) {
      dispatch(busy())
      api.deleteTicket(ticket, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleDeleteErrors('ticket', dispatch))
    }
  }

  return {
    showDeleteTicketDialog,
    hideDeleteTicketDialog,
    addTicket,
    getTickets,
    updateTicket,
    deleteTicket,
    receivedTickets,

    setFilterQueries,
    setOrder,
    setLimit,
    setOffset,
    setSearch,
    resetSearch
  }
}
