import { busy, notBusy } from '../busy/actions.js'
import { handleGetErrors } from '../error/actions.js'
import { getAuthTokens } from '../common.js'

export const PERMISSION_GET_OK_ACTION = 'PERMISSION_GET_OK_ACTION'

export function permission (api, store) {
  /**
   * READ
   */
  function getPermissions () {
    return function (dispatch) {
      dispatch(busy())
      api.getPermissions(getAuthTokens(store)).then(result => {
        dispatch(notBusy())
        dispatch(receivedPermissions(result.data.value))
      }).catch(handleGetErrors('permission', dispatch))
    }
  }

  function receivedPermissions (items) {
    return {
      type: PERMISSION_GET_OK_ACTION,
      items
    }
  }

  return { getPermissions }
}
