import React, { useState } from 'react'
import { connect } from 'react-redux'
import { styled, Group, Button, Modal, Input } from '@pergas-common/pergas-components'
import { Tag, Add, Close } from '@pergas-common/pergas-icons'

const Holder = styled.div`
  display: flex;
  height: 130px;
  flex-direction: column;
`

const SelectGroupHolder = styled.div`
  width: 100%;
  position: relative;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  > span {
    margin-left: 8px;
    ${({ theme }) => theme.typography.breadcrumb};
    color: ${({ theme }) => theme.palette.brand.secondary};
  }
  > div {
    background: #FFFFFF;
  }
`

const ContentHolder = styled.div`
  max-width: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  align-self: center;
`

const Label = styled.span`
  margin-bottom: 5px;
`

const AddFolder = ({
  isOpen,
  onSelect,
  onCloseRequest,
  strings
}) => {
  const [folderName, setFolderName] = useState('')

  return (
    <Modal
      title={strings.add_folder}
      onCloseRequest={onCloseRequest}
      titleIcon={<Tag width={20} height={20} />}
      isOpen={isOpen}
      size='md' footer={() => (
        <Group.Button>
          <Button onClick={() => onCloseRequest()}>
            <span>{strings.cancel}</span>
            <Close width={20} height={18} color='#28afe0' />
          </Button>
          <Button
            disabled={!folderName}
            onClick={() => onCloseRequest(folderName)}
          >
            <span>{strings.add}</span>
            <Add width={20} height={20} color='#28afe0' />
          </Button>
        </Group.Button>
      )}
    >
      {() => (
        <Holder>
          <ContentHolder>
            <SelectGroupHolder>
              <Label>{strings.name}</Label>
              <Input
                name='folder_name'
                value={folderName}
                onChangeValue={(e) => setFolderName(e.currentTarget.value)}
              />
            </SelectGroupHolder>
          </ContentHolder>
        </Holder>
      )}
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    strings: state.locale.strings
  }
}

export default connect(mapStateToProps)(AddFolder)
