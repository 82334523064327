import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { TablePagination } from '@mui/material'
import { Link, styled, Tag } from '@pergas-common/pergas-components'
import { Table, Td } from '../../pergas-components/index.js'
import {
  Add,
  Remove,
  Favorite,
  FavoriteFilled,
  OpenExternal
} from '@pergas-common/pergas-icons'
import PageToolbar from './PageToolbar.js'
import redux from '../../redux/index.js'
import SearchField from '../SearchField.js'
import DeleteItem from '../dialogs/DeleteItem.js'
import EditDepartment from '../dialogs/EditDepartment.js'
import { DepartmentIcon } from '../icons.js'
import {
  createDateAccessor,
  sharePointAccessor
} from './columns.js'
import { sortArray } from '../../util.js'
import { DEFAULT_OBJECT_TYPE_COLOR } from '../style.js'
import FileView from '../FileView.jsx'
import storage from '../../redux/storage.js'

const PageHolder = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
`

const TableHolder = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-grow: 1;
  flex-basis: 50px;
`

const NameHolder = styled.div`
  display: inline-block;
  margin: 0 8px;
`

const TagHolder = styled.span`
  margin-right: 8px;
`

const Spacer = styled.div`
  display: inline-block;
  margin: 2px;
`

const ROWS_PER_PAGE = [
  25,
  50,
  100,
  500,
  1000
]

const DepartmentPage = ({
  locale,
  rows,
  limit,
  offset,
  orderBy,
  order,
  search,
  resetSearch,
  getPageItems,
  setSelectedDepartmentId,
  setOrder,
  setLimit,
  setOffset,
  setSearch,
  fileSearchState,
  selectedDepartmentId,
  canUpdate,
  canDelete,
  deleteItem,
  onDeleteItemClick,
  onDeleteOk,
  onDeleteCancel,
  editItem,
  onEditItemClick,
  onEditOk,
  onEditCancel,
  canCreate,
  onShowAddDialog,
  onFavoriteItemClick,
  sharePointSaveEnabled,
  getRootFolder
}) => {
  const settings = storage.getPageSettings('department')

  const [showFileView, setShowFileView] = useState(settings.showFileView || false)
  function onToggleFileView () {
    storage.putPageSetting('department', { showFileView: !showFileView })
    setShowFileView(!showFileView)
  }

  function onSetFileViewHeight (height) {
    storage.putPageSetting('department', { fileViewHeight: height })
  }

  useEffect(getPageItems, [limit, offset, orderBy, order, search])

  const manualSort = useCallback(({ id, isSorted, isSortedDesc }) => {
    if (isSorted && !isSortedDesc) {
      setOrder(id, 'desc')
    } else if (isSorted && isSortedDesc) {
      setOrder('', '')
    } else {
      setOrder(id, 'asc')
    }
  }, [setOrder])

  const selectedRowIds = useMemo(() => {
    const index = rows.findIndex(item => item.id === selectedDepartmentId)
    if (index !== -1) {
      return { [String(index)]: true }
    } else {
      return {}
    }
  }, [rows, selectedDepartmentId])

  const columns = useMemo(() => {
    return [
      {
        Header: locale.name,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (
            <Td
              {...cell.getCellProps()} left={
                <>
                  <DepartmentIcon style={{ width: 16, height: 16 }} />
                  <NameHolder>
                    {!!original.name && <span>{original.name}</span>}
                  </NameHolder>
                </>
            }
            />
          )
        },
        canSort: true,
        id: 'name',
        size: 'md',
        manualSort,
        sortType: () => {}
      },
      {
        id: 'object_type_name',
        Header: locale.department_object_type,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return <Td {...cell.getCellProps()} center={original.object_type_name && <Tag color={original.object_type_color || DEFAULT_OBJECT_TYPE_COLOR} textColor={original.object_type_color ? '#FFFFFF' : '#3a4a54'} border='#969696'>{original.object_type_name}</Tag>} />
        },
        size: 'sm',
        canSort: true,
        sortType: () => {},
        manualSort
      },
      {
        id: 'tags_string',
        Header: locale.tags,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          const tags = original.tags && original.tags.length > 0 && sortArray(original.tags, 'name').slice(0, 2).map(({ color, id, name }) => {
            return <TagHolder key={id}><Tag textColor='#FFFFFF' color={color}>{name}</Tag></TagHolder>
          })
          return (<Td {...cell.getCellProps()} left={tags && tags} />)
        },
        canSort: true,
        sortType: () => {},
        manualSort
      },
      {
        id: 'updated_at',
        Header: locale.updated_at,
        manualSort,
        accessor: createDateAccessor('updated_at'),
        sortType: () => {}
      },
      {
        id: 'toolbar',
        Header: locale.tool_belt,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (
            <Td
              {...cell.getCellProps()}
              left={
                <>
                  {sharePointSaveEnabled && sharePointAccessor(original)}
                  <Spacer><Link onClickHandler={() => { onFavoriteItemClick(original) }}>{original.is_favorite ? <FavoriteFilled color='#28afe0' width={20} height={20} /> : <Favorite color='#28afe0' width={20} height={20} />}</Link></Spacer>
                  {canUpdate && <Spacer><Link onClickHandler={() => { onEditItemClick(original) }}><OpenExternal color='#28afe0' width={20} height={20} /></Link></Spacer>}
                </>
              }
              right={canDelete && <Link onClickHandler={() => { onDeleteItemClick(original) }}><Remove width={20} height={20} color='#FF5656' /></Link>}
            />
          )
        },
        size: 'sm',
        isSortable: false
      }
    ]
  }, [locale, canUpdate, canDelete, sharePointSaveEnabled, manualSort, onDeleteItemClick, onEditItemClick, onFavoriteItemClick])

  let initialSortBy = []
  if (orderBy) {
    initialSortBy = [{
      id: orderBy,
      desc: order === 'desc'
    }]
  }

  const selectedSiteItem = rows.find(i => i.id === selectedDepartmentId)

  return (
    <PageHolder>
      {editItem && <EditDepartment isEditing item={editItem} onOk={onEditOk} onCancel={onEditCancel} />}
      {deleteItem && <DeleteItem text={deleteItem.name} onOk={() => { onDeleteOk(deleteItem) }} onCancel={onDeleteCancel} />}
      <PageToolbar left={<><DepartmentIcon style={{ width: 20, height: 20 }} /><span>{locale.departments}</span> {canCreate && <Link onClickHandler={onShowAddDialog}><Add width={20} height={20} color='#28afe0' /></Link>}</>}>
        <SearchField resetSearch={resetSearch} value={search} onChange={setSearch} />
      </PageToolbar>
      <TableHolder>
        <Table
          columns={columns}
          data={rows}
          initialPageSize={limit}
          initialSortBy={initialSortBy}
          selectedRowIds={selectedRowIds}
          onRowClick={(e, row, toggleAllPageRowsSelected) => {
            getRootFolder(row.original)
            setSelectedDepartmentId(row.original.id)
            toggleAllPageRowsSelected(false)
            row.toggleRowSelected(true)
          }}
        >
          {({ setPageSize }) => (
            <TablePagination
              rowsPerPage={limit}
              rowsPerPageOptions={ROWS_PER_PAGE}
              count={-1}
              page={offset / limit}
              labelRowsPerPage={locale.rows_per_page}
              labelDisplayedRows={({ from, to }) => `${from}-${to}`}
              onRowsPerPageChange={(e) => {
                setLimit(e.target.value)
                setPageSize(e.target.value)
              }}
              onPageChange={(e, number) => setOffset(number * limit)}
            />
          )}
        </Table>
      </TableHolder>
      <FileView siteItem={selectedSiteItem} right={<></>} onShowToggle={onToggleFileView} isOpen={showFileView} initialHeight={settings.fileViewHeight} onSetHeight={onSetFileViewHeight} searchState={fileSearchState.department} />
    </PageHolder>
  )
}

const mapStateToProps = (state) => {
  const { locale, department, files } = state
  const permissions = state.login.permissions
  const {
    pageItems,
    limit,
    offset,
    orderBy,
    order,
    search
  } = department
  return {
    locale: locale.strings,
    rows: pageItems,
    limit,
    offset,
    orderBy,
    order,
    search,
    canUpdate: permissions.department.canUpdate,
    canDelete: permissions.department.canDelete,
    canCreate: permissions.department.canCreate,
    deleteItem: state.department.deleteItem,
    editItem: state.department.editItem,
    sharePointSaveEnabled: state.login.userData.sharePointSaveEnabled,
    fileSearchState: files.search,
    selectedDepartmentId: department.selectedItemId
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions: { department, file } } = redux
  return {
    onShowAddDialog: () => {
      dispatch(department.showAddDepartmentDialog())
    },

    onEditOk: (d) => {
      dispatch(department.hideEditDepartmentDialog())
      dispatch(department.updateDepartment(d))
    },
    onEditItemClick: (d) => {
      dispatch(department.showEditDepartmentDialog(d))
    },
    onEditCancel: () => {
      dispatch(department.hideEditDepartmentDialog())
    },

    onFavoriteItemClick: (d) => {
      dispatch(department.toggleFavorite(d))
    },

    onDeleteItemClick: (d) => {
      dispatch(department.showDeleteDepartmentDialog(d))
    },
    onDeleteOk: (d) => {
      dispatch(department.hideDeleteDepartmentDialog())
      dispatch(department.deleteDepartment(d))
    },
    onDeleteCancel: () => {
      dispatch(department.hideDeleteDepartmentDialog())
    },

    getPageItems: () => dispatch(department.getPageItems()),
    setOrder: (orderBy, order) => dispatch(department.setOrder(orderBy, order)),
    setLimit: (limit) => dispatch(department.setLimit(limit)),
    setOffset: (offset) => dispatch(department.setOffset(offset)),
    setSearch: (search) => dispatch(department.setSearch(search)),
    resetSearch: () => dispatch(department.resetSearch()),
    getRootFolder: (item) => {
      dispatch(file.getRootFolder(item))
    },
    setSelectedDepartmentId: (id) => {
      dispatch(department.setSelectedItemId(id))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentPage)
