import React from 'react'
import { createRoot } from 'react-dom/client'
import { theme, ThemeProvider } from '@pergas-common/pergas-components'
import { Provider } from 'react-redux'
import redux from './redux/index.js'
import App from './App'
import Modal from 'react-modal'

console.log('Using theme:', theme)
console.log('Default state:', redux.store.getState())
console.log('Version string:', process.env.REACT_APP_VERSION)

Modal.setAppElement('#root-modal')

// TODO move to pergas-components
theme.palette.brand.secondary = '#073A53'
theme.palette.table.primary = '#073A53'
theme.palette.table.tertiary = '#DAE1E5'

function Main () {
  return (
    <Provider store={redux.store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  )
}
const root = createRoot(document.getElementById('root'))
root.render(<Main />)
