import { busy, notBusy } from '../busy/actions.js'
import {
  handleAddErrors,
  handleGetErrors,
  handleUpdateErrors,
  handleDeleteErrors
} from '../error/actions.js'
import { getAuthTokens } from '../common.js'

export const TIME_ENTRY_GET_OK_ACTION = 'TIME_ENTRY_GET_OK_ACTION'
export const TIME_ENTRY_ADD_ROW_ACTION = 'TIME_ENTRY_ADD_ROW_ACTION'
export const TIME_ENTRY_DELETE_ACTION = 'TIME_ENTRY_DELETE_ACTION'
export const TIME_ENTRY_CREATE_ACTION = 'TIME_ENTRY_CREATE_ACTION'
export const TIME_ENTRY_SELECT_EMPLOYEE_OK_ACTION = 'TIME_ENTRY_SELECT_EMPLOYEE_OK_ACTION'
export const TIME_ENTRY_SET_ROW_PROJECT_OK_ACTION = 'TIME_ENTRY_SET_ROW_PROJECT_OK_ACTION'
export const TIME_ENTRY_SET_ROW_TICKET_OK_ACTION = 'TIME_ENTRY_SET_ROW_TICKET_OK_ACTION'
export const TIME_ENTRY_UPDATE_ACTION = 'TIME_ENTRY_UPDATE_ACTION'
export const TIME_ENTRY_SET_DATE_OK_ACTION = 'TIME_ENTRY_SET_DATE_OK_ACTION'

export function timeEntryCreateAction (data) {
  return {
    type: TIME_ENTRY_CREATE_ACTION,
    data
  }
}

export function timeEntryUpdateAction (data) {
  return {
    type: TIME_ENTRY_UPDATE_ACTION,
    data
  }
}

export function timeEntryDeleteAction (data) {
  return {
    type: TIME_ENTRY_DELETE_ACTION,
    data
  }
}

export function time (api, store) {
  function setDate (data) {
    return {
      type: TIME_ENTRY_SET_DATE_OK_ACTION,
      data
    }
  }

  function addNewTimeRow (employeeId, fullYear, weekNr) {
    return {
      type: TIME_ENTRY_ADD_ROW_ACTION,
      data: {
        id: 0,
        project_id: 0,
        ticket_id: 0,
        employee_id: employeeId,
        fullyear: fullYear,
        weeknr: weekNr,
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0,
        sat: 0,
        sun: 0
      }
    }
  }

  function selectTimeEntryEmployee (employeeId) {
    return {
      type: TIME_ENTRY_SELECT_EMPLOYEE_OK_ACTION,
      data: employeeId
    }
  }

  function setTimeEntryProject (projectId) {
    return {
      type: TIME_ENTRY_SET_ROW_PROJECT_OK_ACTION,
      data: projectId
    }
  }

  function setTimeEntryTicket (ticketId) {
    return {
      type: TIME_ENTRY_SET_ROW_TICKET_OK_ACTION,
      data: ticketId
    }
  }

  /**
   * CREATE
   */
  function addTimeEntry (entry) {
    return function (dispatch) {
      dispatch(busy())
      api.addTimeEntry(entry, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleAddErrors('timeEntry', dispatch))
    }
  }

  /**
   * READ
   */
  function getTimeEntries (fullYear, weekNr, employeeId) {
    return function (dispatch) {
      dispatch(busy())
      const query = [
        { key: 'fullyear', value: fullYear, op: '=' },
        { key: 'weeknr', value: weekNr, op: '=' },
        { key: 'employee_id', value: employeeId, op: '=' }
      ]
      api.getTimeEntries({ query }, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
        dispatch(receivedTimeEntries(result.data.value))
      }).catch(handleGetErrors('timeEntry', dispatch))
    }
  }

  function receivedTimeEntries (data) {
    return {
      type: TIME_ENTRY_GET_OK_ACTION,
      data
    }
  }

  /**
   * UPDATE
   */
  function updateTimeEntry (entry) {
    return function (dispatch) {
      dispatch(busy())
      api.updateTimeEntry(entry, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleUpdateErrors('timeEntry', dispatch))
    }
  }

  /**
   * DELETE
   */
  function deleteTimeEntry (entry) {
    if (entry.id === 0) {
      return timeEntryDeleteAction(entry)
    } else {
      return function (dispatch) {
        dispatch(busy())
        api.deleteTimeEntry(entry, getAuthTokens(store)).then(result => {
          dispatch(notBusy())
        }).catch(handleDeleteErrors('timeEntry', dispatch))
      }
    }
  }

  return {
    setDate,
    addNewTimeRow,
    selectTimeEntryEmployee,
    setTimeEntryProject,
    setTimeEntryTicket,
    addTimeEntry,
    getTimeEntries,
    updateTimeEntry,
    deleteTimeEntry
  }
}
