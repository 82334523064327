import { busy, notBusy } from '../busy/actions.js'
import {
  handleAddErrors,
  handleGetErrors,
  handleUpdateErrors,
  handleDeleteErrors
} from '../error/actions.js'
import { getAuthTokens } from '../common.js'
import { resetFileSearch } from '../files/actions.js'

export const DEPARTMENT_CREATE_ACTION = 'DEPARTMENT_CREATE_ACTION'
export const DEPARTMENT_SHOW_ADD_DIALOG_ACTION = 'DEPARTMENT_SHOW_ADD_DIALOG_ACTION'
export const DEPARTMENT_HIDE_ADD_DIALOG_ACTION = 'DEPARTMENT_HIDE_ADD_DIALOG_ACTION'
export const DEPARTMENT_CHANGE_ORDERING_ACTION = 'DEPARTMENT_CHANGE_ORDERING_ACTION'
export const DEPARTMENT_GET_OK_ACTION = 'DEPARTMENT_GET_OK_ACTION'
export const DEPARTMENT_UPDATE_ACTION = 'DEPARTMENT_UPDATE_ACTION'
export const DEPARTMENT_SHOW_EDIT_DIALOG_ACTION = 'DEPARTMENT_SHOW_EDIT_DIALOG_ACTION'
export const DEPARTMENT_HIDE_EDIT_DIALOG_ACTION = 'DEPARTMENT_HIDE_EDIT_DIALOG_ACTION'
export const DEPARTMENT_DELETE_ACTION = 'DEPARTMENT_DELETE_ACTION'
export const DEPARTMENT_SHOW_DELETE_DIALOG_ACTION = 'DEPARTMENT_SHOW_DELETE_DIALOG_ACTION'
export const DEPARTMENT_HIDE_DELETE_DIALOG_ACTION = 'DEPARTMENT_HIDE_DELETE_DIALOG_ACTION'

export const DEPARTMENT_GET_PAGE_ITEMS_ACTION = 'DEPARTMENT_GET_PAGE_ITEMS_ACTION'
export const DEPARTMENT_SET_ORDER_ACTION = 'DEPARTMENT_SET_ORDER_ACTION'
export const DEPARTMENT_SET_LIMIT_ACTION = 'DEPARTMENT_SET_LIMIT_ACTION'
export const DEPARTMENT_SET_OFFSET_ACTION = 'DEPARTMENT_SET_OFFSET_ACTION'
export const DEPARTMENT_SET_SEARCH_ACTION = 'DEPARTMENT_SET_SEARCH_ACTION'
export const DEPARTMENT_RESET_SEARCH_ACTION = 'DEPARTMENT_RESET_SEARCH_ACTION'
export const DEPARTMENT_SET_SELECTED_ITEM_ID = 'DEPARTMENT_SET_SELECTED_ITEM_ID'

export function departmentCreateAction (department) {
  return {
    type: DEPARTMENT_CREATE_ACTION,
    department
  }
}

export function departmentUpdateAction (department) {
  return {
    type: DEPARTMENT_UPDATE_ACTION,
    department
  }
}

export function departmentDeleteAction (department) {
  return {
    type: DEPARTMENT_DELETE_ACTION,
    department
  }
}

export function department (api, store) {
  function setSelectedItemId (selectedItemId) {
    return function (dispatch) {
      dispatch({
        type: DEPARTMENT_SET_SELECTED_ITEM_ID,
        selectedItemId
      })
      dispatch(resetFileSearch('department'))
    }
  }

  /**
   * Add dialog
   */
  function showAddDepartmentDialog () {
    return {
      type: DEPARTMENT_SHOW_ADD_DIALOG_ACTION
    }
  }

  function hideAddDepartmentDialog () {
    return {
      type: DEPARTMENT_HIDE_ADD_DIALOG_ACTION
    }
  }

  /**
   * Edit dialog
   */
  function showEditDepartmentDialog (department) {
    return {
      type: DEPARTMENT_SHOW_EDIT_DIALOG_ACTION,
      department
    }
  }

  function hideEditDepartmentDialog () {
    return {
      type: DEPARTMENT_HIDE_EDIT_DIALOG_ACTION
    }
  }

  /**
   * Delete dialog
   */
  function showDeleteDepartmentDialog (department) {
    return {
      type: DEPARTMENT_SHOW_DELETE_DIALOG_ACTION,
      department
    }
  }

  function hideDeleteDepartmentDialog () {
    return {
      type: DEPARTMENT_HIDE_DELETE_DIALOG_ACTION
    }
  }

  /**
   * CREATE
   */
  function addDepartment (department) {
    return function (dispatch) {
      dispatch(busy())
      api.addDepartment(department, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleAddErrors('department', dispatch))
    }
  }

  /**
   * READ
   */
  function getDepartments () {
    return function (dispatch) {
      dispatch(busy())
      api.getDepartments(getAuthTokens(store)).then(result => {
        dispatch(notBusy())
        dispatch(receivedDepartments(result.data.value))
      }).catch(handleGetErrors('department', dispatch))
    }
  }

  function receivedDepartments (items) {
    return {
      type: DEPARTMENT_GET_OK_ACTION,
      items
    }
  }

  /**
   * UPDATE
   */
  function updateDepartment (department) {
    return function (dispatch) {
      dispatch(busy())
      api.updateDepartment(department, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleUpdateErrors('department', dispatch))
    }
  }

  /**
   * DELETE
   */
  function deleteDepartment (department) {
    return function (dispatch) {
      dispatch(busy())
      api.deleteDepartment(department, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleDeleteErrors('department', dispatch))
    }
  }

  function toggleFavorite (department) {
    return function (dispatch) {
      const favorite = !department.is_favorite
      dispatch(busy())
      api.setDepartmentFavorite(department.id, favorite, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
      }).catch(handleUpdateErrors('department', dispatch))
    }
  }

  /**
   * Get items for a page
   */
  function getPageItems () {
    const state = store.getState()
    const { search, limit, offset, orderBy, order } = state.department

    const query = []
    if (typeof search === 'string' && search.length > 0) {
      const split = search.split(',').map(s => s.trim()).filter(Boolean)
      if (split.length) {
        query.push({ key: 'name', value: split[0], op: '~' })
      }
    }

    const sort = orderBy ? `${orderBy}.${order}` : null

    return function (dispatch) {
      dispatch(busy())
      api.getDepartments({
        query,
        limit,
        offset,
        sort
      }, getAuthTokens(store)).then(result => {
        dispatch(notBusy())
        dispatch({
          type: DEPARTMENT_GET_PAGE_ITEMS_ACTION,
          pageItems: result.data.value
        })
      }).catch(handleGetErrors('department', dispatch))
    }
  }

  /**
   * Sort order
   */
  function setOrder (orderBy, order) {
    return {
      type: DEPARTMENT_SET_ORDER_ACTION,
      orderBy,
      order
    }
  }

  /**
   * Set limit for pagination
   */
  function setLimit (limit) {
    return {
      type: DEPARTMENT_SET_LIMIT_ACTION,
      limit
    }
  }

  /**
   * Set offset for pagination
   */
  function setOffset (offset) {
    return {
      type: DEPARTMENT_SET_OFFSET_ACTION,
      offset
    }
  }

  /**
   * Set search
   */
  function setSearch (search) {
    return {
      type: DEPARTMENT_SET_SEARCH_ACTION,
      search
    }
  }

  /**
   * Reset search
   */
  function resetSearch () {
    return {
      type: DEPARTMENT_RESET_SEARCH_ACTION
    }
  }

  return {
    setSelectedItemId,

    showAddDepartmentDialog,
    hideAddDepartmentDialog,
    showEditDepartmentDialog,
    hideEditDepartmentDialog,
    showDeleteDepartmentDialog,
    hideDeleteDepartmentDialog,
    addDepartment,
    getDepartments,
    updateDepartment,
    deleteDepartment,
    toggleFavorite,

    getPageItems,
    setOrder,
    setLimit,
    setOffset,
    setSearch,
    resetSearch
  }
}
