import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useNavigate, Link as RLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import {
  styled,
  Grid,
  LinkStyle,
  Link,
  Waves
} from '@pergas-common/pergas-components'
import {
  Remove,
  CircularTick,
  OpenExternal
} from '@pergas-common/pergas-icons'
import { makeSelectorPermissionTicket } from 'domains/permission/selectors'
import { useDeleteTicketMutation, useGetTicketByProjectIdQuery } from 'domains/ticket/hooks'
import { DeleteItemV2 } from 'components/dialogs/DeleteItem'
import AsyncLoader from 'components/AsyncLoader'

const ReactRouterLink = styled(RLink)`
  ${LinkStyle};
  display: initial;
`

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Strikethrough = styled.span`
 text-decoration: ${({ isDone }) => isDone ? 'line-through' : 'none'};
`

export const TicketGrid = ({ projectId, currentTicket = null }) => {
  const navigate = useNavigate()
  const [deleteTicket, setDeleteTicket] = useState(null)
  const { data, refetch, isLoading } = useGetTicketByProjectIdQuery(projectId)
  const selectPermission = useMemo(makeSelectorPermissionTicket, [])
  const permission = useSelector(selectPermission)
  const { mutation: deleteMutation, isLoading: isDeleting } = useDeleteTicketMutation()

  const deleteAsyncLoader = <AsyncLoader loading={isDeleting}><Remove color='#b13a22' width={20} height={20} /></AsyncLoader>

  useEffect(() => {
    refetch()
  }, [projectId])

  if (!Array.isArray(data)) {
    return null
  }

  const rows = data.map((ticket) => {
    const {
      id,
      name,
      person_role: personRole,
      expires_at: expiresAt,
      ended_at: endedAt
    } = ticket
    const due = dayjs(expiresAt)
    const assigned = personRole?.find(({ role_id: _id }) => _id === 1)
    const current = currentTicket === id
    return (
      <Fragment key={id}>
        <Grid.Item><CircularTick width={18} height={18} /><Strikethrough isDone={endedAt}>{current ? !!name && name : <ReactRouterLink to={`/tickets/edit?id=${id}`}>{!!name && name}</ReactRouterLink>}</Strikethrough></Grid.Item>
        <Grid.Item>{assigned && assigned.person_name}</Grid.Item>
        <Grid.Item>{due.format('YYYY-MM-DD')}</Grid.Item>
        <Grid.Item actions>
          <span>{!current && permission.canUpdate && <Link onClickHandler={(e) => { e.stopPropagation(); navigate(`/tickets/edit?id=${id}`, { replace: true }) }}><OpenExternal color='#28afe0' width={20} height={20} /></Link>}</span>
          <span>{!current && permission.canDelete && <Link onClickHandler={(e) => { e.stopPropagation(); setDeleteTicket(ticket) }}>{deleteAsyncLoader}</Link>}</span>
        </Grid.Item>
      </Fragment>
    )
  })

  if (isLoading) {
    return <Center><Waves width={28} height={28} /></Center>
  }

  return (
    <>
      {!!deleteTicket && <DeleteItemV2
        text={deleteTicket.name}
        isOpen={!!deleteTicket}
        onCloseRequest={() => {
          setDeleteTicket(null)
        }}
        onSubmit={() => {
          deleteMutation(deleteTicket)
          setDeleteTicket(null)
        }}
                         />}

      <Grid.Content columns='4fr 2fr 2fr 1fr'>{rows}</Grid.Content>
    </>
  )
}
