import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import redux from '../redux/index.js'
import { ERROR_TYPE_LOGIN } from '../redux/login/actions.js'
import { MicrosoftIcon, PdsLogo } from './icons.js'
import { AUTO_LOGIN_TIMEOUT } from '../config.js'

const Login = ({
  strings,
  autoLogin,
  isLoggingIn,
  showLogout,
  doLogin,
  doLogout
}) => {
  useEffect(() => {
    if (autoLogin && !isLoggingIn && !showLogout) {
      console.info('auto login!')
      setTimeout(doLogin, AUTO_LOGIN_TIMEOUT)
    }
  }, [autoLogin, isLoggingIn, showLogout])

  const renderButton = () => {
    if (isLoggingIn) {
      return (
        <div style={loginButtonStyle(false)}>
          <MicrosoftIcon style={msIconStyle} />
          <div style={loginWithMsStyle}>{strings.is_logging_in}</div>
        </div>
      )
    } else if (showLogout) {
      return (
        <div style={loginButtonStyle(true)} onClick={doLogout}>
          <MicrosoftIcon style={msIconStyle} />
          <div style={loginWithMsStyle}>{strings.logout}</div>
        </div>
      )
    } else {
      return (
        <div style={loginButtonStyle(true)} onClick={doLogin}>
          <MicrosoftIcon style={msIconStyle} />
          <div style={loginWithMsStyle}>{strings.login_with_microsoft}</div>
        </div>
      )
    }
  }

  return (
    <div style={loginPageStyle}>
      <div style={middleStyle}>
        <div style={loginAreaStyle}>
          <div style={pdsDivStyle}>
            <PdsLogo width={60} height={60} />
            <div style={pdsCMStyle}>PDS CONTACT MANAGER</div>
          </div>
          {renderButton()}
          <div style={explainLoginStyle}>
            {strings.explainlogin}
          </div>
        </div>
      </div>
    </div>
  )
}

const loginPageStyle = {
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center'
}

const middleStyle = {
  display: 'flex',
  alignItems: 'center'
}

const loginAreaStyle = {
  border: '1px dashed #206a85',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '50px'
}

const pdsDivStyle = {
  display: 'flex',
  alignItems: 'center'
}

const pdsCMStyle = {
  fontSize: '20px',
  color: '#3a4a54'
}

const loginButtonStyle = (clickable) => {
  return {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    color: '#3a4a54',
    textTransform: 'none',
    fontFamily: 'Helvetica',
    fontSize: '14px',
    letterSpacing: '0.025em',
    lineHeight: '11px',
    borderRadius: '0',
    border: '0.5px solid #3a4a54',
    cursor: clickable ? 'pointer' : 'inherit',
    padding: '10px 15px 8px 15px',
    marginTop: '50px'
  }
}

const msIconStyle = {
  width: '36px',
  height: '36px'
}

const loginWithMsStyle = {
  marginLeft: '10px'
}

const explainLoginStyle = {
  borderTop: '0.3px solid #707070',
  padding: '10px',
  fontSize: '10px',
  color: '#3a4a54',
  marginTop: '50px'
}

const mapStateToProps = (state) => {
  const { userData, isLoggingIn } = state.login
  const loginHint = userData?.email
  const error = state.error
  const showLogout = error && error.errorType === ERROR_TYPE_LOGIN
  return {
    strings: state.locale.strings,
    autoLogin: typeof loginHint === 'string',
    isLoggingIn,
    showLogout
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    doLogin: () => {
      dispatch(redux.actions.login.doLogin())
    },
    doLogout: () => {
      dispatch(redux.actions.login.doLogout())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
