import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled, media, spacing, Avatar, Modal, Button, Link, Group, Topbar } from '@pergas-common/pergas-components'
import { WarningOutlined } from '@mui/icons-material'
import LightTooltip from './Tooltip.js'
import redux from '../redux/index.js'
import { selectLocale } from '../redux/locale/selectors'
import { Info, Lock } from '@pergas-common/pergas-icons'
import { PdsLogo } from './icons.js'
import {
  makeSelectorByAvatar,
  makeSelectorByName,
  makeSelectorByRemainingTime,
  makeSelectorByTenantName
} from '../redux/login/selectors.js'

const Holder = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.palette.brand.primary};
`
const ConfirmLogoutBody = styled.div`
  height: 50px;
  padding: 16px;
  > span {
    ${({ theme }) => theme.typography.body};
    color: ${({ theme }) => theme.palette.brand.secondary};
  }
`

const BrandText = styled.span`
  display: none;
  ${media.medium`
    display: initial;
    margin: 0 ${spacing(1)};
    ${({ theme }) => theme.typography.subTitle};
    text-transform: uppercase;
  `}
`

const RightSideHolder = styled.div`
  display: inline-flex;
  align-items: center;
  * {
    margin: 0 ${spacing(0.5)};
  }
  > p {
    margin: 0;
    display: flex;
  }
  > span {
    ${({ theme }) => theme.typography.body};
    color: ${({ theme }) => theme.palette.common.primary};
    display: none;
    ${media.medium`
      display: inherit;
    `}
  }
`

const infoStyle = (minutesLeft) => {
  let color = 'lightgreen'

  if (minutesLeft < 0) {
    color = 'lightgreen'
  } else if (minutesLeft < 2) {
    color = 'red'
  } else if (minutesLeft < 5) {
    color = 'darkorange'
  } else if (minutesLeft < 10) {
    color = 'yellow'
  } else {
    color = 'lightgreen'
  }
  return color
}
const appVersionFull = process.env.REACT_APP_VERSION

const ConfirmLogout = ({ isOpen, onSubmit, onCancel }) => {
  const locale = useSelector(selectLocale)
  return (
    <Modal
      title={locale.logout} titleIcon={<WarningOutlined style={{ width: '20px', height: '20px' }} />} isOpen={isOpen} onCloseRequest={onCancel} size='md' footer={() => (
        <Group.Button>
          <Button onClick={onSubmit}>{locale.logout}</Button>
          <Button onClick={onCancel}>{locale.cancel}</Button>
        </Group.Button>
      )}
    >
      {() => (
        <ConfirmLogoutBody><span>{locale.logout_confirm}</span></ConfirmLogoutBody>
      )}
    </Modal>
  )
}

const AppVersion = () => {
  const selectRemainingTime = useMemo(makeSelectorByRemainingTime, [])
  const selectTenantName = useMemo(makeSelectorByTenantName, [])
  const locale = useSelector(selectLocale)
  const remainingTime = useSelector(selectRemainingTime)
  const tenantName = useSelector(selectTenantName)

  const appVersionSplit = appVersionFull.split('-')
  const appVersion = appVersionSplit.length > 0 ? appVersionSplit[0] : 'N/A'
  const build = appVersionSplit.length > 1 ? appVersionSplit[1] : null
  const hash = appVersionSplit.length > 2 ? appVersionSplit[2].substring(1) : null
  const str = remainingTime > 0 ? Math.floor(remainingTime) : '...'

  return (
    <>
      <div>{locale.version}: {appVersion}</div>
      <div>{build ? `Build: ${build}` : null}</div>
      <div>{hash ? `Git: ${hash}` : null}</div>
      <div>{`${locale.tenant_name}: ${tenantName}`}</div>
      <div>{locale.relogin_in}: {str} {locale.minutes}</div>
    </>
  )
}

export default function TopBar () {
  const dispatch = useDispatch()
  const selectAvatar = useMemo(makeSelectorByAvatar, [])
  const selectName = useMemo(makeSelectorByName, [])
  const selectRemainingTime = useMemo(makeSelectorByRemainingTime, [])

  const locale = useSelector(selectLocale)
  const avatar = useSelector(selectAvatar)
  const name = useSelector(selectName)
  const remainingTime = useSelector(selectRemainingTime)
  const [isConfirmLogoutOpen, setIsConfirmLogoutOpen] = useState(false)

  const brand = (
    <>
      <PdsLogo width={32} height={32} />
      <BrandText>Contact Manager</BrandText>
    </>
  )

  const signout = (
    <RightSideHolder>
      <Avatar src={avatar} />
      <span>{name}</span>
      <LightTooltip title={<AppVersion />}>
        <span><Info width={24} height={24} color={infoStyle(remainingTime)} /></span>
      </LightTooltip>
      <LightTooltip title={locale.logout}>
        <p>
          <Link onClickHandler={() => {
            setIsConfirmLogoutOpen(true)
          }}
          >
            <Lock width={24} height={24} color='#FFFFFF' />
          </Link>
        </p>
      </LightTooltip>
    </RightSideHolder>
  )

  return (
    <>
      {isConfirmLogoutOpen && <ConfirmLogout
        isOpen={isConfirmLogoutOpen} onSubmit={() => {
          dispatch(redux.actions.login.doLogout())
          setIsConfirmLogoutOpen(false)
        }} onCancel={() => {
          setIsConfirmLogoutOpen(false)
        }}
                              />}
      <Holder>
        <Topbar left={brand} right={signout} />
      </Holder>
    </>
  )
}
