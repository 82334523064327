import React, { useEffect, useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import PageToolbar from './PageToolbar.js'
import redux from '../../redux/index.js'
import SearchField from '../SearchField.js'
import DeleteItem from '../dialogs/DeleteItem.js'
import { TablePagination } from '@mui/material'
import {
  Table,
  Link,
  styled,
  TD as Td
} from '@pergas-common/pergas-components'
import {
  Add,
  Remove,
  OpenExternal
} from '@pergas-common/pergas-icons'
import { createDateAccessor } from './columns.js'
import EditRole from '../dialogs/EditRole.js'
import { RoleIcon } from '../icons.js'

const TableHolder = styled.div`
  display: flex;
  position: absolute;
  top: 100px;
  bottom: 0;
  overflow-y: scroll;
`

const NameHolder = styled.div`
  display: inline-block;
  margin: 0 8px;
`

const Spacer = styled.div`
  display: inline-block;
  margin: 2px;
`

const ROWS_PER_PAGE = [
  25,
  50,
  100,
  500,
  1000
]

const RolePage = ({
  locale,
  rows,
  limit,
  offset,
  orderBy,
  order,
  search,
  resetSearch,
  getPageItems,
  setOrder,
  setLimit,
  setOffset,
  setSearch,
  canUpdate,
  canDelete,
  deleteItem,
  onDeleteItemClick,
  onDeleteOk,
  onDeleteCancel,
  editItem,
  onEditItemClick,
  onEditOk,
  onEditCancel,
  canCreate,
  onShowAddDialog
}) => {
  useEffect(getPageItems, [limit, offset, orderBy, order, search])

  const manualSort = useCallback(({ id, isSorted, isSortedDesc }) => {
    if (isSorted && !isSortedDesc) {
      setOrder(id, 'desc')
    } else if (isSorted && isSortedDesc) {
      setOrder('', '')
    } else {
      setOrder(id, 'asc')
    }
  }, [setOrder])

  const columns = useMemo(() => {
    return [
      {
        Header: locale.name,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (
            <Td
              {...cell.getCellProps()} left={
                <>
                  <RoleIcon style={{ width: 16, height: 16 }} />
                  <NameHolder>
                    {!!original.name && <span>{original.name}</span>}
                  </NameHolder>
                </>
            }
            />
          )
        },
        canSort: true,
        id: 'name',
        size: 'md',
        manualSort,
        sortType: () => {}
      },
      {
        Header: locale.description,
        manualSort,
        accessor: 'description',
        sortType: () => {}
      },
      {
        id: 'updated_at',
        Header: locale.updated_at,
        manualSort,
        accessor: createDateAccessor('updated_at'),
        sortType: () => {}
      },
      {
        id: 'toolbar',
        Header: locale.tool_belt,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (
            <Td
              {...cell.getCellProps()}
              left={
                <>
                  {canUpdate && <Spacer><Link onClickHandler={() => { onEditItemClick(original) }}><OpenExternal color='#28afe0' width={20} height={20} /></Link></Spacer>}
                </>
              }
              right={canDelete && !original.read_only && <Link onClickHandler={() => { onDeleteItemClick(original) }}><Remove width={20} height={20} color='#FF5656' /></Link>}
            />
          )
        },
        size: 'sm',
        isSortable: false
      }
    ]
  }, [locale, canUpdate, canDelete, manualSort, onDeleteItemClick, onEditItemClick])

  let initialSortBy = []
  if (orderBy) {
    initialSortBy = [{
      id: orderBy,
      desc: order === 'desc'
    }]
  }

  return (
    <>
      {editItem && <EditRole isEditing item={editItem} onOk={onEditOk} onCancel={onEditCancel} />}
      {deleteItem && <DeleteItem text={deleteItem.name || deleteItem.first_name} onOk={() => { onDeleteOk(deleteItem) }} onCancel={onDeleteCancel} />}
      <PageToolbar left={<><RoleIcon style={{ width: 20, height: 20 }} /> <span>{locale.roles}</span>{canCreate && <Link onClickHandler={onShowAddDialog}><Add width={20} height={20} color='#28afe0' /></Link>}</>}>
        <SearchField resetSearch={resetSearch} value={search} onChange={setSearch} />
      </PageToolbar>
      <TableHolder>
        <Table columns={columns} data={rows} initialPageSize={limit} initialSortBy={initialSortBy}>
          {({ setPageSize }) => (
            <TablePagination
              rowsPerPage={limit}
              rowsPerPageOptions={ROWS_PER_PAGE}
              count={-1}
              page={offset / limit}
              labelRowsPerPage={locale.rows_per_page}
              labelDisplayedRows={({ from, to }) => `${from}-${to}`}
              onRowsPerPageChange={(e) => {
                setLimit(e.target.value)
                setPageSize(e.target.value)
              }}
              onPageChange={(e, number) => setOffset(number * limit)}
            />
          )}
        </Table>
      </TableHolder>
    </>
  )
}

const mapStateToProps = (state) => {
  const { locale, role } = state
  const permissions = state.login.permissions
  const {
    pageItems,
    limit,
    offset,
    orderBy,
    order,
    search
  } = role
  return {
    locale: locale.strings,
    rows: pageItems,
    limit,
    offset,
    orderBy,
    order,
    search,
    canUpdate: permissions.role.canUpdate,
    canDelete: permissions.role.canDelete,
    canCreate: permissions.role.canCreate,
    deleteItem: state.role.deleteItem,
    editItem: state.role.editItem
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions: { role } } = redux
  return {
    onShowAddDialog: () => {
      dispatch(role.showAddRoleDialog())
    },

    onEditOk: (r) => {
      dispatch(role.hideEditRoleDialog())
      dispatch(role.updateRole(r))
    },
    onEditItemClick: (r) => {
      dispatch(role.showEditRoleDialog(r))
    },
    onEditCancel: () => {
      dispatch(role.hideEditRoleDialog())
    },

    onDeleteOk: (r) => {
      dispatch(role.hideDeleteRoleDialog())
      dispatch(role.deleteRole(r))
    },
    onDeleteItemClick: (r) => {
      dispatch(role.showDeleteRoleDialog(r))
    },
    onDeleteCancel: () => {
      dispatch(role.hideDeleteRoleDialog())
    },

    getPageItems: () => dispatch(role.getPageItems()),
    setOrder: (orderBy, order) => dispatch(role.setOrder(orderBy, order)),
    setLimit: (limit) => dispatch(role.setLimit(limit)),
    setOffset: (offset) => dispatch(role.setOffset(offset)),
    setSearch: (search) => dispatch(role.setSearch(search)),
    resetSearch: () => dispatch(role.resetSearch())

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RolePage)
