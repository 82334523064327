import './App.css'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import WorkSpace from './components/WorkSpace.js'
import Login from './components/Login.js'
import Feedback from './components/Feedback.js'
import redux from './redux/index.js'
import socket from './redux/socket-io.js'
import { isValidToken } from './util.js'

const App = ({
  strings,
  isLoggedIn,
  token,
  error,
  onResetError,
  connectWebsockets,
  doStartHeartbeat,
  doLogin
}) => {
  // Reconnect on websockets if token changes.
  useEffect(() => {
    if (isLoggedIn) {
      connectWebsockets(token)
    }
  }, [token])

  // Start heartbeat on initial render.
  useEffect(doStartHeartbeat, [])

  const renderError = () => {
    const errorMessage = () => {
      if (error) {
        console.error(error)
        const { errorType, errorMessage } = error
        const translatedErrorType = strings[errorType] || 'N/A'
        if (errorMessage) {
          const translatedErrorMessage = strings[errorMessage] || errorMessage
          return `${translatedErrorType} (${translatedErrorMessage})`
        } else {
          return translatedErrorType
        }
      } else {
        return ''
      }
    }
    return (
      <Feedback
        open={!!error}
        message={errorMessage()}
        variant='error'
        closeTimeout={10000}
        onAutoClose={onResetError}
      />
    )
  }

  return (
    <>
      {renderError()}
      {isLoggedIn ? <WorkSpace /> : <Login />}
    </>
  )
}

const mapStateToProps = (state) => {
  const { idToken, token } = state.login.userData
  const isLoggedIn = (isValidToken(idToken) && isValidToken(token))
  return {
    strings: state.locale.strings,
    isLoggedIn,
    token,
    error: state.error
  }
}

const mapDispatchToProps = (dispatch) => {
  const { resetError } = redux.actions.error
  return {
    onResetError: () => {
      dispatch(resetError())
    },
    connectWebsockets: (token) => {
      socket.connect(token, dispatch)
    },
    doLogin: () => {
      dispatch(redux.actions.login.doLogin())
    },
    doStartHeartbeat: () => {
      dispatch(redux.actions.login.doStartHeartbeat())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
