import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { api } from 'domains'
import {
  styled,
  media,
  Card,
  Layout,
  Link,
  Topbar
} from '@pergas-common/pergas-components'
import {
  Add,
  Teams,
  Company
} from '@pergas-common/pergas-icons'
import { selectLocale } from 'domains/locale/selectors'
import { makeSelectorEidById } from 'domains/project/selectors'
import { makeSelectorPermissionTicket } from 'domains/permission/selectors'
import { AddTagV2 } from 'components/dialogs/AddTag'
import { PersonRoleGrid } from 'components/PersonRoleGrid'
import { TicketGrid } from 'components/TicketGrid'
import { AddPersonRoleV2 } from 'components/dialogs/AddPersonRole'
import useRequest from 'hooks/useRequest'
import Divider from 'components/Divider'
import { Select, Input, Textarea, InputGroup } from 'components/Input'

import { TagList } from 'components/Tags'
import useDebounce from 'hooks/useDebounce'
import useUrlQuery from 'hooks/useUrlQuery'
import { itemsWithEmail } from '../../Input'

const RESPONSIBLE = 'responsible'

const Holder = styled.div`
  height: calc(100% + 600px);
  overflow-y: auto;
  background: rgb(247, 249, 250);
`

const Overflow = styled.div`
  overflow-y: hidden;
  height: 218px;
`

const TagHolder = styled.div`
  width: 100%;
  > div {
    padding: 0px 6px 0 0;
  }
  ${media.medium`
    width: 92%;
  `}
  height: 268px;
`

const Background = styled.div`
  background: ${({ theme }) => theme.palette.brand.secondary};
`
const Name = styled.span`
  ${({ theme }) => theme.typography.body};
  color: ${({ theme }) => theme.palette.common.primary};
`

const REQUEST_PARAMS = {
  limit: 50,
  sort: 'name.asc'
}

const OBJECT_TYPE_REQUEST_PARAMS = {
  query: [{ key: 'object_type_type', op: '=', value: 'project' }],
  ...REQUEST_PARAMS
}

export default ({ disable, values, setFieldValue, handleChange, handleSubmit, children, original }) => {
  const query = useUrlQuery()
  const id = query.get('id')
  const locale = useSelector(selectLocale)
  const selectTicketPermissions = useMemo(makeSelectorPermissionTicket, [])
  const ticketPermissions = useSelector(selectTicketPermissions)

  const navigate = useNavigate()

  const [isTagDialogOpen, setIsTagDialogOpen] = useState(false)
  const [isCreatePersonRoleOpen, setIsCreatePersonRoleOpen] = useState(false)
  const [getContacts, contacts, contactStatus] = useRequest(api.getContacts, 'contact')
  const [debouncedContactsRequest] = useDebounce(getContacts, 300)

  const [getObjectTypes, objectTypes, objectTypesStatus] = useRequest(api.getObjectTypes, 'objectType')
  const [debouncedObjectTypesRequest] = useDebounce(getObjectTypes, 300)
  const [getRoles, roles] = useRequest(api.getRoles, 'roles')

  const [getEmployees, employees, employeeStatus] = useRequest(api.getEmployees, 'employee')
  const [debouncedEmployeesRequest] = useDebounce(getEmployees, 300)

  const isFetchingContact = contactStatus === 'pending'
  const isFetchingObjectTypes = objectTypesStatus === 'pending'
  const isFetchingEmployees = employeeStatus === 'pending'

  useEffect(() => {
    getContacts(REQUEST_PARAMS)
  }, [getContacts])

  useEffect(() => {
    getObjectTypes(OBJECT_TYPE_REQUEST_PARAMS)
  }, [getObjectTypes])

  useEffect(() => {
    getEmployees(REQUEST_PARAMS)
  }, [getEmployees])

  useEffect(() => {
    getRoles(REQUEST_PARAMS)
  }, [getRoles])

  const selectorProjectEidById = useMemo(makeSelectorEidById, [])
  const eId = useSelector((state) => selectorProjectEidById(state, id))

  const responsibleRole = values.person_role?.find((pr) => pr.role_internal_name === RESPONSIBLE)
  const defaultResponsible = responsibleRole && { name: responsibleRole.person_name, id: responsibleRole.person_id }
  const defaultObjectType = { name: values.object_type_name || '', id: values.object_type_id || 0 }
  const defaultCollection = { name: values.collection_name || '', id: values.collection_id || 0 } // eslint-disable-line camelcase
  const onTagSelect = useCallback((selected) => {
    const tag = { ...selected, tag_id: selected.id }
    if (values.tags == null) {
      setFieldValue('tags', [tag])
      return
    }
    const tagAlreadyExist = values.tags.some(({ tag_id: tagId }) => tagId === selected.id)
    if (!tagAlreadyExist) {
      setFieldValue('tags', [tag, ...values.tags])
    }
  }, [setFieldValue, values.tags])

  const onResponsible = useCallback((_, __, selected) => {
    const roleExists = roles?.find((r) => r.id === 1)
    const withoutPersonRole = values.person_role?.filter((pr) => pr.role_id !== 1) ?? []

    if (roleExists) {
      const mergedRoles = [...withoutPersonRole, {
        person_id: selected.id,
        person_name: selected.name,
        role_id: roleExists.id,
        role_name: roleExists.name,
        role_internal_name: roleExists.internal_name
      }]
      setFieldValue('person_role', mergedRoles)
    }
  }, [roles, setFieldValue, values.person_role])

  return (
    <>
      <Background>
        <Topbar left={<Name>{values.name}</Name>} />
      </Background>
      <Holder>
        <Layout.Main>
          <Layout.Aside>
            <form onSubmit={handleSubmit} id='submitProject'>
              {isTagDialogOpen && <AddTagV2
                tagType='project'
                isOpen={isTagDialogOpen}
                onCloseRequest={() => {
                  setIsTagDialogOpen(false)
                }}
                onSelect={onTagSelect}
                                  />}
              <InputGroup>
                <Input name='x_id' label={locale.id} disabled value={id} handleChange={() => {}} />
                <Input name='x_eid' label={locale.external_id} disabled value={eId} handleChange={() => {}} />
              </InputGroup>
              <Divider />
              <Input name='name' label={locale.project} disabled={disable} value={values.name} handleChange={handleChange} />
              <Textarea name='description' label={locale.description} disabled={disable} value={values.description} handleChange={handleChange} />
              <Divider />
              <Input name='started_at' type='date' label={locale.started_at} disabled={disable} value={values.started_at} handleChange={handleChange} />
              <Input name='expires_at' type='date' label={locale.expires_at} disabled={disable} value={values.expires_at} handleChange={handleChange} />
              <Divider />
              <Input name='microsoft_teams' disabled value={values.teams_url}>
                <Link onClickHandler={(e) => e.stopPropagation()} href={values.teams_url || ''}><Teams width={18} height={18} /></Link>
              </Input>
              <Divider />
              <Select
                name='collection_id'
                label={locale.contact}
                disabled={disable}
                defaultValue={defaultCollection}
                request={(input) => {
                  debouncedContactsRequest({ query: [{ key: 'name', op: '~', value: input }], sort: 'name.asc', limit: 50 })
                }}
                requestStatus={isFetchingContact}
                items={contacts}
                handleChange={(_, id, item) => {
                  setFieldValue('collection_id', id)
                  setFieldValue('collection_name', item.name)
                }}
              >
                <Company
                  onClick={() => {
                    if (values.collection_id) {
                      navigate(`/contacts/edit?id=${values.collection_id}`)
                    }
                  }} color='#28afe0' width={18} height={18}
                />
              </Select>

              <Select
                name='responsible_person_role'
                label={locale.project_responsible}
                disabled={disable}
                defaultValue={defaultResponsible}
                request={(input) => {
                  debouncedEmployeesRequest({ query: [{ key: 'name', op: '~', value: input }], sort: 'name.asc', limit: 50 })
                }}
                requestStatus={isFetchingEmployees}
                items={itemsWithEmail(employees)}
                handleChange={onResponsible}
              />
              <Select
                name='object_type_id'
                label={locale.project_object_type}
                disabled={disable}
                defaultValue={defaultObjectType}
                request={(input) => {
                  debouncedObjectTypesRequest({
                    query: [
                      { key: 'object_type_type', op: '=', value: 'project' },
                      { key: 'name', op: '~', value: input }
                    ],
                    limit: 50
                  })
                }}
                requestStatus={isFetchingObjectTypes}
                items={objectTypes}
                handleChange={setFieldValue}
              />
              <TagHolder>
                <Card.Content>
                  <Card.Header title={locale.tags}>
                    {!disable && (
                      <Link onClickHandler={() => { setIsTagDialogOpen(true) }}>
                        <Add color='#28afe0' width={20} height={20} />
                      </Link>
                    )}
                  </Card.Header>
                  <Overflow>
                    <TagList name='tags' disabled={disable} tags={values.tags} handleChange={setFieldValue} />
                  </Overflow>
                </Card.Content>
              </TagHolder>
            </form>
          </Layout.Aside>
          <Layout.Section>
            {children}
            <Card.Content>
              <Card.Header title={locale.project_participants} columns='2fr 2fr 1fr 2fr' extra={[locale.project_role]}>
                {!disable && (
                  <Link onClickHandler={() => { setIsCreatePersonRoleOpen(true) }}>
                    <Add color='#28afe0' width={20} height={20} />
                  </Link>
                )}
              </Card.Header>
              <Overflow>
                <PersonRoleGrid>
                  {isCreatePersonRoleOpen && (
                    <AddPersonRoleV2
                      isOpen={isCreatePersonRoleOpen}
                      onCloseRequest={() => {
                        setIsCreatePersonRoleOpen(false)
                      }}
                    />
                  )}
                </PersonRoleGrid>
              </Overflow>
            </Card.Content>
            <Card.Content>
              <Card.Header title={locale.project_tasks} columns='4fr 2fr 2fr 1fr' extra={[locale.assigned, locale.should_be_finished_by]}>
                {ticketPermissions.canCreate && (
                  <Link onClickHandler={() => { navigate('/tickets/new', { state: { project: { ...original } } }) }}>
                    <Add color='#28afe0' width={20} height={20} />
                  </Link>
                )}
              </Card.Header>
              <Overflow>
                <TicketGrid projectId={id} />
              </Overflow>
            </Card.Content>
          </Layout.Section>
        </Layout.Main>
      </Holder>
    </>
  )
}
