import { SET_APP_PATH_ACTION } from './actions.js'

export function appPath (state = window.location.pathname, { type, path }) { // eslint-disable-line
  switch (type) {
    case SET_APP_PATH_ACTION:
      return path
    default:
      return state
  }
}
