import {
  TIME_ENTRY_GET_OK_ACTION,
  TIME_ENTRY_ADD_ROW_ACTION,
  TIME_ENTRY_DELETE_ACTION,
  TIME_ENTRY_CREATE_ACTION,
  TIME_ENTRY_SELECT_EMPLOYEE_OK_ACTION,
  TIME_ENTRY_SET_ROW_PROJECT_OK_ACTION,
  TIME_ENTRY_SET_ROW_TICKET_OK_ACTION,
  TIME_ENTRY_UPDATE_ACTION,
  TIME_ENTRY_SET_DATE_OK_ACTION
} from './actions.js'

const TIME_ENTRY_DEFAULT = {
  date: new Date(),
  employee_id: 0,
  items: []
}

/**
 * Reducer for handling time entries
 */
export function time (state = TIME_ENTRY_DEFAULT, action) { // eslint-disable-line
  switch (action.type) {
    case TIME_ENTRY_GET_OK_ACTION:
      return Object.assign({}, state, {
        items: action.data.slice()
      })
    case TIME_ENTRY_ADD_ROW_ACTION:
      return Object.assign({}, state, {
        items: state.items.concat(action.data)
      })
    case TIME_ENTRY_DELETE_ACTION:
      return Object.assign({}, state, {
        items: state.items.filter(r => {
          return r.id !== action.data.id
        })
      })
    case TIME_ENTRY_CREATE_ACTION:
      return Object.assign({}, state, {
        items: state.items.filter(r => {
          return r.id !== 0
        }).concat(action.data)
      })
    case TIME_ENTRY_SELECT_EMPLOYEE_OK_ACTION:
      return Object.assign({}, state, {
        employee_id: action.data
      })
    case TIME_ENTRY_SET_ROW_PROJECT_OK_ACTION:
      return Object.assign({}, state, {
        items: state.items.map(r => {
          if (r.id === 0) {
            r.project_id = action.data
          }
          return r
        })
      })
    case TIME_ENTRY_SET_ROW_TICKET_OK_ACTION:
      return Object.assign({}, state, {
        items: state.items.map(r => {
          if (r.id === 0) {
            r.ticket_id = action.data
          }
          return r
        })
      })
    case TIME_ENTRY_UPDATE_ACTION:
      return Object.assign({}, state, {
        items: state.items.map(r => {
          return r.id === action.data.id ? action.data : r
        })
      })
    case TIME_ENTRY_SET_DATE_OK_ACTION:
      return Object.assign({}, state, {
        date: action.data
      })
    default:
      return state
  }
}
