import React, { useCallback, useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { styled, Modal, Button as PergasButton, Group } from '@pergas-common/pergas-components'
import { Company, Add, Close as PergasClose } from '@pergas-common/pergas-icons'
import { selectLocale } from 'domains/locale/selectors'
import { api } from 'domains'
import useRequest from 'hooks/useRequest'
import useDebounce from 'hooks/useDebounce'
import { InputGroup, Select } from '../Input'

const Holder = styled.div`
  display: flex;
  height: 130px;
  flex-direction: column;
`

const ContentHolder = styled.div`
  max-width: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 32px;
  align-self: center;
`

const usePersonRole = (defaultPerson) => {
  const [getRoles, roles, rolesStatus] = useRequest(api.getRoles, 'roles')
  const [person, setPerson] = useState(defaultPerson)
  const [role, setRole] = useState(null)

  return [
    person,
    role,
    setRole,
    roles,
    rolesStatus,
    getRoles,
    setPerson
  ]
}

const useAvailableRoles = (person, roles) => {
  const { values } = useFormikContext()

  const selectableRoles = Array.isArray(roles) && roles.filter((r) => {
    if (person && values?.person_role?.length > 0) {
      const hasRole = values.person_role.some((pr) => pr.person_id === person.person_id && pr.role_id === r.id)
      return !hasRole && r.internal_name !== 'responsible'
    }
    return r.internal_name !== 'responsible'
  })

  return selectableRoles || []
}

export const EditPersonRole = ({ isOpen, onCloseRequest, selected }) => {
  const { values, setFieldValue } = useFormikContext()
  const locale = useSelector(selectLocale)
  const [person, role, setRole, roles, rolesStatus, getRoles] = usePersonRole(selected)
  const [debouncedRoleRequest] = useDebounce(getRoles, 300)
  const selectableRoles = useAvailableRoles(selected, roles)

  const disableSubmit = person === null || role === null

  const onSelect = useCallback((p) => {
    const containing = Array.isArray(values.person_role) ? [...values.person_role] : []
    const index = containing.findIndex((pr) => pr.person_id === p.person_id)
    if (index > -1) {
      containing[index] = p
      setFieldValue('person_role', containing)
    }
    onCloseRequest()
  }, [setFieldValue, onCloseRequest, values])

  useEffect(() => {
    getRoles({ sort: 'name.asc', limit: 50 })
  }, [getRoles])

  return (
    <Modal
      title={locale.person_role} titleIcon={<Company width={20} height={20} />} isOpen={isOpen} size='lg' footer={() => (
        <Group.Button>
          <PergasButton onClick={() => {
            onCloseRequest()
          }}
          ><span>{locale.cancel}</span><PergasClose width={18} height={18} />
          </PergasButton>
          <PergasButton
            disabled={disableSubmit} onClick={() => {
              const personRole = {
                ...person,
                role_id: role.id,
                role_name: role.name,
                role_internal_name: role.internal_name
              }
              onSelect(personRole)
            }}
          ><span>{locale.update}</span><Add width={20} height={20} />
          </PergasButton>
        </Group.Button>
      )}
    >
      {() => (
        <Holder>
          <ContentHolder>
            <InputGroup fullWidth>
              <Select
                name='name'
                label={locale.name}
                defaultValue={{ id: selected.person_id, name: selected.person_name }}
                disabled
                requestStatus={false}
                items={[]}
                handleChange={() => {}}
              />
              <Select
                name='role'
                label={locale.role}
                disabled={selected === null}
                defaultValue={{ id: person.role_id, name: person.role_name }}
                request={(input) => {
                  debouncedRoleRequest({ query: [{ key: 'name', op: '~', value: input }], sort: 'name.asc', limit: 50 })
                }}
                requestStatus={rolesStatus === 'pending'}
                items={selectableRoles}
                handleChange={(_, id, role) => {
                  setRole({ ...role, id })
                }}
              />
            </InputGroup>
          </ContentHolder>
        </Holder>
      )}
    </Modal>
  )
}
