import React, { useEffect, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import { TablePagination } from '@mui/material'
import PageToolbar from './PageToolbar.js'
import redux from '../../redux/index.js'
import SearchField from '../SearchField.js'
import DeleteItem from '../dialogs/DeleteItem.js'
import {
  Table,
  Link,
  styled,
  Tag,
  TD as Td
} from '@pergas-common/pergas-components'
import { Add, Remove, OpenExternal } from '@pergas-common/pergas-icons'
import { createDateAccessor } from './columns.js'
import EditObjectType from '../dialogs/EditObjectType.js'
import { ObjectTypeIcon } from '../icons.js'
import { DEFAULT_OBJECT_TYPE_COLOR } from '../style.js'

const TableHolder = styled.div`
  display: flex;
  position: absolute;
  top: 100px;
  bottom: 0;
  overflow-y: scroll;
`

const TagHolder = styled.span`
  margin-left: 5px;
  margin-right: 8px;
`

const Spacer = styled.div`
  display: inline-block;
  margin: 2px;
`

const ROWS_PER_PAGE = [
  25,
  50,
  100,
  500,
  1000
]

const ObjectTypePage = ({
  locale,
  rows,
  limit,
  offset,
  orderBy,
  order,
  search,
  resetSearch,
  getPageItems,
  setOrder,
  setLimit,
  setOffset,
  setSearch,
  canUpdate,
  canDelete,
  deleteItem,
  onDeleteItemClick,
  onDeleteOk,
  onDeleteCancel,
  editItem,
  onEditItemClick,
  onEditOk,
  onEditCancel,
  canCreate,
  onShowAddDialog
}) => {
  useEffect(getPageItems, [limit, offset, orderBy, order, search])

  const manualSort = useCallback(({ id, isSorted, isSortedDesc }) => {
    if (isSorted && !isSortedDesc) {
      setOrder(id, 'desc')
    } else if (isSorted && isSortedDesc) {
      setOrder('', '')
    } else {
      setOrder(id, 'asc')
    }
  }, [setOrder])

  const columns = useMemo(() => {
    return [
      {
        Header: locale.name,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (
            <Td
              {...cell.getCellProps()} left={
                <>
                  <ObjectTypeIcon style={{ width: 16, height: 16 }} />
                  <TagHolder>
                    <Tag color={original.color || DEFAULT_OBJECT_TYPE_COLOR} textColor={original.color ? '#FFFFFF' : '#3a4a54'} border='#969696'>{original.name}</Tag>
                  </TagHolder>
                </>
            }
            />
          )
        },
        canSort: true,
        id: 'name',
        size: 'sm',
        manualSort,
        sortType: () => {}
      },
      {
        Header: locale.description,
        manualSort,
        accessor: 'description',
        sortType: () => {}
      },
      {
        id: 'object_type_name',
        Header: locale.object_type_type,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (<Td {...cell.getCellProps()} left={locale[original.object_type_type] || ''} />)
        },
        isSortable: false
      },
      {
        id: 'updated_at',
        Header: locale.updated_at,
        manualSort,
        accessor: createDateAccessor('updated_at'),
        sortType: () => {}
      },
      {
        id: 'toolbar',
        Header: locale.tool_belt,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (
            <Td
              {...cell.getCellProps()}
              left={
                <>
                  {canUpdate && <Spacer><Link onClickHandler={() => { onEditItemClick(original) }}><OpenExternal color='#28afe0' width={20} height={20} /></Link></Spacer>}
                </>
              }
              right={canDelete && !original.read_only && <Link onClickHandler={() => { onDeleteItemClick(original) }}><Remove width={20} height={20} color='#FF5656' /></Link>}
            />
          )
        },
        size: 'sm',
        isSortable: false
      }
    ]
  }, [locale, canUpdate, canDelete, manualSort, onDeleteItemClick, onEditItemClick])

  let initialSortBy = []
  if (orderBy) {
    initialSortBy = [{
      id: orderBy,
      desc: order === 'desc'
    }]
  }

  return (
    <>
      {editItem && <EditObjectType isEditing item={editItem} onOk={onEditOk} onCancel={onEditCancel} />}
      {deleteItem && <DeleteItem text={deleteItem.name} onOk={() => { onDeleteOk(deleteItem) }} onCancel={onDeleteCancel} />}
      <PageToolbar left={<><ObjectTypeIcon style={{ width: 20, height: 20 }} /><span>{locale.object_types}</span> {canCreate && <Link onClickHandler={onShowAddDialog}><Add width={20} height={20} color='#28afe0' /></Link>}</>}>
        <SearchField value={search} onChange={setSearch} />
      </PageToolbar>
      <TableHolder>
        <Table columns={columns} data={rows} initialPageSize={limit} initialSortBy={initialSortBy}>
          {({ setPageSize }) => (
            <TablePagination
              rowsPerPage={limit}
              rowsPerPageOptions={ROWS_PER_PAGE}
              count={-1}
              page={offset / limit}
              labelRowsPerPage={locale.rows_per_page}
              labelDisplayedRows={({ from, to }) => `${from}-${to}`}
              onRowsPerPageChange={(e) => {
                setLimit(e.target.value)
                setPageSize(e.target.value)
              }}
              onPageChange={(e, number) => setOffset(number * limit)}
            />
          )}
        </Table>
      </TableHolder>
    </>
  )
}

const mapStateToProps = (state) => {
  const { locale, objectType } = state
  const permissions = state.login.permissions
  const {
    pageItems,
    limit,
    offset,
    orderBy,
    order,
    search
  } = objectType
  return {
    locale: locale.strings,
    rows: pageItems,
    limit,
    offset,
    orderBy,
    order,
    search,
    canUpdate: permissions.objectType.canUpdate,
    canDelete: permissions.objectType.canDelete,
    canCreate: permissions.objectType.canCreate,
    deleteItem: state.objectType.deleteItem,
    editItem: state.objectType.editItem
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions: { objectType } } = redux
  return {
    onShowAddDialog: () => {
      dispatch(objectType.showAddObjectTypeDialog())
    },

    onEditOk: (type) => {
      dispatch(objectType.hideEditObjectTypeDialog())
      dispatch(objectType.updateObjectType(type))
    },
    onEditItemClick: (type) => {
      dispatch(objectType.showEditObjectTypeDialog(type))
    },
    onEditCancel: () => {
      dispatch(objectType.hideEditObjectTypeDialog())
    },

    onDeleteOk: (type) => {
      dispatch(objectType.hideDeleteObjectTypeDialog())
      dispatch(objectType.deleteObjectType(type))
    },
    onDeleteItemClick: (type) => {
      dispatch(objectType.showDeleteObjectTypeDialog(type))
    },
    onDeleteCancel: () => {
      dispatch(objectType.hideDeleteObjectTypeDialog())
    },

    getPageItems: () => dispatch(objectType.getPageItems()),
    setOrder: (orderBy, order) => dispatch(objectType.setOrder(orderBy, order)),
    setLimit: (limit) => dispatch(objectType.setLimit(limit)),
    setOffset: (offset) => dispatch(objectType.setOffset(offset)),
    setSearch: (search) => dispatch(objectType.setSearch(search)),
    resetSearch: () => dispatch(objectType.resetSearch())

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ObjectTypePage)
